import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { ChudidharContext } from "../../../../../context/chudidharContext";

const BackModelDisplay=({name,data,icon})=>{
     const {selectdChudiBackModelItem,updateChudiBackModel,selectdChudiDBackItem,updateChudiDBack
    ,chudiDefaultBackData}=useContext(ChudidharContext);

     const getBackNeckModels=(jsondata,itemName)=>{
        updateChudiBackModel(jsondata);

         // check the model is true default neck is without neck active
         if(selectdChudiDBackItem === true){
            chudiDefaultBackData.map((each)=>{
                if(each.name === "Round Neck"){
                    updateChudiDBack(each);
                }
            })
        }
        else{
            chudiDefaultBackData.map((each)=>{
                if(each.name === "Without Neck"){
                    updateChudiDBack(each);
                }
            })
        }

     }

    return(
        <>
        <Button className={selectdChudiBackModelItem && selectdChudiBackModelItem.name === name ? 
            "items_display btn-danger":"items_display"}
           onClick={()=>getBackNeckModels(data,name)}>
            <img src={icon} alt={name} className="icon_image"/>
            {name}
        </Button>
       </>
    )
}
export default BackModelDisplay