import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { LoginContext } from "./logincontext";
import urls from "../api.json"


export const PackageContext=createContext({});

const PackageProvider=({children})=>{
    const {authTokens}=useContext(LoginContext);
    const accessToken = authTokens?.access;
    const [blouseID,setBlouseID]=useState({});
    const [sleeveID,setSleeveID]=useState({});
    const [defaultFrontNeckID,setDefaultFrontNeckID]=useState({});
    const [collorID,setCollorID]=useState({});
    const [backDropID,setbackDropID]=useState({});
    const [patcheID,setPatcheID]=useState({});
    const [defaultBackneckID,setDefaultBackNeckID]=useState({});
    const [frontModelID,setFrontModelID]=useState({});
    const [backModelID,setBackModelID]=useState({});
    const [hignNeckID,setHighNeckID]=useState({});
    const [chudidharID,setChudidharID]=useState({});
    const [chudidharSleeveID,setChudidharSleeveID]=useState({});
    const [chudidharDefaultfrontneckID,setChudidharDefaultFrontNeckID]=useState({});
    const [chudidharDefaultbackneckID,setChudidharDefaultBackNeckID]=useState({});
    const [chudidharbackModelID,setChudidharBackModelID]=useState({});
    const [chudidharFrontModelID,setChudidharFrontModelID]=useState({});
    const [chudidharBackDropID,setChudidharBackDropID]=useState({});
    const [chudidharCollorID,setChudidharCollorID]=useState({});
    const [chudidharHighNeckID,setChudidharHighNeckID]=useState({});
    const [kidsID,setKidsID]=useState({})
    const [kidsSleeveID,setKidsSleeveID]=useState({})
    const [kidsDefaultFrontID,setKidsDefaultFrontID]=useState({})
    const [kidsDefaultBackID,setKidsDefaultBackID]=useState({})
    const [kidsFrontModelID,setKidsFrontModelID]=useState({})
    const [kidsBackModelID,setKidsBackModelID]=useState({})
    const [kidsCollorID,setKidsCollorID]=useState({})
    const [selectedName,setSelectedName]=useState("");
    const [mainPackages,setMainPackages] = useState();
    const [youtube,setYoutube]=useState(null);
    const [activeYoutube,setActiveYoutube]=useState(false);
  

    const handleSelectedName=(value)=>{
      setSelectedName(value)
    }
    

    const PackageData = () => {
        axios
          .get(urls.urls.BASE_URL+urls.urls.main_package,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
          )
          .then((res) => {
            setMainPackages(res.data.main_packages)
            let len = res.data.main_packages.length;
            let lencustom = res.data.custom_packages.length;
            let mainPackages = [];
            let id=[];
            let sleeveId=[];
            let defaulFrontNeckId=[];
            let ColloId=[];
            let BackdropId=[];
            let PatcheId=[];
            let DefaultBackNeckId=[];
            let FrontNeckModel=[];
            let BackNeckModel=[];
            let HighNeckID=[];
            let ChudidharID=[];
            let ChudidharSleeveID=[];
            let ChudidharDefaultFrontNeckID=[];
            let ChudidharDefaultBackNeckID=[];
            let ChudidharbackModelID=[];
            let ChudidharfrontModelID=[];
            let ChudidharBackdropID=[];
            let ChudidharCollorID=[];
            let ChudidharHighID=[];
            let KidsID=[];
            let KidsSleeveID=[];
            let KidsDefaultFrontNeckID=[];
            let KidsDefaultBackNeckID=[];
            let KidsBackModelID=[];
            let KidsFrontModelID=[];
            let KidsCollorID=[];
              
              for (let i = 0; i < len; i++) {
                mainPackages.push(res.data.main_packages[i]);
              }
              for (let i = 0; i < lencustom; i++) {
                mainPackages.push(res.data.custom_packages[i]);
              }

              mainPackages.map(each=>(
                id.push(each.blouse),
                sleeveId.push(each.blouse_sleeves),
                defaulFrontNeckId.push(each.blouse_defaultfrontnecks),
                ColloId.push(each.blouse_collar),
                BackdropId.push(each.blouse_backdrops),
                PatcheId.push(each.blouse_patches),
                DefaultBackNeckId.push(each.blouse_defaultbacknecks),
                FrontNeckModel.push(each.blouse_frontnecks),
                BackNeckModel.push(each.blouse_backnecks),
                HighNeckID.push(each.blouse_defaulthighnecks),
                ChudidharID.push(each.churidar),
                ChudidharSleeveID.push(each.churidar_sleeves),
                ChudidharDefaultFrontNeckID.push(each.churidar_defaultfrontnecks),
                ChudidharDefaultBackNeckID.push(each.churidar_defaultbacknecks),
                ChudidharfrontModelID.push(each.churidar_frontnecks),
                ChudidharbackModelID.push(each.churidar_backnecks),
                ChudidharBackdropID.push(each.churidar_backdrops),
                ChudidharCollorID.push(each.churidar_collar),
                ChudidharHighID.push(each.churidar_defaulthighnecks),
                KidsID.push(each.kids),
                KidsSleeveID.push(each.kids_sleeves),
                KidsDefaultFrontNeckID.push(each.kids_defaultfrontnecks),
                KidsDefaultBackNeckID.push(each.kids_defaultbacknecks),
                KidsFrontModelID.push(each.kids_frontnecks),
                KidsBackModelID.push(each.kids_backnecks),
                KidsCollorID.push(each.kids_collar)

              ))
             
              const mergedData = id.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const SleeveData = sleeveId.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const DefaultFrontData = defaulFrontNeckId.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const CollorData = ColloId.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const BackDropData = BackdropId.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const PatcheData = PatcheId.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const DefaultBackData = DefaultBackNeckId.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const FrontModelData = FrontNeckModel.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const BackModelData = BackNeckModel.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const HighNeckData = HighNeckID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const ChuddiahrData = ChudidharID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const ChuddiahrSleeveData = ChudidharSleeveID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const ChuddiahrDefaultFrontNeckData = ChudidharDefaultFrontNeckID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const ChuddiahrDefaultBackNeckData = ChudidharDefaultBackNeckID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const ChuddiahrBackModelkData = ChudidharbackModelID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const ChuddiahrFrontModelData = ChudidharfrontModelID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const ChuddiahrBackdropData = ChudidharBackdropID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const ChuddiahrCollorData = ChudidharCollorID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const ChuddiahrHighNeckData = ChudidharCollorID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const KidsData = KidsID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const KidsSleeveData = KidsSleeveID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const KidsDefaultFrontData = KidsDefaultFrontNeckID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const KidsDeafaultBackData = KidsDefaultBackNeckID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const KidsFrontModelData = KidsFrontModelID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const KidsBackModelData = KidsBackModelID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);
              const KidsCollorModelData = KidsCollorID.reduce((accumulator, currentArray) => {
                return accumulator.concat(currentArray);
              }, []);

        
            setBlouseID(mergedData);
            setSleeveID(SleeveData);
            setCollorID(CollorData);
            setbackDropID(BackDropData);
            setPatcheID(PatcheData);
            setDefaultFrontNeckID(DefaultFrontData);
            setDefaultBackNeckID(DefaultBackData);
            setFrontModelID(FrontModelData);
            setBackModelID(BackModelData);
            setHighNeckID(HighNeckData);
            setChudidharID(ChuddiahrData);
            setChudidharSleeveID(ChuddiahrSleeveData);
            setChudidharDefaultFrontNeckID(ChuddiahrDefaultFrontNeckData);
            setChudidharDefaultBackNeckID(ChuddiahrDefaultBackNeckData);
            setChudidharBackModelID(ChuddiahrBackModelkData);
            setChudidharFrontModelID(ChuddiahrFrontModelData);
            setChudidharBackDropID(ChuddiahrBackdropData);
            setChudidharCollorID(ChuddiahrCollorData);
            setChudidharHighNeckID(ChuddiahrHighNeckData);
            setKidsID(KidsData);
            setKidsSleeveID(KidsSleeveData);
            setKidsDefaultFrontID(KidsDefaultFrontData);
            setKidsDefaultBackID(KidsDeafaultBackData);
            setKidsFrontModelID(KidsFrontModelData);
            setKidsBackModelID(KidsBackModelData);
            setKidsCollorID(KidsCollorModelData);
          })
          .catch((err) => {
            console.log("packages error", err);
          });
        };

    const YoutubeData=()=>{
      axios
          .get(urls.urls.BASE_URL+urls.urls.youtube_data,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
          ).then(res=>{
            setYoutube(res.data)
          })
          .catch(err=>{
            console.log("Youtube API error",err)
          })
    }

    useEffect(()=>{
        if(authTokens){
            PackageData()
            YoutubeData()
        }
       
    },[authTokens])

    return(
        <PackageContext.Provider value={{
            blouseID,kidsID,chudidharCollorID,chudidharBackDropID,chudidharSleeveID,
            chudidharFrontModelID,chudidharbackModelID,chudidharDefaultbackneckID,
            chudidharDefaultfrontneckID,chudidharID,hignNeckID,backModelID,
            sleeveID,defaultFrontNeckID,collorID,backDropID,patcheID,defaultBackneckID,
            frontModelID,kidsCollorID,chudidharHighNeckID,handleSelectedName,
            selectedName,mainPackages,activeYoutube,setActiveYoutube,youtube,
            kidsSleeveID,kidsDefaultFrontID,kidsDefaultBackID,kidsFrontModelID,kidsBackModelID
        }}>
            {children}
        </PackageContext.Provider>
    )
}
export default PackageProvider