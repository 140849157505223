import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { BlouseContext } from "../../../../context/blouseContext";
import { useTranslation } from 'react-i18next';


const CollarDisplay=({name,icon,data,children})=>{
    const {selectedCollarItem,updateCollarItem}=useContext(BlouseContext);
    const { t } = useTranslation();
    const CollarData = t('main.collars_data', { returnObjects: true });

    const getCollarItem=(jsondata)=>{
        const isSelected = selectedCollarItem && selectedCollarItem.name === name;
        if (!isSelected) {
            updateCollarItem(jsondata);
        }
        else{
            updateCollarItem(null)
        }
        
    }

    return(
        <>
        <Button variant="white border" className={selectedCollarItem && 
                selectedCollarItem.name === name ?"items_display btn-danger " :"items_display"}
            onClick={()=>getCollarItem(data,name)}>
            <img src={icon} alt={name} className="icon_image"/>
            {CollarData[name] ? CollarData[name] :name}
            {children}
        </Button>
       </>
    )
}
export default CollarDisplay