import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBarComponent from "../Navbar";
import MainBlouseComponent from "./mainleft";
import RightSideComponent from "./Layout/rightside";
import Joyride from "react-joyride";
import { BlouseContext } from "../../context/blouseContext";
import { Link, useNavigate } from "react-router-dom";
import { LoginContext } from "../../context/logincontext";

const steps = [
  {
    target: "#blouse",
    content: "THis is BLouse List",
  },
  {
    target: "#blouse_items",
    content: "Click Any Blouse Items",
  },
  {
    target: "#front_svg_item",
    content: "This is Selected Item",
  },
  {
    target: "#sleeve",
    content: "This is Sleeves List",
  },
  {
    target: "#front_svg_item",
    content: "This is Your Selected Item",
  },
  {
    target: "#next",
    content: "Go",
  },
  {
    target: "#Customer_save",
    content: "Save Your Customer Details",
  },
  {
    target: "#next",
    content: "Go",
  },
  {
    target: "#trails_button",
    content: "You Want Give Trail MEasurment",
  },
  {
    target: "#pattern_button",
    content: "You Want To Print This Pattern Click Here",
  },
  {
    target: ".footer .form",
    content: "Subscribe to our newsletter here",
  },
];



const BlouseComponent=()=>{
    const {run}=useContext(BlouseContext);
    const {authTokens}=useContext(LoginContext);
    const navigate=useNavigate()


   const handleJoyrideCallback = (data) => {
    const { status, index } = data;

    if (status === 'finished' || status === 'skipped') {
      // Call your method when the tour is finished or skipped
      // For example:
      // myMethod();
    } else {
      // Check if the target is '#blouse_item'
     
    }
  };
  useEffect(()=>{
    if(!authTokens){
      navigate("/login")
    }

  })
    return(
        <Container fluid className="home">
            <Joyride
            callback={handleJoyrideCallback}
            continuous
            run={run}
            scrollToFirstStep
            showProgress
            steps={steps}
              />
            <NavBarComponent/>
            <Row className="p-1">
                <Col >
                <MainBlouseComponent/>
                </Col>
                <Col lg={8} className="mx-1">
                <RightSideComponent/>
                </Col>
            </Row>
        </Container>
    )
}
export default BlouseComponent