import React, { createContext, useContext, useEffect, useState } from "react";
import { LoginContext } from "./logincontext";
import axios from "axios";
import urls from "../api.json"

export const KidsContext=createContext({});

const KidsProvider=({children})=>{
    const {authTokens}=useContext(LoginContext);
    const accessToken = authTokens?.access;
    const [kidsData,setKidsData]=useState([]);
    const [allkidsData,setAllkidsData]=useState([]);
    const [kidsSleeveData,setKidsSleeveData]=useState([]);
    const [kidsDefaultFrontData,setKidsDefaultFrontData]=useState([]);
    const [kidsDefaultBackData,setKidsDefaultBackData]=useState([]);
    const [kidsFrontModelData,setKidsFrontModelData]=useState([]);
    const [kidsBackModelData,setKidsBackModelData]=useState([]);
    const [kidsCollarData,setKidsCollarData]=useState([]);
    const [selectdKidsItem,setSelectedKidsItem]=useState(null);
    const [selectdKidsSleeveItem,setSelectedKidsSleeveItem]=useState(null);
    const [selectdKidsDFrontItem,setSelectedKidsDFrontItem]=useState(null);
    const [selectdKidsDBackItem,setSelectedKidsDBackItem]=useState(null);
    const [selectdKidsFrontModelItem,setSelectedKidsFrontModelItem]=useState(null);
    const [selectdKidsBackModelItem,setSelectedKidsBackModelItem]=useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [kidsCustomizeData,setKidsCustomizeData]=useState(null);
    const [activeKidsColor,setActiveKidsColor]=useState("");


    const getKidsItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.kids_items, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setKidsData(res.data.categories)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    const getKidsSleeveItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.kids_sleeve, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setKidsSleeveData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    const getKidsDFrontNeckItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.kids_d_frontnecks, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setKidsDefaultFrontData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    const getKidsDBackNeckItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.kids_d_backnecks, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setKidsDefaultBackData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    const getKidsFrontModelItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.kids_frontmodels, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setKidsFrontModelData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    const getKidsBackModelItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.kids_backmodels, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setKidsBackModelData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    const getKidsCollarItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.chudi_items, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setKidsCollarData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }

    const getPatternPage=()=>{
      setCurrentStep(4);
    }


    const updateKidsItems=(value)=>{
        setSelectedKidsItem(value)
    }
    const updateKidsSleeve=(value)=>{
        setSelectedKidsSleeveItem(value)
    }
    const updateKidsDFront=(value)=>{
        setSelectedKidsDFrontItem(value)
    }
    const updateKidsDBack=(value)=>{
        setSelectedKidsDBackItem(value)
    }
    const updateKidsFrontModel=(value)=>{
        setSelectedKidsFrontModelItem(value)
    }
    const updateKidsBackModel=(value)=>{
        setSelectedKidsBackModelItem(value)
    }

    const getKidsCustomizationData=(value)=>{
      setKidsCustomizeData(value)
    }

    const getHome=()=>{
      setCurrentStep(1);
    }

    const handleKidsClear=()=>{
       setCurrentStep(1);
       updateKidsItems(null);
       updateKidsSleeve(null);
       updateKidsDBack(null);
       updateKidsDFront(null);
       updateKidsFrontModel(null);
       updateKidsBackModel(null)

    }

    const updateKidsColor=(value)=>{
      setActiveKidsColor(value)
    }


    const handleNextClick = () => {
        if (currentStep < 4) {
            setCurrentStep(currentStep + 1);
          }
    }

    const handlePreviousClick = () => {
        if (currentStep > 1) {
          setCurrentStep(currentStep - 1);
        }
    }

    


    useEffect(()=>{
        if(authTokens){
            getKidsItems();
            getKidsSleeveItems();
            getKidsDFrontNeckItems();
            getKidsDBackNeckItems();
            getKidsFrontModelItems();
            getKidsBackModelItems();
            // getKidsCollarItems();
        }
    },[authTokens])

    useEffect(() => {
        const items = kidsData.reduce((acc, category) => acc.concat(category.items), []);
        setAllkidsData(items); 
      }, [kidsData]);




    return(
        <KidsContext.Provider  value={{allkidsData,kidsData,kidsSleeveData,getPatternPage,
            kidsDefaultFrontData,kidsDefaultBackData,kidsFrontModelData,kidsBackModelData,kidsCollarData,
            selectdKidsItem,selectdKidsSleeveItem,selectdKidsDFrontItem,selectdKidsDBackItem,getHome,
            selectdKidsFrontModelItem,selectdKidsBackModelItem,currentStep,getKidsCustomizationData,
            updateKidsItems,updateKidsSleeve,updateKidsDFront,updateKidsDBack,updateKidsFrontModel,
            updateKidsColor,activeKidsColor,
            updateKidsBackModel,handleNextClick,handlePreviousClick,kidsCustomizeData,handleKidsClear
             }} >
            {children}
        </KidsContext.Provider>
    )
}

export default KidsProvider