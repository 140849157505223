import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { BlouseContext } from "../../../../../context/blouseContext";
import { useTranslation } from 'react-i18next';


const FrontNeckModel=({name,icon,data,children})=>{
    const {updateFrontNeckModels,selectedFrontneckItem,defaultFrontData,
        updateDefaultFrontNecks,selectedDefaultFrontneckItem}=useContext(BlouseContext);
        const { t } = useTranslation();
        const FrontNeckData = t('main.front_neck_model_data', { returnObjects: true });

    const getFrontNeckModels=(jsondata)=>{
        updateFrontNeckModels(jsondata);


        // check the model is true default neck is without neck active
        if(selectedDefaultFrontneckItem === true){
            defaultFrontData.map((each)=>{
                if(each.name === "Round Neck"){
                    updateDefaultFrontNecks(each);
                }
            })
        }
        else{
            defaultFrontData.map((each)=>{
                if(each.name === "Without Neck"){
                    updateDefaultFrontNecks(each);
                }
            })
        }
       
        
    }  
      

    return(
        <>
            <Button variant="white border" className={selectedFrontneckItem && selectedFrontneckItem.name === name ?
             "items_display btn-danger":"items_display"}
               onClick={()=>getFrontNeckModels(data)}>
                <img src={icon} alt={name} className="icon_image"/>
                {FrontNeckData[name] ? FrontNeckData[name] :name} 
                {children}
            </Button>
        </>

    )
}
export default FrontNeckModel