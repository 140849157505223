import React, { useContext} from "react";
import parse from 'html-react-parser';
import { KidsContext } from "../../../../context/kidsContext";
import { BlouseContext } from "../../../../context/blouseContext";

const KidsFrontSvgContent=()=>{
    const {selectdKidsItem,activeKidsColor}=useContext(KidsContext);
        const {activeFabric}=useContext(BlouseContext);
    return(
        <>
        <svg xmlns="http://www.w3.org/2000/svg" 
          width="100mm" height="100mm" version="1.1" 
            viewBox="1500 1000 6750 8000"
            style={{shapeRendering:"geometricPrecision",textRendering:"geometricPrecision",imageRendering:"optimizeQuality",fillRule:"evenodd",clipRule:"evenodd",
            fill:activeKidsColor || activeFabric ? activeKidsColor || `url(#image-fill)` : "white"
           
        }}
            >    
            <defs>
                <pattern id="image-fill"  width="100%" height="100%" patternContentUnits="userSpaceOnUse">
                    <image xlinkHref={activeFabric} width="100%" />
                </pattern>
              </defs>
              
            <path style={{stroke:"#16406A",strokeWidth:"70.56",strokeLinecap:"round",strokeLinejoin:'round',strokeMiterlimit:"22.9256",
            fill: activeKidsColor || activeFabric ? activeKidsColor || `url(#image-fill)` : "white" 
        }} 
                d={selectdKidsItem ? (selectdKidsItem.base_value ) : null}
                //  (selectedDefaultfrontNeckItem ? selectedDefaultfrontNeckItem.svg_content:'') + 
                //  (selectedHighNeckFrontItem ? selectedHighNeckFrontItem.svg_content :'')
                 ></path>
                {selectdKidsItem && parse(selectdKidsItem.front_svg)}
                 {/* {selectedCollarItem && parse(selectedCollarItem.svg_content)}
                { selectedSleeveItem && parse(selectedSleeveItem.svg_content)} */}
            </svg>

          
        </>
    )
}
export default KidsFrontSvgContent