import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { BlouseContext } from "../../../../../context/blouseContext";
import { useTranslation } from 'react-i18next';

const BackModelDisplay=({name,data,icon})=>{
     const {selectedBackneckItem,updateBackNeckModels,selectedDefaultBackneckItem,updateDefaultBackNecks
    ,defaultBackData}=useContext(BlouseContext);
    const { t, i18n } = useTranslation();
    const BackNeckModelData = t('main.Back_neck_model_data', { returnObjects: true });

     const getBackNeckModels=(jsondata,itemName)=>{
        updateBackNeckModels(jsondata);

         // check the model is true default neck is without neck active
         if(selectedDefaultBackneckItem === true){
            defaultBackData.map((each)=>{
                if(each.name === "Round Neck"){
                    updateDefaultBackNecks(each);
                }
            })
        }
        else{
            defaultBackData.map((each)=>{
                if(each.name === "Without Neck"){
                    updateDefaultBackNecks(each);
                }
            })
        }

     }

    return(
        <>
        <Button variant="white border" className={selectedBackneckItem && selectedBackneckItem.name === name ? 
            "items_display btn-danger":"items_display"}
           onClick={()=>getBackNeckModels(data,name)}>
            <img src={icon} alt={name} className="icon_image"/>
            {BackNeckModelData[name] ? BackNeckModelData[name] :name}
        </Button>
       </>
    )
}
export default BackModelDisplay