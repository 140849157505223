import React, { useContext, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Form from 'react-bootstrap/Form';
import HighneckDisplay from "./highdisplay";
import { BlouseContext } from "../../../../context/blouseContext";


const DefaultHighNecks=()=>{
      const {highNeckData}=useContext(BlouseContext);
      const [filterData,setFilterData]=useState("");

    const getFilterData=highNeckData && highNeckData.filter(each=>(
      each.name.toLowerCase().includes(filterData.toLowerCase())
    ))

    
    return(
        <>
         <div className="d-flex">
                <Col className="" lg={8}>
                   <Form.Control type="text" placeholder="Search By Items..."
                   onChange={(e)=>setFilterData(e.target.value)} />
                 </Col>
                 <Col className="mx-2 mt-2" lg={4}>
                   <p className="text-dark ">Total Items {getFilterData && getFilterData.length}</p>
                 </Col>
            </div>
           
            <div className="leftside_items_sleeve" >
                 {getFilterData?.map(each=>(
                  <HighneckDisplay key={each.id}
                  icon={each.icon}
                  name={each.name}
                  data={each}>
                  </HighneckDisplay>
                 ))}
                 
            </div>
        </>
    )
}
export default DefaultHighNecks