import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { KidsContext } from "../../../../context/kidsContext";
import { useTranslation } from 'react-i18next';
import { CustomerContext } from "../../../../context/customerContext";


const KidsDisplay=({data,name,icon})=>{
    const {updateKidsItems,selectdKidsItem}=useContext(KidsContext)
    const {setCustomerMeasurment,setFormData}=useContext(CustomerContext)
    const { t } = useTranslation();
    const kids_Data = t('main.kids_data', { returnObjects: true });

    const getKidsData=(jsonData)=>{
        updateKidsItems(jsonData);
        setCustomerMeasurment(null)
        setFormData(null)
    }

    return(
        <>
           <Button variant="bg-light border"
           className={selectdKidsItem && selectdKidsItem.name === name ? 
            "items_display btn-danger":"items_display"}
             onClick={()=>getKidsData(data,name)}>
                <img src={icon} alt={name} className="icon_image_kids"/>
                {kids_Data[name] ? kids_Data[name] :name}
            </Button>
        </>
    )
}
export default KidsDisplay