import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FaPrint } from "react-icons/fa6";
import { BlouseContext } from "../../../context/blouseContext";
import { useTranslation } from 'react-i18next';

const PatternComponent = () => {
    const { customizeData, selectedBlouse, selectedSleeveItem, selectedDefaultFrontneckItem,
        selectedDefaultBackneckItem, selectedFrontneckItem, selectedBackneckItem,
        selectedHighneckItem, selectedCollarItem, selectedBackDropItem } = useContext(BlouseContext);
    const [dxfPath, setDxfPath] = useState(customizeData.dxf_path);
    const { t } = useTranslation();
    const customize_data = t('main.page_4_buttons', { returnObjects: true });

    useEffect(() => {
        setDxfPath(customizeData.dxf_path);
    }, []);

    const disableAllLinks = true; // Set this to true to disable all links

    const svgWithClass = customizeData.url ? customizeData.url.replace('<svg', '<svg class=" "') : null;

    return (
        <>
            <Row className="bg-light rounded mt-1">
                <Col className="p-1" lg={12}>
                    <h6 >{customize_data[2]} /
                        <span className="text-danger"> {selectedBlouse && selectedBlouse.name}</span>
                        <span className="text-info"> {selectedSleeveItem && selectedSleeveItem.name}</span>
                        <span className="text-danger"> {selectedDefaultFrontneckItem && selectedDefaultFrontneckItem.name}</span>
                        <span className="text-info"> {selectedDefaultBackneckItem && selectedDefaultBackneckItem.name}</span>
                        <span className="text-danger">{selectedHighneckItem && selectedHighneckItem.name}</span>
                        <span className="text-info"> {selectedFrontneckItem && selectedFrontneckItem.name}</span>
                        <span className="text-danger"> {selectedBackneckItem && selectedBackneckItem.name}</span>
                        <span className="text-info"> {selectedCollarItem && selectedCollarItem.name}</span>
                        <span className="text-info"> {selectedBackDropItem && selectedBackDropItem.name}</span>
                    </h6>
                </Col>
                <hr style={{ margin: "0px", marginBottom: "0px", marginTop: "-5px" }} />
                {/* <Col>
                    {dxfPath && dxfPath?.map(each => {
                        const path = each.split("/");
                        const buttonName = path[path.length - 2];

                        return (
                            <a
                                href={disableAllLinks ? undefined : (process.env['REACT_APP_BASE_URL'] + each)}
                                className={`btn btn-outline-success btn-sm btn_style mx-1 ${disableAllLinks ? 'disabled' : ''}`}
                                onClick={disableAllLinks ? (e) => e.preventDefault() : null}
                            >
                                <FaPrint className="mx-1"/>
                                {buttonName}
                            </a>
                        );
                    })}
                </Col> */}
                <Row className="items_pattern">
                    <div dangerouslySetInnerHTML={{ __html: svgWithClass }} />
                </Row>
            </Row>
        </>
    );
};

export default PatternComponent;
