import React, { useContext, useState } from "react";
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CropTopList from "../croptop";
import { CropTopContext } from "../../../context/croptopContext";
import CustomerComponent from "../../customer";
import MeasurmentCropComponent from "../measurment";

const MainNightyComponent=()=>{
    const {selectedCropItem,handlePreviousClick,handleNextClick,currentStepCrop}=useContext(CropTopContext)
    const [activeKey, setActiveKey] = useState("Blouse");
    const { t } = useTranslation();
    const translatedButtons = t('main.buttons', { returnObjects: true });
    const onlyButtons_data = t('main.all_buttons', { returnObjects: true });

    const handleSelect = (key) => {
        setActiveKey(key);
    };
    
    return(
        <>
      {currentStepCrop === 1 && 
        <>
        <Row>
            <Col > 
                <Button variant={activeKey === "Blouse" ? "light mx-1":"outline-light mx-1"} size="sm"
                    onClick={()=>handleSelect("Blouse")}>{translatedButtons[8]}</Button>
            </Col>
        </Row>
        <Row className="bg-white rounded mt-1" style={{height:"79.5vh"}}>
            {activeKey === "Blouse" && <CropTopList/>}
        </Row>
        </>
     }
       {currentStepCrop ===2 && 
        <>
        <CustomerComponent/>
        </>}
        {currentStepCrop ===3 && 
        <>
        <MeasurmentCropComponent/>
        </>}
       <Row className="bg-white rounded mt-1 p-1">
        <Col>
        <Button size="sm" variant={currentStepCrop===1 ? "outline-dark":"dark"} onClick={handlePreviousClick}
            disabled={ currentStepCrop ===1}
        >{onlyButtons_data[1]}</Button>
        </Col>
        <Col className="text-end">
        <Button size="sm" variant={currentStepCrop===3 || currentStepCrop===4 ? "outline-dark":"dark"}
         onClick={handleNextClick}
        disabled={!selectedCropItem || currentStepCrop ===3 || currentStepCrop ===4}>{onlyButtons_data[0]}</Button>
        </Col>
       </Row>
      
     </>
    )
}
export default MainNightyComponent