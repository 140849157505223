import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { ChudidharContext } from "../../../../context/chudidharContext";


const ChudiCollarDisplay=({name,icon,data})=>{
    const {selectdChudiCollarItem,updateChudiCollar}=useContext(ChudidharContext);

    const getCollarItem=(jsondata)=>{

        const isSelected = selectdChudiCollarItem && selectdChudiCollarItem.name === name;
        if (!isSelected) {
            updateChudiCollar(jsondata);
        }
        else{
            updateChudiCollar(null)
        }

    }

    return(
        <>
        <Button className={selectdChudiCollarItem && 
                selectdChudiCollarItem.name === name ?"items_display btn-danger" :"items_display"}
            onClick={()=>getCollarItem(data,name)}>
            <img src={icon} alt={name} className="icon_image"/>
            {name}
        </Button>
       </>
    )
}
export default ChudiCollarDisplay