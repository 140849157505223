import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBarComponent from "../Navbar";
import { LoginContext } from "../../context/logincontext";
import { useNavigate } from "react-router-dom";
import RightCropComponent from "../CropTop/layout/rightside";
import MainNightyComponent from "../CropTop/layout/leftside";
import RightNightyComponent from "../CropTop/layout/rightside";


const NightyComponent=()=>{
    const {authTokens}=useContext(LoginContext);
    const navigate=useNavigate();

    useEffect(()=>{
        if(!authTokens){
          navigate("/login")
        }
    
      })
    return(
        <Container fluid className="home">
            <NavBarComponent />
            <Row className="p-1">
                <Col >
                <MainNightyComponent/>
                </Col>
                <Col lg={8} className="mx-1">
                <RightNightyComponent/>
                </Col>
            </Row>
        </Container>
    )
}
export default NightyComponent