import React, { useContext, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { LoginContext } from "../../context/logincontext";
import 'animate.css';
import "./login.css"

const LoginComponent = () => {
    const {ActiveOTP,loading,activeOtp,loginOtp,setEmail}=useContext(LoginContext)
    const [activeBtn, setActiveBtn] = useState(false);
    
    // Function to handle input change event
    const handleInputChange = (e) => {
        // Check if email input field is not empty
        setEmail(e.target.value)
        setActiveBtn(e.target.value.trim().length > 0);
    }

    return (
        <Container fluid className="landing" style={{height:"100vh"}}>
            <Row className="p-5 text-center">
                <Col  lg={8} sm={12} className="mt-5 p-3">
                <p className="text-uppercase text-white Para_up">Welcome to</p> 
                <h1 className="text-white head_style animate__animated animate__heartBeat 
                           animate__delay-5s animate__infinite">
                            TailorTrix</h1>
                <p className="text-uppercase text-white Para_down">where ideas unfold</p> 
                </Col>
                <Col lg={4} sm={12}  className="bg-white text-center mt-5 p-4 rounded login_div"
                 style={{marginTop:"-150px"}}>
                    <img src="\media\login\laser_svg_login.svg" alt="login_text" className="mb-3"
                        style={{paddingTop:"10px",height:"70px"}}/>
                    <form className="mt-2" onSubmit={ActiveOTP}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Email address"
                            className="mb-3"
                        >
                            <Form.Control 
                                type="text" 
                                name="email" 
                                placeholder="name@example.com"
                                onChange={handleInputChange} // Add onChange event handler
                            />
                        </FloatingLabel>
                        {activeOtp && 
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Enter Your OTP"
                            className="mb-3"
                        >
                            <Form.Control 
                                type="text" 
                                name="otp" 
                                placeholder="name@example.com"
                                />
                        </FloatingLabel>
                        }

                {loading ? (
                    <Button variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                        />
                        Loading...
                    </Button>
                ) : (
                    <>
                        {!activeOtp ? (
                            <Button
                                variant={activeBtn ? "primary" : "outline-primary"}
                                type="button"
                                onClick={loginOtp}
                                disabled={!activeBtn}
                                size="lg"
                            >
                                Send OTP
                            </Button>
                        ) : (
                            <Button
                                variant="success"
                                type="submit"
                                size="lg"
                            >
                                LOGIN
                            </Button>
                        )}
                    </>
                )}

                        
                       
                    </form>
                </Col>
            </Row>
        </Container>
    );
}

export default LoginComponent;
