import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import { ChudidharContext } from "../../../context/chudidharContext";
import axios from "axios";
import { CustomerContext } from "../../../context/customerContext";
import { useTranslation } from 'react-i18next';
import { LoginContext } from "../../../context/logincontext";
import { Button, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import urls from "../../../api.json";


const Umbrellameasurment=()=>{
    const {selectdChudiItem,getChudiCustomizationData,
        selectedChudiSleeveItem,
        getPatternPage}=useContext(ChudidharContext);
    const {customerMeasurment,orderNewId,orderOldId,
        setCustomerMeasurment}=useContext(CustomerContext);
    const [loading, setLoading] = useState(false);
    const [totalLength, setTotalLength] = useState('');
    const [bodyLength, setBodyLength] = useState('');
    const [mainLength, setMainLength] = useState('');
    const {authTokens}=useContext(LoginContext);
    const { t} = useTranslation();
    const measurement_data = t('main.page_3_buttons', { returnObjects: true });
    const onlyButtons_data = t('main.all_buttons', { returnObjects: true });
    const accessToken = authTokens?.access;
    const [updateId,setUpdateId]=useState("");
  
    const getLength=()=>{
        setMainLength(parseFloat(totalLength) - parseFloat(bodyLength));
    }

    const getDownloadCount=()=>{
      const updateCustomerData={
          "update_type": "measurment",
          "order_id":updateId,
          "measurment":{
            "churidar":[
              {
                "churidar_id": selectdChudiItem.name,
                "measurment":{
                  "length":parseFloat($("input[name='length']").val()),
                  "CenterChest": parseFloat($("input[name='CenterChest']").val()),
                  "ShoulderFinishing": 0,
                  "SleeveLenght": 0,
                  "SleeveRound": 0,
                  "MiddleHandRound": 0,
                  "FrontNeckHeight":0,
                  "BackNeckHeight": 0,
                  "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
                  "slit_open":0,
                  "WaistHeight":parseFloat($("input[name='WaistHeight']").val()),
                  "hip_round":0,
                  "FrontDartPoint":0,
                  "Full_Shoulder": 0,
                  "Arm_Hole_Round": 0,
                    }
                  }
              ]
          }
    
        }
        
        axios.put(urls.urls.BASE_URL+urls.urls.order_update,updateCustomerData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
        )
        .then(res=>{
            // console.log(res.data)
        })
        .catch(err=>{
          console.log("update customer Data",err)
        })

    }

    const getUmbrellBottomPattern=(e)=>{
        setLoading(true)
        e.preventDefault();

        const  MeasurmentUMData={
                "length":parseFloat($("input[name='length']").val()),
                "CenterChest": parseFloat($("input[name='CenterChest']").val()),
                "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
                "WaistHeight":parseFloat($("input[name='WaistHeight']").val()),
            }
         

         const  MeasurmentChudiData={
            "measurement":{
                "churidar_id":selectdChudiItem ? selectdChudiItem.id : 0,
                "sleeve_id":selectedChudiSleeveItem ? selectedChudiSleeveItem.id : 0,
                "patche_id": 0,
                "backdrop_id": 0,
                "frontneck_id": 0,
                "backneck_id": 0,
                "collar_id":0,
                "colorheight":0,
                "colorwidth":0,
                "collar_id":0,
                "length":parseFloat($("input[name='length']").val()),
                "CenterChest": parseFloat($("input[name='CenterChest']").val()),
                "ShoulderFinishing": 0,
                "SleeveLenght": 0,
                "SleeveRound": 0,
                "MiddleHandRound": 0,
                "FrontNeckHeight":0,
                "BackNeckHeight": 0,
                "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
                "slit_open":0,
                "WaistHeight":parseFloat($("input[name='WaistHeight']").val()),
                "hip_round":0,
                "FrontDartPoint":0,
                "Full_Shoulder": 0,
                "Arm_Hole_Round": 0,
                "order_id":updateId
                }
         }
            axios.post(urls.urls.BASE_URL+urls.urls.chudi_pattern,MeasurmentChudiData,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                }
            )
            .then(res=>{
            getChudiCustomizationData(res.data);
            setLoading(false);
            getPatternPage()
            getDownloadCount()
            setCustomerMeasurment(MeasurmentUMData);
            })
            .catch(err=>{
                console.log("measurments error ",err);
            })
    }

    const getSave=()=>{
        const UmbreelaMeasurmentInputData={
          "length":parseFloat($("input[name='length']").val()),
          "CenterChest": parseFloat($("input[name='CenterChest']").val()),
          "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
          "WaistHeight": parseFloat($("input[name='WaistHeight']").val()),
         }
        localStorage.setItem("ChudiUmbrellMeasurmentData",JSON.stringify(UmbreelaMeasurmentInputData));
        
        toast.success('Data Added Successfully!', {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
      }
    
      const resetInputs = () => {
        const inputs = [
        "TotalLength",
        "BodyLength",
          "length",
          "CenterChest",
          "WaistLoose",
          "WaistHeight",
        ];
        inputs.forEach((input) => {
          $(`input[name='${input}']`).val(""); // Reset input values to empty
        });
      };

      const setTrialData = () => {
        const trialData = {
         TotalLength:50,
         BodyLength:14,
          length: 36,
          CenterChest:32,
          WaistLoose:32,
          WaistHeight:14,
        };
    
        Object.entries(trialData).forEach(([key, value]) => {
          $(`input[name='${key}']`).val(value); // Set input values to trial data
        });
      };

      useEffect(()=>{
        if (orderOldId !== null) {
            setUpdateId(orderOldId);
          } else if (orderNewId && orderNewId.order_id !== null) {
            setUpdateId(orderNewId.order_id);
          } else {
            setUpdateId(null);
          }

        if(customerMeasurment){
            setCustomerMeasurment(customerMeasurment);
          }
          else{
            setCustomerMeasurment(null);
          }
      },[])
      

    return(
        <>
        <Row className="bg-white rounded mt-1" style={{height:"79.5vh"}}>
          <div>
          <form  onSubmit={getUmbrellBottomPattern}>
              <Row className="items_main_mea mt-2">
                  <Col lg={12} className="mb-1 mt-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[18]}>
                      <Form.Control type="float" name="TotalLength" placeholder="name@example.com" 
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[19]}>
                          <Form.Control type="float" name="BodyLength" placeholder="name@example.com"
                            />
                          </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[2]}>
                      <Form.Control type="float" name="length" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[11]}>
                          <Form.Control type="float" name="WaistLoose" placeholder="name@example.com" 
                           />
                          </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[4]}>
                      <Form.Control type="float" name="CenterChest" placeholder="name@example.com" 
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[15]}>
                          <Form.Control type="float" name="WaistHeight" placeholder="name@example.com"
                           />
                          </FloatingLabel>
                  </Col>
                  
              </Row>
              <Row>
                  <Col>
                      {loading ? (
                      <Button variant="outline-success" size="sm" disabled>
                          <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          />
                          Loading...
                      </Button>):( 
                      <Button size="sm" variant="outline-success" type="submit">{onlyButtons_data[4]}</Button>)
                      }
                      <Button size="sm" variant="outline-dark mx-1"
                      type="button" onClick={()=>resetInputs()}>{onlyButtons_data[5]}</Button> 
                      <Button size="sm" variant="outline-info mx-1"
                      type="button" onClick={()=>setTrialData()}>{onlyButtons_data[6]}</Button>
                      <Button size="sm" variant="outline-info mx-2" disabled>Select Size</Button>
                  </Col>
                  
              </Row>
          </form>
          </div>
        </Row>
        </>
    )
}
export default Umbrellameasurment