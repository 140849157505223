import './App.css';
import RoutingComponent from './component';
import "bootstrap/dist/css/bootstrap.min.css"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import TutorialVideo from './component/tutorial';
// import LogoVideo from './component/tutorial/logoanimation';



function App() {
  return (
    <>
      <RoutingComponent/>
      <ToastContainer
      position="top-center"
      autoClose={4000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark" />
      {/* <LogoVideo /> */}
    </>
  );
}

export default App;
