import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { ChudidharContext } from "../../../../../context/chudidharContext";

const HighneckDisplay=({data,icon,name})=>{
    const {selectdChudiHighNeckItem,updateChudiHighNeck,updateChudiDFront
    ,updateChudiFrontModel}=useContext(ChudidharContext);

    const getHighNecks=(jsonData)=>{
        updateChudiHighNeck(jsonData);
        updateChudiFrontModel(null);
        updateChudiDFront(null)

    }

    return(
        <>
            <Button className={selectdChudiHighNeckItem && selectdChudiHighNeckItem.name === name ?
             "items_display btn-danger":"items_display"}
               onClick={()=>getHighNecks(data)}>
                {/* <img src={icon} alt={name} className="icon_image"/> */}
                {name}
            </Button>
        </>
    )
}
export default HighneckDisplay