import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import axios from "axios";
import urls from "../api.json"


export const LoginContext = createContext({});

const LoginProvider = ({ children }) => {
    const [authTokens, setAuthTokens] = useState(() => Cookies.get("DemoauthTokens") ? JSON.parse(Cookies.get("DemoauthTokens")) : null);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [activeOtp, setActiveOtp] = useState(false);
    

    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            toast.dismiss(); // Clear all toasts on component unmount
        };
    }, []);

    let loginOtp = () => {
        setLoading(true);
        const payload = {
            "email": email
        };

        axios.post(urls.urls.BASE_URL+urls.urls.register, payload)
            .then(res => {
                setLoading(false);
                setActiveOtp(true);
                toast.success(res.data.message, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    toastId: "login-toast", 
                  });
            })
            .catch(err => {
                setLoading(false);
                toast.error("User with this email address already exists", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
            });

    };

    let ActiveOTP = (e) => {
        e.preventDefault();
        const payload = {
            "email": e.target.email.value,
            "otp": e.target.otp.value
        };
        axios.post(urls.urls.BASE_URL+urls.urls.active_user, payload)
            .then(res => {
                loginUser(); // Call loginUser function after OTP activation
            })
            .catch(err => {
                toast.error(err.response.data.message, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
            });

    };

    let loginUser = () => {
        const payload = {
            "email": email,
            "password": "1234"
        };
        axios.post(urls.urls.BASE_URL+urls.urls.login, payload)
            .then(res => {
                navigate("/");
                setAuthTokens(res.data);
                Cookies.set("DemoauthTokens",JSON.stringify(res.data));
                toast.success("Login Successfully", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    toastId: "login-toast", 
                  });
            })
            .catch(err => {
                console.log("LOGIN API Error", err);
            });

    };


    let logoutUser = () => {
        setAuthTokens(null);
        Cookies.remove("DemoauthTokens");
        navigate("/login");
        setActiveOtp(false);
    };

    let contextData = {
        authTokens: authTokens,
        loginUser: loginUser,
        logoutUser: logoutUser,
        loading: loading,
        activeOtp: activeOtp,
        loginOtp: loginOtp,
        setEmail: setEmail,
        email: email,
        ActiveOTP: ActiveOTP
    };

    useEffect(() => {
        let Time = 120 * 60 * 1000;
        let interval = setInterval(() => {
            Cookies.remove("DemoauthTokens")
            window.location.reload();
        }, Time);
        return () => clearInterval(interval);
    }, [authTokens]);


    return (
        <LoginContext.Provider value={contextData}>
            {children}
        </LoginContext.Provider>
    );
};

export default LoginProvider;
