import React, { createContext, useContext, useEffect, useState } from "react";
import { LoginContext } from "./logincontext";
import axios from "axios";
import urls from "../api.json"

export const CropTopContext=createContext({});

const CropTopProvider=({children})=>{
    const {authTokens}=useContext(LoginContext);
    const accessToken = authTokens?.access;
    const [cropTopData,setCropTopData]=useState([]);
    const [allcroptopData,setAllCropTopData]=useState([]);
    const [selectedCropItem,setSelectedCropItem]=useState("");
    const [activeCropColor,setActiveCropColor]=useState("");
    const [currentStepCrop, setCurrentStepCrop] = useState(1);
    
    const getCroptopItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.croptop_items, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setCropTopData(res.data.categories)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })
    }


    const handleNextClick = () => {
      if (currentStepCrop < 4) {
        setCurrentStepCrop(currentStepCrop + 1);
        }
  }

  const handlePreviousClick = () => {
      if (currentStepCrop > 1) {
        setCurrentStepCrop(currentStepCrop - 1);
      }
  }

  const getAllCropClear=()=>{
    setCurrentStepCrop(1);
    updateCropItems(null)
  }

    const updateCropItems=(value)=>{
      setSelectedCropItem(value)
    }

    const updateCropColor=(value)=>{
      setActiveCropColor(value)
    }

    
    useEffect(()=>{
        if(authTokens){
            getCroptopItems();
        }
    },[authTokens])

    useEffect(() => {
        const items = cropTopData.reduce((acc, category) => acc.concat(category.items), []);
        setAllCropTopData(items); 
      }, [cropTopData]);




    return(
        <CropTopContext.Provider  value={{allcroptopData,cropTopData,updateCropItems,
          handlePreviousClick,handleNextClick,currentStepCrop,getAllCropClear,
          updateCropColor,activeCropColor,selectedCropItem}} >
            {children}
        </CropTopContext.Provider>
    )
}

export default CropTopProvider