import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios"
import { LoginContext } from "./logincontext";
import urls from "../api.json"


export const BlouseContext = createContext({});

const BlouseProvider = ({ children }) => {
    const {authTokens} = useContext(LoginContext);
    const accessToken = authTokens?.access;
    const [userData,setUserData]=useState(null);
    const [blouseData,setBlouseData]=useState([]);
    const [sleeveData,setSleeveData]=useState([]);
    const [itemsData, setItemsData] = useState([]);
    const [backDropData, setBackDropData] = useState([]);
    const [defaultFrontData, setDefaultFrontData] = useState([]);
    const [defaultBackData, setDefaultBackData] = useState([]);
    const [modelBill, setModelBill] = useState(false);
    const [highNeckData, setHighNeckData] = useState([]);
    const [frontModelData, setFrontModelData] = useState([]);
    const [backModelData, setBackModelData] = useState([]);
    const [collarData, setCollarData] = useState([]);
    const [selectedBlouse, setSelectedBlouse] = useState(null);
    const [selectedSleeveItem, setSelectedSleeveItem] = useState(null);
    const [selectedCollarItem, setSelectedCollarItem] = useState(null);
    const [selectedDefaultFrontneckItem, setSelectedDefaultFrontneckItem] = useState(null);
    const [selectedDefaultBackneckItem, setSelectedDefaultBackneckItem] = useState(null);
    const [selectedFrontneckItem, setSelectedFrontneckItem] = useState(null);
    const [selectedBackneckItem, setSelectedBackneckItem] = useState(null);
    const [selectedHighneckItem, setSelectedHighneckItem] = useState(null);
    const [selectedBackDropItem, setSelectedBackDropItem] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [customizeData,setCustomizeData]=useState(null);
    const [activeColor,setActiveColor]=useState("");
    const [activeFabric,setActiveFabric]=useState("");
    const [run, setRun] = useState(false);



    const getUserData=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.user_profile,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
              // console.log("kskdn",res.data);
              setUserData(res.data);
          })
          .catch(err=>{
              console.log("User Data API Error",err);
          })
       }
   
    const handleBlouseItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.blouse_items,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then(res=>{
                setBlouseData(res.data.categories)
            })
            .catch(err=>{
                console.log("Blouse Items API Error",err);
            })
    }
    const handleSleeveItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.blouse_sleeve,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then(res=>{
                setSleeveData(res.data)
            })
            .catch(err=>{
                console.log("Blouse Items API Error",err);
            })
    }
    const handleBackDropItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.blouse_backdrop,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then(res=>{
                setBackDropData(res.data)
            })
            .catch(err=>{
                console.log("BackDrop Data API Error",err);
            })
    }
    const handleDefaultFrontNeckItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.blouse_d_frontnecks,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then(res=>{
                setDefaultFrontData(res.data)
            })
            .catch(err=>{
                console.log("BackDrop Data API Error",err);
            })
    }
    const handleDefaultBackNeckItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.blouse_d_backnecks,
            {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(res=>{
                setDefaultBackData(res.data)
            })
            .catch(err=>{
                console.log("BackDrop Data API Error",err);
            })
    }
    const handleFrontModelItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.blouse_frontmodels,
            {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(res=>{
                setFrontModelData(res.data)
            })
            .catch(err=>{
                console.log("BackDrop Data API Error",err);
            })
    }
    const handleBackModelItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.blouse_backmodels,
            {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(res=>{
                setBackModelData(res.data)
            })
            .catch(err=>{
                console.log("BackDrop Data API Error",err);
            })
    }
    const handleHighNeckItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.blouse_highmodels,
            {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(res=>{
                setHighNeckData(res.data)
            })
            .catch(err=>{
                console.log("BackDrop Data API Error",err);
            })
    }
    
    const handleCollarItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.blouse_collar,
            {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(res=>{
                setCollarData(res.data)
            })
            .catch(err=>{
                console.log("BackDrop Data API Error",err);
            })
    }

    const updateBlouseItems=(value)=>{
        setSelectedBlouse(value)
    }
    const updateSleeveItems=(value)=>{
        setSelectedSleeveItem(value)
    }

    const updateFabric=(value)=>{
        setActiveFabric(value)
      }

    const updateDefaultFrontNecks=(value)=>{
        setSelectedDefaultFrontneckItem(value)
    }
    const updateDefaultBackNecks=(value)=>{
        setSelectedDefaultBackneckItem(value)
    }
    const updateBackNeckModels=(value)=>{
        setSelectedBackneckItem(value)
    }
    const updateFrontNeckModels=(value)=>{
        setSelectedFrontneckItem(value)
    }
    const updateHighNeckItem=(value)=>{
        setSelectedHighneckItem(value)
    }
    const updateCollarItem=(value)=>{
        setSelectedCollarItem(value)
    }
    const updateBackDropItem=(value)=>{
        setSelectedBackDropItem(value)
    }

    const getHome=()=>{
        setCurrentStep(1)
    }

    const getClearAll=()=>{
        setCurrentStep(1)
        updateBlouseItems(null)
        updateSleeveItems(null)
        updateDefaultFrontNecks(null)
        updateDefaultBackNecks(null)
        updateBackNeckModels(null)
        updateFrontNeckModels(null)
        updateHighNeckItem(null)
        updateCollarItem(null)
        setSelectedBackDropItem(null)
        updateFabric(null)
    }

    const handleNextClick = () => {
        if (currentStep < 4) {
            setCurrentStep(currentStep + 1);
          }
    }

    const startTour = () => {
        setRun(!run);
      }

    const updateColor=(value)=>{
        setActiveColor(value)
      }

    const handlePreviousClick = () => {
        if (currentStep > 1) {
          setCurrentStep(currentStep - 1);
        }
    }

    const getCustomizationData=(value)=>{
        setCustomizeData(value)
      }

    

    useEffect(() => {
        if(accessToken){
            getUserData();
            handleBlouseItems();
            handleSleeveItems();
            handleBackDropItems();
            handleDefaultFrontNeckItems();
            handleDefaultBackNeckItems();
            handleFrontModelItems();
            handleBackModelItems();
            handleCollarItems();
            handleHighNeckItems();
        }
    }, [accessToken]);

    useEffect(() => {
        const items = blouseData.reduce((acc, category) => acc.concat(category.items), []);
        setItemsData(items); 
      }, [blouseData]);


    return (
        <BlouseContext.Provider value={{sleeveData,blouseData,itemsData,backDropData,
            defaultFrontData,defaultBackData,frontModelData,backModelData,collarData,
            highNeckData,updateHighNeckItem,updateCollarItem,getCustomizationData,getClearAll,
            customizeData,getHome,activeColor,updateColor,userData,activeFabric,updateFabric,
            updateBlouseItems,selectedBlouse,selectedSleeveItem,updateSleeveItems,updateBackDropItem,
            updateDefaultFrontNecks,updateDefaultBackNecks,updateBackNeckModels,updateFrontNeckModels,
            selectedDefaultFrontneckItem,selectedDefaultBackneckItem,selectedFrontneckItem,
            selectedBackneckItem,selectedHighneckItem,selectedCollarItem,selectedBackDropItem,
            handlePreviousClick,handleNextClick,currentStep,modelBill, setModelBill,
            run, setRun,startTour
            }}>
            {children}
        </BlouseContext.Provider>
    );
};

export default BlouseProvider;
