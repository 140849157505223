import React, { useContext, useEffect, useState } from "react";
import { FaPrint } from "react-icons/fa6";
import { CustomerContext } from "../../../context/customerContext";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { KidsContext } from "../../../context/kidsContext";
import { LoginContext } from "../../../context/logincontext";
import { Col, Row } from "react-bootstrap";


const KidsPatternComponent=()=>{
    const {kidsCustomizeData,selectdKidsItem,
        selectdKidsSleeveItem,selectdKidsDFrontItem,
        selectdKidsDBackItem,selectdKidsFrontModelItem,selectdKidsBackModelItem}=useContext(KidsContext);
    const [dxfPath,setDxfPath]=useState(kidsCustomizeData.dxf_path);
    const {orderNewId,orderOldId,customerMeasurment}=useContext(CustomerContext);
        const [updateId,setUpdateId]=useState("");
        const {authTokens}=useContext(LoginContext);
        const { t } = useTranslation();
         const customize_data = t('main.page_4_buttons', { returnObjects: true });
        const accessToken = authTokens?.access;
    
   
    useEffect(()=>{
        if (orderOldId !== null) {
            setUpdateId(orderOldId);
          }
           else if (orderNewId && orderNewId.order_id !== null) {
            setUpdateId(orderNewId.order_id);
          } else {
            setUpdateId(null);
          }
        setDxfPath(kidsCustomizeData.dxf_path)
    },[kidsCustomizeData.url]);

    const disableAllLinks = true; 
    const svgWithClass = kidsCustomizeData.url ? kidsCustomizeData.url.replace('<svg', '<svg class="icon_item_image "') : null;
    return(
        <>
          <Row className="bg-light rounded mt-1">
                <Col className="p-1" lg={12}>
                   <h6 >Pattern View / 
                    <span className="text-danger"> {selectdKidsItem && selectdKidsItem.name}</span>
                    <span className="text-info"> {selectdKidsSleeveItem && selectdKidsSleeveItem.name}</span>
                    <span className="text-danger"> {selectdKidsDFrontItem && selectdKidsDFrontItem.name}</span>
                    <span className="text-info"> {selectdKidsDBackItem && selectdKidsDBackItem.name}</span>
                    <span className="text-info"> {selectdKidsFrontModelItem && selectdKidsFrontModelItem.name}</span>
                    <span className="text-danger"> {selectdKidsBackModelItem && selectdKidsBackModelItem.name}</span>
                   </h6>
                </Col>
                <hr style={{margin:"0px",marginBottom:"0px",marginTop:"-5px"}}/>
                {/* <Col>
                    {dxfPath.map(each => {
                            const path = each.split("/");
                            const buttonName = path[path.length - 2];

                            // Check if the button name contains 'dxf_files'
                            if (buttonName.includes('dxf_files')) {
                            // Replace the button name containing 'dxf_files' with your desired name
                            return (
                                <a  href={disableAllLinks ? undefined : (process.env['REACT_APP_BASE_URL'] + each)}
                                className={`btn btn-outline-success btn-sm btn_style mx-1 ${disableAllLinks ? 'disabled' : ''}`}
                                onClick={disableAllLinks ? (e) => e.preventDefault() : null}
                                // onClick={getDownloadCount}
                                >
                                    <FaPrint className="mx-1"/>
                                Cut Pattern
                                </a>
                            );
                            }

                            // If the button name doesn't contain 'dxf_files', display the original name
                            return (
                            <a  href={disableAllLinks ? undefined : (process.env['REACT_APP_BASE_URL'] + each)}
                            className={`btn btn-outline-success btn-sm btn_style mx-1 ${disableAllLinks ? 'disabled' : ''}`}
                            onClick={disableAllLinks ? (e) => e.preventDefault() : null}>
                                <FaPrint className="mx-1"/>
                                {buttonName}
                            </a>
                            );
                        })
                        }
                </Col> */}
                <Row className="items_pattern">
                      <div dangerouslySetInnerHTML={{ __html:svgWithClass}} />
                  </Row>
                        
            </Row>
        </>
    )
}
export default KidsPatternComponent