import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { ChudidharContext } from "../../../../context/chudidharContext";
import { useTranslation } from 'react-i18next';


const SleeveChudiDisplay=({name,icon,data})=>{
    const {updateChudiSleeve,selectdChudiSleeveItem}=useContext(ChudidharContext);
    const { t } = useTranslation();
        const SleeveData = t('main.chudi_sleeves_data', { returnObjects: true });

    const getSleeveItem=(jsonData)=>{
        updateChudiSleeve(jsonData)
        
    }
    return(
        <Button variant="white border" className={selectdChudiSleeveItem && 
            selectdChudiSleeveItem.name === name ?"items_display btn-danger" :"items_display"}
        onClick={()=>getSleeveItem(data,name)}>
            <img src={icon} alt={name} className="icon_image"/>
            {SleeveData[name] ? SleeveData[name] :name}
        </Button>
    )
}
export default SleeveChudiDisplay