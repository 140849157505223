import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./laanding.css"
import { LoginContext } from "../../context/logincontext";
import { Link, useNavigate } from "react-router-dom";
import { PackageContext } from "../../context/packageContext";
import Blouse3D from "../View3DViewOnly/blouse";
import Chudidhar3D from "../View3DViewOnly";
import Kids3D from "../View3DViewOnly/kids";
import Westren3D from "../View3DViewOnly/westren";
import Croptops3D from "../View3DViewOnly/croptops";

const LandingComponent=()=>{
  const {logoutUser,authTokens}=useContext(LoginContext);
  const {handleSelectedName}=useContext(PackageContext);
  const [blouse,setBlouse]=useState(false);
    const [chudi,setChudi]=useState(false);
    const [kids,setKids]=useState(false);
    const [westren,setWestren]=useState(false);
    const [croptop,setCroptop]=useState(false);
  const navigate=useNavigate()


  const handleBlouse = () => {
    setBlouse(true);
    setChudi(false);
    setKids(false);
    setWestren(false);
    setCroptop(false)
  };
const  handleChudi=()=>{
   setChudi(true);
   setBlouse(false);
   setKids(false);
   setWestren(false);
    setCroptop(false)
  }

  const  handleKids=()=>{
    setChudi(false);
    setBlouse(false);
    setKids(true);
    setWestren(false);
    setCroptop(false)
   }
   const  handleWestren=()=>{
    setChudi(false);
    setBlouse(false);
    setKids(false);
    setWestren(true);
    setCroptop(false)
   }

   const  handleCroptops=()=>{
     setChudi(false);
     setBlouse(false);
     setKids(false);
     setWestren(false);
    setCroptop(true)
    }


  useEffect(()=>{
    if(!authTokens){
      navigate("/login")
    }

  })
    return(
        <>
        <Container fluid className="landing" style={{height:"100vh"}}>
         <Row className="p-1 align-items-start">
            <Col lg={{span:4,offset:8}} className="text-end">
                <img src="media\navbar\nav_log.svg" alt="Nav_Logo" style={{ height: "50px" }} />
            </Col>
          </Row>
          <Row style={{height:"67%"}}>
            <Col lg={{span:5,offset:6}} >
            {blouse && <Blouse3D/>}
            {chudi && <Chudidhar3D />}
            {kids && <Kids3D/>}
            {westren && <Westren3D/>}
            {croptop && <Croptops3D/>}
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={1}>
            </Col>
             <Col>
                <div className="Button-wrapper" onMouseEnter={handleBlouse}>
                    <div className="Button-cover" >
                    <div className="Button-logo">
                      <h6 className="heading_label">Blouse</h6>
                      </div>
                    </div>
                       <Link to="/blouse">
                        <button className="Button text-primary" onClick={()=>handleSelectedName("Blouse")}>
                                Click To Design
                        </button>
                        </Link>
                </div>
             </Col>
             <Col>
                <div className="Button-wrapper" onMouseEnter={handleChudi}>
                    <div className="Button-cover" >
                    <div className="Button-logo">
                      <h6 className="heading_label">Chudidhar</h6>
                      </div>
                    </div>
                       <Link to="/chudi">
                        <button className="Button text-primary" onClick={()=>handleSelectedName("Chudidhar")}>
                                Click To Design
                        </button>
                        </Link>
                </div>
             </Col>
             <Col>
                <div className="Button-wrapper" onMouseEnter={handleKids}>
                    <div className="Button-cover" >
                    <div className="Button-logo">
                      <h6 className="heading_label">Kids</h6>
                      </div>
                    </div>
                       <Link to="/kids">
                        <button className="Button text-primary" onClick={()=>handleSelectedName("Kids")}>
                                Click To Design
                        </button>
                        </Link>
                </div>
             </Col>
             <Col>
                <div className="Button-wrapper" onMouseEnter={handleCroptops}>
                    <div className="Button-cover" >
                    <div className="Button-logo">
                      <h6 className="heading_label">CropTops</h6>
                      </div>
                    </div>
                    <Link to="/croptop">
                      <button className="Button text-primary" onClick={()=>handleSelectedName("CropTop")}>
                              Click To Design
                      </button>
                    </Link>
                </div>
             </Col>
             <Col>
                <div className="Button-wrapper" onMouseEnter={handleWestren}>
                    <div className="Button-cover" >
                    <div className="Button-logo">
                      <h6 className="heading_label">Nighty</h6>
                      </div>
                    </div>
                    <Link to="/nighty">
                      <button className="Button">
                           Comming Soon
                      </button>
                      </Link>
                </div>
             </Col>
             <Col>
                <Button variant="outline-light" onClick={logoutUser}>Logout</Button>
             </Col>
          </Row>
        </Container>
        {/* {authTokens && <TutorialVideo />} */}
        </>
    )
}
export default LandingComponent