import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { CustomerContext } from "../../context/customerContext";
import OldCustomer from "./oldcustomer";
import { useTranslation } from 'react-i18next';

const CustomerComponent = () => {
    const { getID, orderNewId, CustomerFrom, formData } = useContext(CustomerContext);
    const [selectedDate, setSelectedDate] = useState('');
    const { t } = useTranslation();
    const customre_filter = t('main.page_2_buttons', { returnObjects: true });
    const onlyButtons_data = t('main.all_buttons', { returnObjects: true });

    const initialDate = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

    // Set the initial date to display
    useState(() => {
        setSelectedDate(initialDate);
        getID();
    }, []);

    return (
        <>
            {formData && formData.order_id ? (
                <OldCustomer />
            ) : (
                <>
                <Row>
                    <Col > 
                        <Button variant="outline-light" size="sm"
                        >{customre_filter[0]}</Button>
                    </Col>
                </Row>
                <Row className="bg-white rounded mt-1" style={{ height: "79.5vh" }}>
                    <div>
                        <form className="p-1 mt-2" onSubmit={CustomerFrom}>
                            <FloatingLabel controlId="floatingPassword" label={customre_filter[3]} className="mb-2">
                                <Form.Control type="text" placeholder="Order ID" name="orderId"
                                    value={orderNewId && orderNewId?.order_id} />
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingPassword" label={customre_filter[4]} className="mb-2">
                                <Form.Control type="text" placeholder="Customer Name"
                                    name="customerName" />
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingPassword" label={customre_filter[5]} className="mb-2">
                                <Form.Control type="text" placeholder="Phone Number"
                                    name="phoneNumber" />
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingPassword" label={customre_filter[6]} className="mb-2">
                                <Form.Control type="text" placeholder="Address"
                                    name="address" />
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingPassword" label={customre_filter[7]} className="mb-2">
                                <Form.Control type="date" placeholder="Password" value={selectedDate}
                                    name="order_date" />
                            </FloatingLabel>
                            <FloatingLabel controlId="floatingPassword" label={customre_filter[8]} className="mb-2">
                                <Form.Control type="date" placeholder="Password"
                                    name="exp_del_date" />
                            </FloatingLabel>
                            <Col className="text-center">
                                <Button type="submit" id="Customer_save">{onlyButtons_data[2]}</Button>
                            </Col>
                        </form>
                    </div>
                </Row>
                </>
            )}
        </>
    );
};

export default CustomerComponent;
