import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { KidsContext } from "../../../../context/kidsContext";
import { useTranslation } from 'react-i18next';

const SleeveDisplay=({name,icon,data})=>{
    const {updateKidsSleeve,selectdKidsSleeveItem}=useContext(KidsContext);
    const { t } = useTranslation();
    const sleeve_Data = t('main.kids_sleeve', { returnObjects: true });

    const getSleeveItem=(jsonData,itemName)=>{
        updateKidsSleeve(jsonData)
    }

    return(
        <Button variant="bg-light border" thick
        className={selectdKidsSleeveItem && 
            selectdKidsSleeveItem.name === name ?"items_display btn-danger" :"items_display"}
        onClick={()=>getSleeveItem(data,name)}>
            <img src={icon} alt={name} className="icon_image"/>
            {sleeve_Data[name] ? sleeve_Data[name] :name}
           
        </Button>
    )
}
export default SleeveDisplay