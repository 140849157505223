import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBarComponent from "../Navbar";
import MainChudiComponent from "./mainleft";
import RightCudiComponent from "./layout/rightside";
import { LoginContext } from "../../context/logincontext";
import { useNavigate } from "react-router-dom";


const ChudiComponent=()=>{
    const {authTokens}=useContext(LoginContext);
    const navigate=useNavigate();

    useEffect(()=>{
        if(!authTokens){
          navigate("/login")
        }
    
      })
    return(
        <Container fluid className="home">
            <NavBarComponent/>
            <Row className="p-1">
                <Col >
                <MainChudiComponent/>
                </Col>
                <Col lg={8} className="mx-1">
                <RightCudiComponent/>
                </Col>
            </Row>
        </Container>
    )
}
export default ChudiComponent