import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { BlouseContext } from "../../../../../context/blouseContext";
import { useTranslation } from 'react-i18next';


const DBackDisplay=({name,data,icon})=>{
    const {updateDefaultBackNecks,selectedDefaultBackneckItem,
        updateBackNeckModels}=useContext(BlouseContext);
        const { t, i18n } = useTranslation();
        const BackDefaultNeckData = t('main.back_neck_data', { returnObjects: true });

    const getDBackNecks=(jsondata)=>{
        updateDefaultBackNecks(jsondata);
        updateBackNeckModels(null)
    }
    return(
        <>
        <Button variant="white border" className={selectedDefaultBackneckItem && selectedDefaultBackneckItem.name === name ? 
            "items_display btn-danger":"items_display"}
           onClick={()=>getDBackNecks(data,name)}>
            <img src={icon} alt={name} className="icon_image"/>
            {BackDefaultNeckData[name] ? BackDefaultNeckData[name] :name}
        </Button>
       </>
    )
}
export default DBackDisplay