import React, { useContext, useEffect, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import {  toast } from 'react-toastify';
import axios from "axios";
import { KidsContext } from "../../../context/kidsContext";
import kidsBlouseSizes from "./kidsBlouse.json";
import { useTranslation } from 'react-i18next';
import { CustomerContext } from "../../../context/customerContext";
import urls from "../../../api.json";
import { LoginContext } from "../../../context/logincontext";
import { Button, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";

const KidsBlousemeasurment=()=>{
    const {selectdKidsItem,getKidsCustomizationData,
        selectedKidsCollorItem,selectedBackModelItem,selectedFrontModelItem,
        selectedDefaultBackNeckItem,selectedDefaultfrontNeckItem,
        selectedKidsSleeveItem,customerKidsMeasurment,
        getPatternPage}=useContext(KidsContext);
        const {customerMeasurment,
            setCustomerMeasurment}=useContext(CustomerContext);
        const {authTokens}=useContext(LoginContext);
        const accessToken = authTokens?.access;
   
    const [localMeasurmentData,setLocalMeasurmentData]=useState({});
    const [loading, setLoading] = useState(false);
    const [bottomLength,setBottomLength]=useState("");
    const { t} = useTranslation();
        const measurement_data = t('main.page_3_buttons', { returnObjects: true });
      const onlyButtons_data = t('main.all_buttons', { returnObjects: true });

    const [selectedSizeData, setSelectedSizeData] = useState(null);

    const handleSizeChange = (event) => {
        const newSize = parseInt(event.target.value);
        const foundSize = kidsBlouseSizes.find((sizeObj) => sizeObj.size === newSize);
    
        if (foundSize) {
          setSelectedSizeData(foundSize.data);
    
        } else {
          setSelectedSizeData(null);
        }
      };

    const onChangeBottomlength=()=>{
        const length=parseFloat($("input[name='length']").val());
        const total_length=parseFloat($("input[name='total_length']").val());
    
        setBottomLength(total_length-length)
      }
      

    const getBlusePattern=(e)=>{
        setLoading(true)
        e.preventDefault();
        const blouseUpdate={
            "length":parseFloat($("input[name='length']").val()),
                "total_length":parseFloat($("input[name='total_length']").val()),
                "CenterChest": parseFloat($("input[name='CenterChest']").val()),
                "ShoulderFinishing": parseFloat($("input[name='ShoulderFinishing']").val()),
                "SleeveLenght": parseFloat($("input[name='SleeveLenght']").val()),
                "SleeveRound": parseFloat($("input[name='SleeveRound']").val()),
                "MiddleHandRound": 0,
                "FrontNeckHeight": parseFloat($("input[name='FrontNeckHeight']").val()),
                "BackNeckHeight": parseFloat($("input[name='BackNeckHeight']").val()),
                "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
                "FrontDartPoint": parseFloat($("input[name='FrontDartPoint']").val()),
                "WaistHeight":parseFloat($("input[name='WaistHeight']").val()),
                "hip_round":parseFloat($("input[name='hip_round']").val()),
                "slit_open":parseFloat($("input[name='slit_open']").val()),
                "Full_Shoulder": parseFloat($("input[name='Full_Shoulder']").val()),
                "Arm_Hole_Round": parseFloat($("input[name='Arm_Hole_Round']").val()),
                "bottom_length": parseFloat($("input[name='bottom_length']").val()),
        }
         const  MeasurmentKidsPantData={
            "measurement":{
                "kids_id": selectdKidsItem ? selectdKidsItem.id : 0,
                "sleeve_id": selectedKidsSleeveItem ? selectedKidsSleeveItem.id : 0,
                "patche_id":  0,
                "collar_id": selectedKidsCollorItem ? selectedKidsCollorItem.id : 0 ,
                "backdrop_id": 0,
                "frontneck_id": selectedFrontModelItem ? selectedFrontModelItem.id : 0,
                "backneck_id": selectedBackModelItem ? selectedBackModelItem.id : 0,
                "default_front_neck_id":  selectedDefaultfrontNeckItem ? selectedDefaultfrontNeckItem.id : 0,
                "default_back_neck_id":selectedDefaultBackNeckItem ? selectedDefaultBackNeckItem.id : 0,
                "high_neck_id":0, 
                "length":parseFloat($("input[name='length']").val()),
                "total_length":parseFloat($("input[name='total_length']").val()),
                "CenterChest": parseFloat($("input[name='CenterChest']").val()),
                "ShoulderFinishing": parseFloat($("input[name='ShoulderFinishing']").val()),
                "SleeveLenght": parseFloat($("input[name='SleeveLenght']").val()),
                "SleeveRound": parseFloat($("input[name='SleeveRound']").val()),
                "MiddleHandRound": 0,
                "FrontNeckHeight": parseFloat($("input[name='FrontNeckHeight']").val()),
                "BackNeckHeight": parseFloat($("input[name='BackNeckHeight']").val()),
                "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
                "FrontDartPoint": parseFloat($("input[name='FrontDartPoint']").val()),
                "WaistHeight":parseFloat($("input[name='WaistHeight']").val()),
                "hip_round":parseFloat($("input[name='hip_round']").val()),
                "slit_open":parseFloat($("input[name='slit_open']").val()),
                // "sleeve_patti_height":selectedSleeveItem && selectedSleeveItem.name === "Puff Sleeve" ? parseFloat($("input[name='sleeve_patti_height']").val()) : 0,
                "Full_Shoulder": parseFloat($("input[name='Full_Shoulder']").val()),
                "Arm_Hole_Round": parseFloat($("input[name='Arm_Hole_Round']").val()),
                }
         }
            axios.post(urls.urls.BASE_URL+urls.urls.kids_pattern,MeasurmentKidsPantData,{
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              })
            .then(res=>{
            getKidsCustomizationData(res.data);
            setCustomerMeasurment(blouseUpdate);
            setLoading(false);
            getPatternPage()
            })
            .catch(err=>{
                console.log("measurments error ",err);
            })
    }

    const getSave=()=>{
        const ChudiMeasurmentInputData={
          "length":parseFloat($("input[name='length']").val()),
          "CenterChest": parseFloat($("input[name='CenterChest']").val()),
          "ShoulderFinishing": parseFloat($("input[name='ShoulderFinishing']").val()),
          "SleeveLenght": parseFloat($("input[name='SleeveLenght']").val()),
          "SleeveRound": parseFloat($("input[name='SleeveRound']").val()),
          "MiddleHandRound": parseFloat($("input[name='MiddleHandRound']").val()),
          "FrontNeckHeight": parseFloat($("input[name='FrontNeckHeight']").val()),
          "BackNeckHeight": parseFloat($("input[name='BackNeckHeight']").val()),
          "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
          "WaistHeight": parseFloat($("input[name='WaistHeight']").val()),
          "slit_open":parseFloat($("input[name='slit_open']").val()),
          "hip_round":parseFloat($("input[name='hip_round']").val()),
          "FrontDartPoint":parseFloat($("input[name='FrontDartPoint']").val()),
          "Full_Shoulder": parseFloat($("input[name='Full_Shoulder']").val()),
          "Arm_Hole_Round": parseFloat($("input[name='Arm_Hole_Round']").val()),
         }
        localStorage.setItem("ChudiMeasurmentData",JSON.stringify(ChudiMeasurmentInputData));
        
        toast.success('Data Added Successfully!', {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
      }
      const resetInputs = () => {
        const inputs = [
          "length",
          "CenterChest",
          "total_length",
          "ShoulderFinishing",
          "SleeveLenght",
          "SleeveRound",
          "MiddleHandRound",
          "FrontNeckHeight",
          "BackNeckHeight",
          "WaistLoose",
          "slit_open",
          "WaistHeight",
          "hip_round",
          "FrontDartPoint",
          "bottom_length",
          "Full_Shoulder",
          "Arm_Hole_Round",
        ];
    
        inputs.forEach((input) => {
          $(`input[name='${input}']`).val(""); // Reset input values to empty
        });
      };
    
      const setTrialData = () => {
        const trialData = {
          length:9,
          total_length:20,
          CenterChest:24,
          ShoulderFinishing:2,
          SleeveLenght:6,
          SleeveRound:8,
          MiddleHandRound:9,
          FrontNeckHeight:3.5,
          BackNeckHeight:3.5,
          WaistLoose:20,
          slit_open:10,
          WaistHeight:10.5,
          hip_round:28,
          FrontDartPoint:4.5,
          bottom_length:11,
          Full_Shoulder:10,
          Arm_Hole_Round:11,
        };
        Object.entries(trialData).forEach(([key, value]) => {
          $(`input[name='${key}']`).val(value); // Set input values to trial data
        });
      };

    useEffect(()=>{
    if(customerMeasurment){
        setCustomerMeasurment(customerMeasurment);
        }
        else{
        setCustomerMeasurment(null);
        }
        
    if (selectedSizeData) {
        Object.entries(selectedSizeData).forEach(([key, value]) => {
          $(`input[name='${key}']`).val(value); // Set input values to trial data
        });
      }
    })


    return(
        <>
        <form className="row measurmrnt_from_style mt-1" onSubmit={getBlusePattern}>
               <Row className="items_main_mea">
                  <Col lg={6} className="mb-1 mt-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[2]}>
                      <Form.Control type="float" name="length" placeholder="name@example.com" 
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[18]}>
                          <Form.Control type="float" name="total_length" placeholder="name@example.com"
                            />
                          </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[4]}>
                      <Form.Control type="float" name="CenterChest" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[5]}>
                          <Form.Control type="float" name="ShoulderFinishing" placeholder="name@example.com" 
                           />
                          </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[6]}>
                      <Form.Control type="float" name="SleeveLenght" placeholder="name@example.com" 
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[7]}>
                          <Form.Control type="float" name="SleeveRound" placeholder="name@example.com"
                           />
                          </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[9]}>
                      <Form.Control type="float" name="FrontNeckHeight" placeholder="name@example.com" 
                       />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[10]}>
                          <Form.Control type="float" name="BackNeckHeight" placeholder="name@example.com" 
                          />
                          </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[11]}>
                      <Form.Control type="float" name="WaistLoose" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label="WaistHeight">
                      <Form.Control type="float" name="WaistHeight" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[17]}>
                      <Form.Control type="float" name="hip_round" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[16]}>
                      <Form.Control type="float" name="slit_open" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[26]}>
                      <Form.Control type="float" name="bottom_length" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[12]}>
                      <Form.Control type="float" name="FrontDartPoint" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[13]}>
                      <Form.Control type="float" name="Full_Shoulder" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[14]}>
                          <Form.Control type="float" name="Arm_Hole_Round" placeholder="name@example.com" 
                           />
                          </FloatingLabel>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      {loading ? (
                      <Button variant="outline-success" size="sm" disabled>
                          <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          />
                          Loading...
                      </Button>):( 
                      <Button size="sm" variant="outline-success" type="submit"> {onlyButtons_data[4]}</Button>)
                      }
                      <Button size="sm" variant="outline-dark mx-1"
                      type="button" onClick={()=>resetInputs()}> {onlyButtons_data[5]}</Button> 
                      <Button size="sm" variant="outline-info mx-1"
                      type="button" onClick={()=>setTrialData()}> {onlyButtons_data[6]}</Button>
                      <Button size="sm" variant="outline-info mx-2" disabled>Select Size</Button>
                  </Col>
                  
              </Row>
            </form>
        </>
    )
}
export default KidsBlousemeasurment