import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, Stage,OrbitControls } from '@react-three/drei';
import './view3donly.css';

function Model(props) {
  const { scene } = useGLTF('/media/view3D/final/Croptop.glb');
  const model = useRef();

  useFrame(() => {
    if (model.current) {
      model.current.rotation.y += 0.015; // Adjust rotation speed as needed
    }
  });

  return <primitive object={scene} {...props} ref={model} />;
}

export default function Croptops3D() {
  return (
    <div className="view-header" style={{marginTop:"30px"}}>
      <Canvas>
      <OrbitControls enablePan={true}
                    enableZoom={true}
                    enableRotate={false} />
        <Stage>
          <Model scale={8.1} />
        </Stage>
      </Canvas>
    </div>
  );
}
