import React, { useContext, useEffect, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import axios from "axios";
import { KidsContext } from "../../../context/kidsContext";
import { useTranslation } from 'react-i18next';
import { CustomerContext } from "../../../context/customerContext";
import urls from "../../../api.json";
import { LoginContext } from "../../../context/logincontext";
import { Button, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";

const KidsPantmeasurment=()=>{
    const {selectdKidsItem,getKidsCustomizationData,
        selectedKidsCollorItem,selectedBackModelItem,selectedFrontModelItem,
        selectedDefaultBackNeckItem,selectedDefaultfrontNeckItem,
        selectedKidsSleeveItem,customerKidsMeasurment,
        getPatternPage}=useContext(KidsContext);
    const {authTokens}=useContext(LoginContext);
    const accessToken = authTokens?.access;
    const {customerMeasurment,
        setCustomerMeasurment}=useContext(CustomerContext);
    const [loading, setLoading] = useState(false);
    const { t} = useTranslation();
    const measurement_data = t('main.page_3_buttons', { returnObjects: true });
    const onlyButtons_data = t('main.all_buttons', { returnObjects: true });

    const getPantPattern=(e)=>{
        setLoading(true)
        e.preventDefault();
        const Pantupdate={
                "length":parseFloat($("input[name='length']").val()),
                "CenterChest": 0,
                "ShoulderFinishing": 0,
                "SleeveLenght": 0,
                "total_length":0,
                "SleeveRound": 0,
                "MiddleHandRound": 0,
                "FrontNeckHeight":0,
                "BackNeckHeight": 0,
                "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
                "slit_open":0,
                "WaistHeight": 0,
                "hip_round":parseFloat($("input[name='hip_round']").val()),
                "knee_height":parseFloat($("input[name='knee_height']").val()),
                "knee_loose":parseFloat($("input[name='knee_loose']").val()),
                "bottom_round":parseFloat($("input[name='bottom_round']").val()),
                "FrontDartPoint":0,
                "Full_Shoulder": 0,
                "Arm_Hole_Round": 0,
        }
         const  MeasurmentKidsPantData={
            "measurement":{
                "kids_id": selectdKidsItem ? selectdKidsItem.id : 0,
                "sleeve_id": selectedKidsSleeveItem ? selectedKidsSleeveItem.id : 0,
                "patche_id":  0,
                "collar_id": selectedKidsCollorItem ? selectedKidsCollorItem.id : 0 ,
                "backdrop_id": 0,
                "frontneck_id": selectedFrontModelItem ? selectedFrontModelItem.id : 0,
                "backneck_id": selectedBackModelItem ? selectedBackModelItem.id : 0,
                "default_front_neck_id":  selectedDefaultfrontNeckItem ? selectedDefaultfrontNeckItem.id : 0,
                "default_back_neck_id":selectedDefaultBackNeckItem ? selectedDefaultBackNeckItem.id : 0,
                "high_neck_id":0, 
                "length":parseFloat($("input[name='length']").val()),
                "CenterChest": 0,
                "ShoulderFinishing": 0,
                "SleeveLenght": 0,
                "total_length":0,
                "SleeveRound": 0,
                "MiddleHandRound": 0,
                "FrontNeckHeight":0,
                "BackNeckHeight": 0,
                "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
                "slit_open":0,
                "WaistHeight": 0,
                "hip_round":parseFloat($("input[name='hip_round']").val()),
                "knee_height":parseFloat($("input[name='knee_height']").val()),
                "knee_loose":parseFloat($("input[name='knee_loose']").val()),
                "bottom_round":parseFloat($("input[name='bottom_round']").val()),
                "FrontDartPoint":0,
                "Full_Shoulder": 0,
                "Arm_Hole_Round": 0,
                }
         }
        axios.post(urls.urls.BASE_URL+urls.urls.kids_pattern,MeasurmentKidsPantData,{
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
        .then(res=>{
        getKidsCustomizationData(res.data);
        setCustomerMeasurment(Pantupdate);
        setLoading(false);
        getPatternPage()
        })
        .catch(err=>{
            console.log("measurments error ",err);
        })
    }
    const resetInputs = () => {
        const inputs = [
        "hip_round",
        "knee_height",
        "length",
        "knee_loose",
        "bottom_round",
        "WaistLoose",
        ];
        inputs.forEach((input) => {
          $(`input[name='${input}']`).val(""); // Reset input values to empty
        });
      };

      const setTrialData = () => {
        const trialData = {
          hip_round:42,
          knee_height:22,
          length: 40,
          knee_loose:24,
          bottom_round:16,
          WaistLoose:36,
        };
    
        Object.entries(trialData).forEach(([key, value]) => {
          $(`input[name='${key}']`).val(value); // Set input values to trial data
        });
      };

      useEffect(()=>{
        if(customerMeasurment){
            setCustomerMeasurment(customerMeasurment);
            }
            else{
            setCustomerMeasurment(null);
            }
        },[]
        )

    return(
        <>
        <form className="row mt-2" onSubmit={getPantPattern}>
              <Row className="items_main_mea">
                  <Col lg={12} className="mt-2 mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[2]}>
                          <Form.Control type="float" name="length" placeholder="name@example.com"
                            />
                          </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mt-2 mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[11]}>
                      <Form.Control type="float" name="WaistLoose" placeholder="name@example.com"/>
                      </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[17]}>
                          <Form.Control type="float" name="hip_round" placeholder="name@example.com" 
                           />
                          </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[20]}>
                      <Form.Control type="float" name="knee_height" placeholder="name@example.com" 
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[21]}>
                          <Form.Control type="float" name="knee_loose" placeholder="name@example.com"
                           />
                          </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[22]}>
                      <Form.Control type="float" name="bottom_round" placeholder="name@example.com" 
                       />
                      </FloatingLabel>
                  </Col>
                  
              </Row>
              <Row>
                  <Col>
                      {loading ? (
                      <Button variant="outline-success" size="sm" disabled>
                          <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          />
                          Loading...
                      </Button>):( 
                      <Button size="sm" variant="outline-success" type="submit"> {onlyButtons_data[4]}</Button>)
                      }
                      <Button size="sm" variant="outline-dark mx-1"
                      type="button" onClick={()=>resetInputs()}> {onlyButtons_data[5]}</Button> 
                      <Button size="sm" variant="outline-info mx-1"
                      type="button" onClick={()=>setTrialData()}> {onlyButtons_data[6]}</Button>
                      <Button size="sm" variant="outline-info mx-2" disabled>Select Size</Button>
                  </Col>
                  
              </Row>
        </form>
        </>
    )
}
export default KidsPantmeasurment