import React, { useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { ChudidharContext } from "../../../../context/chudidharContext";
import { useTranslation } from 'react-i18next';
import { CustomerContext } from "../../../../context/customerContext";

const ChudiDisplay=({data,name,icon})=>{
    const {selectdChudiItem,updateChudiItems,selectdChudiSleeveItem,
        selectdChudiDFrontItem,selectdChudiDBackItem,selectdChudiFrontModelItem,
        selectdChudiBackModelItem,selectdChudiHighNeckItem,chudiSleeveData,
        chudiHighNeckData,chudiDefaultFrontData,chudiFrontModelData,chudiDefaultBackData,
        chudiBackModelData,chudiCollarData,selectdChudiCollarItem,
        updateChudiSleeve,updateChudiDFront,updateChudiDBack
        ,updateChudiFrontModel,updateChudiBackModel,updateChudiHighNeck,updateChudiBackDrop
    ,updateChudiCollar}=useContext(ChudidharContext);
    const {setCustomerMeasurment,setFormData}=useContext(CustomerContext);
    const { t } = useTranslation();
    const ChudidharData = t('main.chudidhar_data', { returnObjects: true });

    const getChudiData=(jsonData)=>{
        updateChudiItems(jsonData);
        updateChudiCollar(null);
        updateChudiBackDrop(null);
        setCustomerMeasurment(null);
        setFormData(null)

        
        // Sleeve neck logic
        if(jsonData.name.toLowerCase().includes("pant") || 
        jsonData.name.toLowerCase().includes("umbrella") ||
        jsonData.name.toLowerCase().includes("bottom")
        ){
            updateChudiSleeve(null)

        }else{
            if(selectdChudiSleeveItem === null){
                chudiSleeveData.map((each)=>{
                    if(each.name === "Basic Sleeve"){
                        updateChudiSleeve(each)
                    }
                })
            }
        }

        // Update The Front Neck Shapes
        if(jsonData.front_neck_shapes){
            if(jsonData.name.includes("High") || jsonData.name.includes("Halter")){
                if(selectdChudiHighNeckItem === null){
                    chudiHighNeckData.map(each=>{
                        if(each.name === "Round Neck"){
                            updateChudiHighNeck(each)
                            updateChudiDFront(null)
                            updateChudiFrontModel(null)
                        }
                    })
                }
            }else{
                if(selectdChudiDFrontItem === null){
                    chudiDefaultFrontData.map((each)=>{
                        if(each.name === "Round Neck"){
                            updateChudiDFront(each)
                            updateChudiHighNeck(null)
                            updateChudiFrontModel(null)
                        }
                    })
                }
                else{
                    chudiFrontModelData.map((each)=>{
                        if(each.name === "Round Neck"){
                            updateChudiFrontModel(each);
                            updateChudiDFront(null)
                        }
                    })

                }

            }
        }
        else{
            updateChudiHighNeck(null)
            updateChudiDFront(null);
            updateChudiFrontModel(null)
        }

         // Update The Back Neck Shapes
        if(jsonData.back_neck_shapes){
            if(selectdChudiBackModelItem === null){
                chudiDefaultBackData.map((each)=>{
                    if(each.name === "Round Neck"){
                        updateChudiDBack(each);
                        updateChudiBackModel(null);
                    }
                })
            }
            else{
                chudiDefaultBackData.map((each)=>{
                    if(each.name === "Round Neck"){
                        updateChudiDBack(each);
                        // updatePatcheItem(null);
                        updateChudiBackModel(null);
                    }
                })
            }
            
        }
        else{
            updateChudiBackModel(null);
            updateChudiDBack(null);
            // updatePatcheItem(null);
        }
    }

    useEffect(()=>{
        if (selectdChudiItem && selectdChudiItem.name.toLowerCase().includes("close")) {
           if (selectdChudiCollarItem === null) {
             const defaultCloseNeckCollar = chudiCollarData.find((each) => each.name === "Close Neck");
             if (defaultCloseNeckCollar) {
                updateChudiCollar(defaultCloseNeckCollar);
             }
           }
         }
         if (selectdChudiItem && selectdChudiItem.name.toLowerCase().includes("boat")) {
            if (selectdChudiCollarItem === null) {
              const defaultCloseNeckCollar = chudiCollarData.find((each) => each.name === "Boat Neck");
              if (defaultCloseNeckCollar) {
                 updateChudiCollar(defaultCloseNeckCollar);
              }
            }
          }
          
      },[selectdChudiItem])


    return(
        <>
           <Button variant="bg-white border"
           className={selectdChudiItem && selectdChudiItem.name === name ? 
            "items_display_chudi btn-danger":"items_display_chudi"}
               onClick={()=>getChudiData(data,name)}>
                <img src={icon} alt={name} className="icon_image_chudi"/>
                {ChudidharData[name] ?ChudidharData[name]:name}
            </Button>
        </>
    )
}
export default ChudiDisplay