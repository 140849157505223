import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import FrontSvgContent from "../svgContent/frontsvg";
import BackSvgContent from "../svgContent/backsvg";
import { BlouseContext } from "../../../context/blouseContext";
import PatternComponent from "../customization/pattern";
import ColorComponent from "../color&fabric";
import GetCustomerData from "../../customer/getOld";
import { CustomerContext } from "../../../context/customerContext";
import { useTranslation } from 'react-i18next';


const RightSideComponent=()=>{
    const [activeKey, setActiveKey] = useState("Both"); 
    const {selectedBlouse,selectedSleeveItem,selectedCollarItem,selectedDefaultFrontneckItem,
        selectedDefaultBackneckItem,selectedFrontneckItem,selectedBackneckItem,currentStep,
        updateBlouseItems,updateSleeveItems,updateDefaultFrontNecks,updateDefaultBackNecks,
        updateBackNeckModels,updateFrontNeckModels,updateHighNeckItem,updateCollarItem,
        updateBackDropItem,getHome,updateColor,updateFabric,
        selectedBackDropItem,selectedHighneckItem}=useContext(BlouseContext);
        const { t } = useTranslation();
        const translatedButtons = t('main.right_buttons', { returnObjects: true });
        const right_svg_Filter = t('main.right_buttons_filter', { returnObjects: true });

    const {setFormData,setShowOld,setOrderOldId,setOrderDataDetails}=useContext(CustomerContext)

    const handleSelect = (key) => {
        setActiveKey(key);
    };

    const handleNew=()=>{
        updateBlouseItems(null)
        updateSleeveItems(null)
        updateDefaultFrontNecks(null)
        updateDefaultBackNecks(null)
        updateBackNeckModels(null)
        updateFrontNeckModels(null)
        updateHighNeckItem(null)
        updateCollarItem(null)
        updateBackDropItem(null)
        getHome()
        updateColor(null)
        updateFabric(null)
        setFormData(null)
        setShowOld(false)
        setOrderDataDetails(null)
        setOrderOldId(null)
    }

    const handleHome=()=>{
        getHome()
    }

    
    return(
        <>
        <Row >
            <Col lg={2} >
             <Button variant="outline-light mx-1" disabled={currentStep === 1}
             onClick={handleHome}
              size="sm">{translatedButtons[0]}</Button>
             <Button variant="outline-light mx-1" size="sm"
              disabled={!selectedBlouse} onClick={handleNew}>{translatedButtons[1]}</Button>
            </Col>
           <GetCustomerData/>
        </Row>
        {currentStep === 4 ? (
        <>
            <PatternComponent/>
        </>
        ):(
            <>
             <Row className="bg-white mt-1 rounded" style={{height:"70vh"}}>
                <Row className="pt-1">
                    <Col className="">
                     <p><span className="text-danger bold">{t('main.right_buttons_head')} /</span> 
                        <span className="text-dark f-500"> {selectedBlouse && selectedBlouse.name}</span>
                        <span className="mx-1">{selectedSleeveItem && selectedSleeveItem.name}</span>
                        <span className="mx-1">{selectedDefaultFrontneckItem && selectedDefaultFrontneckItem.name}</span>
                        <span className="mx-1">{selectedDefaultBackneckItem && selectedDefaultBackneckItem.name}</span>
                        <span className="mx-1">{selectedFrontneckItem && selectedFrontneckItem.name}</span>
                        <span className="mx-1">{selectedBackneckItem && selectedBackneckItem.name}</span>
                        <span className="mx-1">{selectedHighneckItem && selectedHighneckItem.name}</span>
                        <span className="mx-1">{selectedCollarItem && selectedCollarItem.name}</span>
                        <span className="mx-1">{selectedBackDropItem && selectedBackDropItem.name}</span>
                    </p>
                    </Col>
                    <hr style={{margin:"0px",marginTop:"-10px",marginLeft:"10px"}}/>
                </Row>
                <Row style={{marginTop:"-2px"}}>
                <Col className="">
                    <Button variant={activeKey ==="Both" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                    onClick={()=>handleSelect("Both")}>{right_svg_Filter[0]}</Button>
                    <Button variant={activeKey ==="Front View" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                    onClick={()=>handleSelect("Front View")}>{right_svg_Filter[1]}</Button>
                    <Button variant={activeKey ==="Back View" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                    onClick={()=>handleSelect("Back View")}>{right_svg_Filter[2]}</Button>
                </Col>
                </Row>
                    {activeKey ==="Both" &&
                        (
                            <Row>
                                <Col className="" lg={6} sm={3} md={6} id="front_svg_item">
                                    <FrontSvgContent />
                                </Col>
                                <Col className="" lg={6} sm={3} md={6}>
                                    <BackSvgContent/>
                                </Col>
                            </Row>
                        
                        )}
                        {activeKey ==="Front View" &&
                        (
                            <Row className="text-center">
                                <Col className="" lg={12} sm={12} md={12}>
                                    <FrontSvgContent/>
                                </Col>
                            </Row>
                        )}
                        {activeKey ==="Back View" &&
                        (
                            <Row className="text-center">
                                <Col className="" lg={12} sm={12} md={12}>
                                    <BackSvgContent/>
                                </Col>
                            </Row>
                        )}
                 </Row>
                 <Row className="bg-light mt-1 rounded p-1">
                    <ColorComponent/>
                 </Row>
            </>
        )}
       
        
       </>
    )
}
export default RightSideComponent