import React, { useContext } from "react";
import parse from 'html-react-parser';
import { ChudidharContext } from "../../../context/chudidharContext";



const ChudiUmbrellaSvgContent=()=>{
    const {selectdChudiItem,activeChudiColor}=useContext(ChudidharContext);

    return(
        <>
         <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
          width="100mm" height="100mm" version="1.1" 
            viewBox="1000 2400 6750 11400"
            style={{shapeRendering:"geometricPrecision",textRendering:"geometricPrecision",imageRendering:"optimizeQuality",fillRule:"evenodd",clipRule:"evenodd",
            fill:activeChudiColor  ? activeChudiColor || `url(#image-fill)` : "white"}}
            >    
            {/* <defs>
                <pattern id="image-fill"  width="100%" height="100%" patternContentUnits="userSpaceOnUse">
                    <image href={activeFabric}  width="100%" />
                </pattern>
                
            </defs> */}
              
            <path style={{stroke:"#16406A",strokeWidth:"70.56",strokeLinecap:"round",strokeLinejoin:'round',strokeMiterlimit:"22.9256",
            fill:activeChudiColor  ? activeChudiColor || `url(#image-fill)` : "white"}}
                d={selectdChudiItem ? (selectdChudiItem.base_value 
                 ) : null}></path>
            
                {selectdChudiItem && parse(selectdChudiItem.front_svg)}
        </svg>
        </>
    )
}
export default ChudiUmbrellaSvgContent