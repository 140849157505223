import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";
import axios from "axios";
import { ChudidharContext } from "../../../context/chudidharContext";
import { toast } from 'react-toastify';
import { CustomerContext } from "../../../context/customerContext";
import ChudidharTrail from "./chudidhartrailmeasurment.json"
import { useTranslation } from 'react-i18next';
import { Button, FloatingLabel, Form, Spinner,Row,Col } from "react-bootstrap";
import { LoginContext } from "../../../context/logincontext";
import urls from "../../../api.json";


const UmbrellaBodyMeasurment=()=>{
  const {selectdChudiItem,getChudiCustomizationData,selectdChudiFrontModelItem,
    selectdChudiBackModelItem,selectdChudiBackDropItem,selectdChudiCollarItem,selectdChudiHighNeckItem,
    selectdChudiSleeveItem,selectdChudiDFrontItem,selectdChudiDBackItem,
    customerChudiMeasurment,
    getPatternPage}=useContext(ChudidharContext); 
    const {customerMeasurment,orderNewId,orderOldId,
        setCustomerMeasurment}=useContext(CustomerContext);
        const {authTokens}=useContext(LoginContext);
    const accessToken = authTokens?.access;
    const [loading, setLoading] = useState(false);
    const { t} = useTranslation();
    const measurement_data = t('main.page_3_buttons', { returnObjects: true });
    const onlyButtons_data = t('main.all_buttons', { returnObjects: true });
    const [localChudiMeasurmentData,setLocalChudiMeasurmentData]=useState({});
    const [selectedSizeData, setSelectedSizeData] = useState(null);
    const [updateId,setUpdateId]=useState("");

    const handleSizeChange = (event) => {
        const newSize = parseInt(event.target.value);
        const foundSize = ChudidharTrail.find((sizeObj) => sizeObj.size === newSize);
    
        if (foundSize) {
          setSelectedSizeData(foundSize.data);
    
        } else {
          setSelectedSizeData(null);
        }
      };

      const getDownloadCount=()=>{
        const updateCustomerData={
            "update_type": "measurment",
            "order_id":updateId,
            "measurment":{
              "churidar":[
                {
                  "churidar_id": selectdChudiItem.name,
                  "sleeve_id": selectdChudiSleeveItem?.name,
                  "dfront_neck_id": selectdChudiDFrontItem?.name,
                  "dback_neck_id": selectdChudiDBackItem?.name,
                  "collar_id": selectdChudiCollarItem?.name,
                  "frontmodel_id": selectdChudiFrontModelItem?.name,
                  "backmodel_id": selectdChudiBackModelItem?.name,
                  "highneck_id": selectdChudiHighNeckItem?.name,
                  "backdrop_id": selectdChudiBackDropItem?.name,
                  "measurment":{
                    "length":parseFloat($("input[name='length']").val()),
                    "CenterChest": parseFloat($("input[name='CenterChest']").val()),
                    "ShoulderFinishing": parseFloat($("input[name='ShoulderFinishing']").val()),
                    "SleeveLenght": parseFloat($("input[name='SleeveLenght']").val()),
                    "SleeveRound": parseFloat($("input[name='SleeveRound']").val()),
                    "MiddleHandRound": parseFloat($("input[name='MiddleHandRound']").val()),
                    "FrontNeckHeight": parseFloat($("input[name='FrontNeckHeight']").val()),
                    "BackNeckHeight": parseFloat($("input[name='BackNeckHeight']").val()),
                    "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
                    "slit_open":0,
                    "WaistHeight": 0,
                    "hip_round":0,
                    "FrontDartPoint":parseFloat($("input[name='FrontDartPoint']").val()),
                    "Full_Shoulder": parseFloat($("input[name='Full_Shoulder']").val()),
                    "Arm_Hole_Round": parseFloat($("input[name='Arm_Hole_Round']").val()),
                      }
                    }
                ]
            }
      
          }
          
          axios.put(urls.urls.BASE_URL+urls.urls.order_update,updateCustomerData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
          )
          .then(res=>{
              // console.log(res.data)
          })
          .catch(err=>{
            console.log("update customer Data",err)
          })
  
      }


    const getChudidharPattren=(e)=>{
        setLoading(true)
        e.preventDefault();

       

       
         const  MeasurmentChudiData={
            "measurement":{
              "churidar_id":selectdChudiItem ? selectdChudiItem.id : 0,
              "sleeve_id":selectdChudiSleeveItem ? selectdChudiSleeveItem.id : 0,
              "patche_id": 2,
              "backdrop_id": selectdChudiBackDropItem ? selectdChudiBackDropItem.id:0,
              "frontneck_id": selectdChudiFrontModelItem ? selectdChudiFrontModelItem.id:0,
              "backneck_id": selectdChudiBackModelItem ? selectdChudiBackModelItem.id:0,
              "collar_id":selectdChudiCollarItem ?selectdChudiCollarItem.id :0,
              "high_neck_id":selectdChudiHighNeckItem?selectdChudiHighNeckItem.id:0,
              "colorheight":10,
              "colorwidth":12,
              "default_back_neck_id":selectdChudiDBackItem ? selectdChudiDBackItem.id:0,
              "default_front_neck_id":selectdChudiDFrontItem ? selectdChudiDFrontItem.id:0,
                "length":parseFloat($("input[name='length']").val()),
                "CenterChest": parseFloat($("input[name='CenterChest']").val()),
                "ShoulderFinishing": parseFloat($("input[name='ShoulderFinishing']").val()),
                "SleeveLenght": parseFloat($("input[name='SleeveLenght']").val()),
                "SleeveRound": parseFloat($("input[name='SleeveRound']").val()),
                "MiddleHandRound": parseFloat($("input[name='MiddleHandRound']").val()),
                "FrontNeckHeight": parseFloat($("input[name='FrontNeckHeight']").val()),
                "BackNeckHeight": parseFloat($("input[name='BackNeckHeight']").val()),
                "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
                "slit_open":0,
                "WaistHeight": 0,
                "hip_round":0,
                "FrontDartPoint":parseFloat($("input[name='FrontDartPoint']").val()),
                "Full_Shoulder": parseFloat($("input[name='Full_Shoulder']").val()),
                "Arm_Hole_Round": parseFloat($("input[name='Arm_Hole_Round']").val()),
                "order_id":updateId
                }
         }
                axios.post(urls.urls.BASE_URL+urls.urls.chudi_pattern,MeasurmentChudiData,
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                })
                .then(res=>{
                getChudiCustomizationData(res.data);
                setLoading(false);
                getPatternPage()
                getDownloadCount()
                })
                .catch(err=>{
                    console.log("measurments error ",err);
                })
    }

    const getSave=()=>{
        const ChudiMeasurmentInputData={
          "length":parseFloat($("input[name='length']").val()),
          "CenterChest": parseFloat($("input[name='CenterChest']").val()),
          "ShoulderFinishing": parseFloat($("input[name='ShoulderFinishing']").val()),
          "SleeveLenght": parseFloat($("input[name='SleeveLenght']").val()),
          "SleeveRound": parseFloat($("input[name='SleeveRound']").val()),
          "MiddleHandRound": parseFloat($("input[name='MiddleHandRound']").val()),
          "FrontNeckHeight": parseFloat($("input[name='FrontNeckHeight']").val()),
          "BackNeckHeight": parseFloat($("input[name='BackNeckHeight']").val()),
          "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
          "WaistHeight": parseFloat($("input[name='WaistHeight']").val()),
          "slit_open":parseFloat($("input[name='slit_open']").val()),
          "hip_round":parseFloat($("input[name='hip_round']").val()),
          "FrontDartPoint":parseFloat($("input[name='FrontDartPoint']").val()),
          "Full_Shoulder": parseFloat($("input[name='Full_Shoulder']").val()),
          "Arm_Hole_Round": parseFloat($("input[name='Arm_Hole_Round']").val()),
         }
        localStorage.setItem("ChudiMeasurmentData",JSON.stringify(ChudiMeasurmentInputData));
        
        toast.success('Data Added Successfully!', {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
      }
      const resetInputs = () => {
        const inputs = [
          "length",
          "CenterChest",
          "ShoulderFinishing",
          "SleeveLenght",
          "SleeveRound",
          "MiddleHandRound",
          "FrontNeckHeight",
          "BackNeckHeight",
          "WaistLoose",
          "slit_open",
          "WaistHeight",
          "hip_round",
          "Full_Shoulder",
          "Arm_Hole_Round",
        ];
    
        inputs.forEach((input) => {
          $(`input[name='${input}']`).val(""); // Reset input values to empty
        });
      };
    
      const setTrialData = () => {
        const trialData = {
          length: 14,
          CenterChest:36,
          ShoulderFinishing:2.5,
          SleeveLenght:6,
          SleeveRound:12,
          MiddleHandRound:13,
          FrontNeckHeight:6,
          BackNeckHeight:7,
          WaistLoose:32,
          slit_open:20,
          WaistHeight:13.5,
          FrontDartPoint:9.5,
          hip_round:42,
          Full_Shoulder:15,
          Arm_Hole_Round:16,
        };
    
        Object.entries(trialData).forEach(([key, value]) => {
          $(`input[name='${key}']`).val(value); // Set input values to trial data
        });
      };


      useEffect(()=>{

        if (orderOldId !== null) {
            setUpdateId(orderOldId);
          } else if (orderNewId && orderNewId.order_id !== null) {
            setUpdateId(orderNewId.order_id);
          } else {
            setUpdateId(null);
          }

        if (selectedSizeData) {
            Object.entries(selectedSizeData).forEach(([key, value]) => {
              $(`input[name='${key}']`).val(value); // Set input values to trial data
            });
          }

        if(customerMeasurment){
            setCustomerMeasurment(customerMeasurment);
          }
          else{
            setCustomerMeasurment(null);
          }

        const mesurData=JSON.parse(localStorage.getItem("ChudiMeasurmentData"));
        if(mesurData !== null){
            setLocalChudiMeasurmentData(mesurData);
        }
      },[selectedSizeData])
    return(
        <>
        <Row className="bg-white rounded mt-1" style={{height:"79.5vh"}}>
          <div>
          <form  onSubmit={getChudidharPattren}>
              <Row className="items_main_mea mt-2">
                  <Col lg={6} className="mb-1 mt-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[2]}>
                      <Form.Control type="float" name="length" placeholder="name@example.com" 
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[3]}>
                          <Form.Control type="float" name="CenterChest" placeholder="name@example.com"
                            />
                          </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[5]}>
                      <Form.Control type="float" name="ShoulderFinishing" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[6]}>
                          <Form.Control type="float" name="SleeveLenght" placeholder="name@example.com" 
                           />
                          </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[7]}>
                      <Form.Control type="float" name="SleeveRound" placeholder="name@example.com" 
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[8]}>
                          <Form.Control type="float" name="MiddleHandRound" placeholder="name@example.com"
                           />
                          </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[9]}>
                      <Form.Control type="float" name="FrontNeckHeight" placeholder="name@example.com" 
                       />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[10]}>
                          <Form.Control type="float" name="BackNeckHeight" placeholder="name@example.com" 
                          />
                          </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[11]}>
                      <Form.Control type="float" name="WaistLoose" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[12]}>
                      <Form.Control type="float" name="FrontDartPoint" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>    
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[13]}>
                      <Form.Control type="float" name="Full_Shoulder" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[1]}  >
                          <Form.Control type="float" name="Arm_Hole_Round" placeholder="name@example.com" 
                           />
                          </FloatingLabel>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      {loading ? (
                      <Button variant="outline-success" size="sm" disabled>
                          <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          />
                          Loading...
                      </Button>):( 
                      <Button size="sm" variant="outline-success" type="submit"> {onlyButtons_data[4]}</Button>)
                      }
                      <Button size="sm" variant="outline-dark mx-1"
                      type="button" onClick={()=>resetInputs()}>{onlyButtons_data[5]}</Button> 
                      <Button size="sm" variant="outline-info mx-1"
                    type="button" onClick={()=>setTrialData()}>{onlyButtons_data[6]}</Button>
                      <Button size="sm" variant="outline-info mx-2" disabled>Select Size</Button>
                  </Col>
                  
              </Row>
          </form>
          </div>
         </Row>
        </>
       
    )
}
export default UmbrellaBodyMeasurment