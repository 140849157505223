import React, { useContext, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { ChudidharContext } from "../../../context/chudidharContext";
import ChudiDefaultFrontNecks from "./defaultFrontNeck";
import ChudiDefaultBackNecks from "./defaultBackNeck";
import ChudiFrontModelNecks from "./frontNeckModel";
import ChudiBackModelNecks from "./backNeckModel";
import ChudiDefaultHighNecks from "./defaultHighNeck";
import { useTranslation } from 'react-i18next';


const ChudiNecksComponent=()=>{
    const {selectdChudiItem}=useContext(ChudidharContext);
    const [activeModel,setActiveModel]=useState("Front Necks");
    const { t } = useTranslation();
    const neck_filter__Data = t('main.necks_buttons', { returnObjects: true });

    const handleNecks=(value)=>{
        setActiveModel(value)
    }
    return(
        <>
        {selectdChudiItem && selectdChudiItem.front_neck_shapes && 
            (selectdChudiItem.name.includes('High') || selectdChudiItem.name.includes('Halter')) ? (
                <>
                  <ChudiDefaultHighNecks/>
                </>
            ):(
                <>
            <div className="mt-1">
                <Col className="text-center">
                <Button variant={activeModel === "Front Necks" ? "dark px-1":"outline-dark px-1"} size="sm"
                onClick={()=>handleNecks("Front Necks")}>{neck_filter__Data[0]}</Button>
                <Button variant={activeModel === "Back Necks" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                onClick={()=>handleNecks("Back Necks")}>{neck_filter__Data[1]}</Button>
                <Button variant={activeModel === "Front M Necks" ? "dark px-1":"outline-dark px-1"} size="sm"
                onClick={()=>handleNecks("Front M Necks")} title="Front Model Necks">{neck_filter__Data[2]}</Button>
                <Button variant={activeModel === "Back M Necks" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                onClick={()=>handleNecks("Back M Necks")} title="Back Model Necks">{neck_filter__Data[3]}</Button>
                </Col>
            </div>
            {activeModel === "Front Necks" && <ChudiDefaultFrontNecks/>}
            {activeModel === "Back Necks" && <ChudiDefaultBackNecks/>}
            {activeModel === "Front M Necks" && <ChudiFrontModelNecks/>}
            {activeModel === "Back M Necks" && <ChudiBackModelNecks/>}
        </>
        )}
        </>
    )
}
export default ChudiNecksComponent