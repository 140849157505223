import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { BlouseContext } from "../../../../../context/blouseContext";

const HighneckDisplay=({data,icon,name})=>{
    const {selectedHighneckItem,updateHighNeckItem,updateDefaultFrontNecks
    ,updateFrontNeckModels}=useContext(BlouseContext);

    const getHighNecks=(jsonData)=>{
        updateHighNeckItem(jsonData);
        updateFrontNeckModels(null);
        updateDefaultFrontNecks(null)

    }

    return(
        <>
            <Button className={selectedHighneckItem && selectedHighneckItem.name === name ?
             "items_display btn-danger":"items_display"}
               onClick={()=>getHighNecks(data)}>
                {/* <img src={icon} alt={name} className="icon_image"/> */}
                {name}
            </Button>
        </>
    )
}
export default HighneckDisplay