import { Button } from "react-bootstrap";
import React, { useContext } from "react";
import { BlouseContext } from "../../../../../context/blouseContext";
import { useTranslation } from 'react-i18next';


const DFrontDisplay=({name,icon,data})=>{
    const {updateDefaultFrontNecks,selectedDefaultFrontneckItem,
        updateFrontNeckModels}=useContext(BlouseContext);
        const { t} = useTranslation();
        const FrontDefaultNeckData = t('main.front_neck_data', { returnObjects: true });
    
    const getDFrontNecks=(jsondata)=>{
        updateDefaultFrontNecks(jsondata);
        updateFrontNeckModels(null)

    }

    return(
        <>
            <Button variant="white border" className={selectedDefaultFrontneckItem && selectedDefaultFrontneckItem.name === name ? 
            "items_display btn-danger":"items_display"}
           onClick={()=>getDFrontNecks(data,name)}
            >
                <img src={icon} alt={name} className="icon_image"/>
                {FrontDefaultNeckData[name] ? FrontDefaultNeckData[name] :name}
               
            </Button>
        </>
    )
}
export default DFrontDisplay