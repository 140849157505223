import React, { useContext, useState } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { KidsContext } from "../../../context/kidsContext";
import { Button, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { LoginContext } from "../../../context/logincontext";
import urls from "../../../api.json";


const KidsCustomizationComponent=()=>{
    const {kidsCustomizeData,getKidsCustomizationData,kidsfrontnecksData,
        kidsdefaultbacknecksData,
        selectedDefaultfrontNeckItem,selectedDefaultBackNeckItem}=useContext(KidsContext)
    const [localCustomizeData,setlocalCustomizeData]=useState(kidsCustomizeData.customizations_form);
    const [loading, setLoading] = useState(false);
    const [tempFrontNeck,setTempFrontNeck]=useState();
    const [tempBackNeck,setTempBackNeck]=useState();
    const { t } = useTranslation();
    const {authTokens}=useContext(LoginContext);
  const accessToken = authTokens?.access;
    const customize_data = t('main.page_4_buttons', { returnObjects: true });
    const onlyButtons_data = t('main.all_buttons', { returnObjects: true });
    const customization_data = t('main.kids_customization', { returnObjects: true });
    const customization_Check_data = t('main.blouse_customize_check', { returnObjects: true });
    const handleWithOutNeck=(value)=>{
        if(value.target.name === "front_neck"){
     if(value.target.checked){
        if(selectedDefaultfrontNeckItem){
          setTempFrontNeck(selectedDefaultfrontNeckItem.id)
        }
        
         kidsfrontnecksData.map((each)=>{
             if(each.name === "Without Neck"){
                kidsCustomizeData.measurement.default_front_neck_id = each.id
             }
         })

     }else{
        kidsCustomizeData.measurement.default_front_neck_id = tempFrontNeck
     }
 }else{
     if(value.target.checked){
         setTempBackNeck(selectedDefaultBackNeckItem.id)
         kidsdefaultbacknecksData.map((each)=>{
             if(each.name === "Without Neck"){
                kidsCustomizeData.measurement.default_back_neck_id = each.id
             }
         })
     }else{
        kidsCustomizeData.measurement.default_back_neck_id = tempBackNeck
     }
 }
}

 
    const getUpdate=(event)=>{
        event.preventDefault();
        setLoading(true)
        const customizations_form ={}
        for (let element = 0; element < event.target.length; element++) {
            customizations_form[event.target[element].name]=parseFloat(event.target[element].value)
        }
        const payload = {
            "measurement":kidsCustomizeData.measurement,
            "customizations_form":customizations_form
        }
        axios.post(urls.urls.BASE_URL+urls.urls.kids_pattern,payload,{
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then(res=>{
                setlocalCustomizeData(res.data.customizations_form);
                setLoading(false);
                getKidsCustomizationData(res.data);
                for (let element = 0; element < event.target.length; element++) {
                    event.target[element].value=res.data.customizations_form[event.target[element].name]
                }
            })
            .catch(err=>{
                console.log("update Pattern error ",err);
            })
    }

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setlocalCustomizeData({
            ...localCustomizeData,
            [name]: value
        });
    }

    return(
        <>
         <Row>
            <Col > 
                <Button variant="outline-light" size="sm"
                >Customization Details</Button>
            </Col>
        </Row>
        <Row className="bg-white rounded mt-1 pt-1" style={{ height: "79.5vh" }}>
            <div>
                <form className="" onSubmit={getUpdate}>
                    <div className="items_main_cus row">
                        {Object.keys(localCustomizeData)
                            .filter(key => !(key === 'size' || key === 'Neck_Seam' ||  key === 'shoulder_shape' 
                            || key === 'backdrop_type' || key === '' || key === 'bottom_curve' || key === 'side_zip'  ||
                            key === 'Shoulder_Finish' || key === 'Front_Cup_Size')) // Filter out keys
                            .map((key, index) => (
                                <Col lg={6} key={index} className="mb-1 mt-1">
                                    <FloatingLabel controlId={`floatingInputGrid-${key}`} label={key}>
                                        <Form.Control type="number"
                                        value={localCustomizeData[key]} 
                                        name={key} 
                                        onChange={handleSelectChange}
                                        disabled={key === 'Full_Shoulder' || 
                                            key === 'Arm_Hole_Round'} />
                                    </FloatingLabel>
                                </Col>
                            ))}
                            
                                
                            
                    </div>
                    <Row className="mt-2">    
                        <Col>
                        {loading ? (
                            <Button variant="outline-success" size="sm" disabled>
                                <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                Loading...
                            </Button>):( 
                            <Button size="sm" variant="outline-success" type="submit">Update</Button>)
                            }
                        </Col>
                    </Row>
                </form>
            </div>
           
        </Row>
        </>
    )
}
export default KidsCustomizationComponent