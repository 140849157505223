import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { BlouseContext } from "../../../../context/blouseContext";
import { useTranslation } from 'react-i18next';

const SleeveDisplay=({name,icon,data,children})=>{
    const {updateSleeveItems,selectedSleeveItem}=useContext(BlouseContext);
    const { t } = useTranslation();
    const SleeveDataFilter = t('main.sleeve_data', { returnObjects: true });

    const getSleeveItem=(jsonData)=>{
        updateSleeveItems(jsonData)
    }

    return(
        <Button variant="bg-light border" thick id="sleeve_item"
        className={selectedSleeveItem && 
            selectedSleeveItem.name === name ?"items_display btn-danger" :"items_display"}
        onClick={()=>getSleeveItem(data,name)}>
            <img src={icon} alt={name} className="icon_image"/>
            {SleeveDataFilter[name] ? SleeveDataFilter[name] :name}
            {children}
        </Button>
    )
}
export default SleeveDisplay