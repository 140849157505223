import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";
import axios from "axios";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import KidsFrockSizes from "./kidsFrocks.json"
import { useTranslation } from 'react-i18next';
import { KidsContext } from "../../../context/kidsContext";
import { CustomerContext } from "../../../context/customerContext";
import { Button, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { LoginContext } from "../../../context/logincontext";
import urls from "../../../api.json";
import KidsPantmeasurment from "./PantMeasurment";
import KidsLahangameasurment from "./LehangaMeasurment";
import KidsBlousemeasurment from "./blousemeasurment";


const KidsMeasurmentComponent=()=>{
  const {selectdKidsItem,getKidsCustomizationData,
    selectdKidsBackModelItem,selectdKidsFrontModelItem,
    selectdKidsDBackItem,selectdKidsDFrontItem,
    selectdKidsSleeveItem,
    getPatternPage}=useContext(KidsContext);
  const [loading, setLoading] = useState(false);
  const {customerMeasurment,orderNewId,orderOldId,
    setCustomerMeasurment}=useContext(CustomerContext);
    const {authTokens}=useContext(LoginContext);
    const accessToken = authTokens?.access;
  const [localMeasurmentData,setLocalMeasurmentData]=useState({});
  const [updateId,setUpdateId]=useState("");
  const [bottomLength,setBottomLength]=useState("");
  const { t } = useTranslation();
    const measurement_data = t('main.page_3_buttons', { returnObjects: true });
    const onlyButtons_data = t('main.all_buttons', { returnObjects: true });

  const [selectedSizeData, setSelectedSizeData] = useState(null);

    const handleSizeChange = (event) => {
        const newSize = parseInt(event.target.value);
        const foundSize = KidsFrockSizes.find((sizeObj) => sizeObj.size === newSize);
        if (foundSize) {
          setSelectedSizeData(foundSize.data);
        } else {
          setSelectedSizeData(null);
        }
      };

  const onChangeBottomlength=()=>{
    const length=parseFloat($("input[name='length']").val());
    const total_length=parseFloat($("input[name='total_length']").val());

    setBottomLength(total_length-length)
  }


  const updateCustomerData=()=>{
    const updateCustomerData={
        "update_type": "measurment",
        "order_id":updateId,
        "measurment":{
        "kids":[
            {
            "kids_id": selectdKidsItem.name,
            "sleeve_id": selectdKidsSleeveItem?.name,
            "dfront_neck_id": selectdKidsDFrontItem?.name,
            "dback_neck_id": selectdKidsDBackItem?.name,
            "frontmodel_id": selectdKidsFrontModelItem?.name,
            "backmodel_id": selectdKidsBackModelItem?.name,
            "measurment":{
              "length":parseFloat($("input[name='length']").val()),
              "total_length":parseFloat($("input[name='total_length']").val()),
              "CenterChest": parseFloat($("input[name='CenterChest']").val()),
              "ShoulderFinishing": parseFloat($("input[name='ShoulderFinishing']").val()),
              "SleeveLenght": parseFloat($("input[name='SleeveLenght']").val()),
              "SleeveRound": parseFloat($("input[name='SleeveRound']").val()),
              "MiddleHandRound": 0,
              "FrontNeckHeight": parseFloat($("input[name='FrontNeckHeight']").val()),
              "BackNeckHeight": parseFloat($("input[name='BackNeckHeight']").val()),
              "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
              "FrontDartPoint": 0,
              "WaistHeight":0,
              "hip_round":0,
              "slit_open":0,
              "Full_Shoulder": parseFloat($("input[name='Full_Shoulder']").val()),
              "Arm_Hole_Round": parseFloat($("input[name='Arm_Hole_Round']").val()),      
                }
            }
            ]
        }
    }
    axios.put(urls.urls.BASE_URL+urls.urls.order_update,updateCustomerData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
      )
      .then(res=>{
          console.log(res.data)
      })
      .catch(err=>{
        console.log("update customer Data",err)
      })
}
  
  const getPattern=(e)=>{
    setLoading(true);
    e.preventDefault();

   
    const measurmentData={
    "measurement":{
        "kids_id": selectdKidsItem ? selectdKidsItem.id : 0,
        "sleeve_id": selectdKidsSleeveItem ? selectdKidsSleeveItem.id : 0,
        "patche_id":  0,
        "collar_id":  0 ,
        "backdrop_id": 0,
        "frontneck_id": selectdKidsFrontModelItem ? selectdKidsFrontModelItem.id : 0,
        "backneck_id": selectdKidsBackModelItem ? selectdKidsBackModelItem.id : 0,
        "default_front_neck_id":  selectdKidsDFrontItem ? selectdKidsDFrontItem.id : 0,
        "default_back_neck_id":selectdKidsDBackItem ? selectdKidsDBackItem.id : 0,
        "high_neck_id":0, 
        "length":parseFloat($("input[name='length']").val()),
        "total_length":parseFloat($("input[name='total_length']").val()),
        "CenterChest": parseFloat($("input[name='CenterChest']").val()),
        "ShoulderFinishing": parseFloat($("input[name='ShoulderFinishing']").val()),
        "SleeveLenght": parseFloat($("input[name='SleeveLenght']").val()),
        "SleeveRound": parseFloat($("input[name='SleeveRound']").val()),
        "MiddleHandRound": 0,
        "FrontNeckHeight": parseFloat($("input[name='FrontNeckHeight']").val()),
        "BackNeckHeight": parseFloat($("input[name='BackNeckHeight']").val()),
        "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
        "FrontDartPoint": 0,
        "WaistHeight":0,
        "hip_round":0,
        "slit_open":0,
        "Full_Shoulder": parseFloat($("input[name='Full_Shoulder']").val()),
        "Arm_Hole_Round": parseFloat($("input[name='Arm_Hole_Round']").val()),

        }
    }
      axios.post(urls.urls.BASE_URL+urls.urls.kids_pattern,measurmentData,{
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(res=>{
        setLoading(false);
        getKidsCustomizationData(res.data);
        getPatternPage()
        updateCustomerData()
      
      })
      .catch(err=>{
          console.log("measurments error ",err);
      })

  }


  const getSave=()=>{
    const MeasurmentInputData={
      "length":parseFloat($("input[name='length']").val()),
      "total_length":parseFloat($("input[name='total_length']").val()),
      "CenterChest": parseFloat($("input[name='CenterChest']").val()),
      "ShoulderFinishing": parseFloat($("input[name='ShoulderFinishing']").val()),
      "SleeveLenght": parseFloat($("input[name='SleeveLenght']").val()),
      "SleeveRound": parseFloat($("input[name='SleeveRound']").val()),
      "MiddleHandRound": parseFloat($("input[name='MiddleHandRound']").val()),
      "FrontNeckHeight": parseFloat($("input[name='FrontNeckHeight']").val()),
      "BackNeckHeight": parseFloat($("input[name='BackNeckHeight']").val()),
      "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
      "FrontDartPoint": parseFloat($("input[name='FrontDartPoint']").val()),
      "WaistHeight":parseFloat($("input[name='WaistHeight']").val()),
      "hip_round":parseFloat($("input[name='hip_round']").val()),
      "slit_open":parseFloat($("input[name='slit_open']").val()),
      "Full_Shoulder": parseFloat($("input[name='Full_Shoulder']").val()),
      "Arm_Hole_Round": parseFloat($("input[name='Arm_Hole_Round']").val()),
     }
    localStorage.setItem("KidsMeasurmentData",JSON.stringify(MeasurmentInputData));
   
    toast.success('Data Added Successfully!', {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });

  }

  const resetInputs = () => {
    const inputs = [
      "length",
      "CenterChest",
      "total_length",
      "ShoulderFinishing",
      "SleeveLenght",
      "SleeveRound",
      "MiddleHandRound",
      "FrontNeckHeight",
      "BackNeckHeight",
      "WaistLoose",
      "FrontDartPoint",
      "sleeve_patti_height",
      "bottom_length",
      "Full_Shoulder",
      "Arm_Hole_Round",
    ];

    inputs.forEach((input) => {
      $(`input[name='${input}']`).val(""); // Reset input values to empty
    });
  };

  const setTrialData = () => {
    const trialData = {
      length: 10,
      total_length: 28,
      CenterChest:24,
      ShoulderFinishing:2,
      SleeveLenght:6,
      SleeveRound:8,
      MiddleHandRound:9,
      FrontNeckHeight:3.5,
      BackNeckHeight:3.5,
      WaistLoose:22,
      FrontDartPoint:4.5,
      bottom_length:18,
      Full_Shoulder:10,
      Arm_Hole_Round:11
    };

    Object.entries(trialData).forEach(([key, value]) => {
      $(`input[name='${key}']`).val(value); // Set input values to trial data
    });
  };
  

  useEffect(()=>{

    if (orderOldId !== null) {
      setUpdateId(orderOldId);
    } else if (orderNewId && orderNewId.order_id !== null) {
      setUpdateId(orderNewId.order_id);
    } else {
      setUpdateId(null);
    }

    if (selectedSizeData) {
      Object.entries(selectedSizeData).forEach(([key, value]) => {
        $(`input[name='${key}']`).val(value); // Set input values to trial data
      });
    }

    if(customerMeasurment){
      setCustomerMeasurment(customerMeasurment);
    }
    else{
      setCustomerMeasurment(null);
    }

    const mesurData=JSON.parse(localStorage.getItem("KidsMeasurmentData"));
    if(mesurData !== null){
      setLocalMeasurmentData(mesurData);
    }
  },[selectedSizeData])

  console.log("Check",updateId)
 

    return(
       <>
        <Row>
            <Col > 
                <Button variant="outline-light" size="sm"
                >{measurement_data[1]}</Button>
            </Col>
        </Row>
        <Row className="bg-white rounded mt-1" style={{height:"79.5vh"}}>
          <div>
          {selectdKidsItem && selectdKidsItem.name.includes("Pant") ?
            (
                <KidsPantmeasurment/>
            )
             :
             selectdKidsItem && selectdKidsItem.name.includes("Umbrella") ? (
                <KidsLahangameasurment /> 
            ):

            selectdKidsItem && selectdKidsItem.name.includes("Blouse") ? (
              <KidsBlousemeasurment /> 
          ):( 
          <form  onSubmit={getPattern}>
              <Row className="items_main_mea">
                  <Col lg={6} className="mb-1 mt-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[2]}>
                      <Form.Control type="float" name="length" placeholder="name@example.com" 
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[18]}>
                          <Form.Control type="float" name="total_length" placeholder="name@example.com"
                            />
                          </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[4]}>
                      <Form.Control type="float" name="CenterChest" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[5]}>
                          <Form.Control type="float" name="ShoulderFinishing" placeholder="name@example.com" 
                           />
                          </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[6]}>
                      <Form.Control type="float" name="SleeveLenght" placeholder="name@example.com" 
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[7]}>
                          <Form.Control type="float" name="SleeveRound" placeholder="name@example.com"
                           />
                          </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[9]}>
                      <Form.Control type="float" name="FrontNeckHeight" placeholder="name@example.com" 
                       />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[10]}>
                          <Form.Control type="float" name="BackNeckHeight" placeholder="name@example.com" 
                          />
                          </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[11]}>
                      <Form.Control type="float" name="WaistLoose" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[23]}>
                      <Form.Control type="float" name="bottom_length" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  
                  <Col lg={6} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[13]}>
                      <Form.Control type="float" name="Full_Shoulder" placeholder="name@example.com"
                        />
                      </FloatingLabel>
                  </Col>
                  
                  <Col lg={6} className="mb-1">
                          <FloatingLabel controlId="floatingInputGrid" label={measurement_data[14]}>
                          <Form.Control type="float" name="Arm_Hole_Round" placeholder="name@example.com" 
                           />
                          </FloatingLabel>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      {loading ? (
                      <Button variant="outline-success" size="sm" disabled>
                          <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          />
                          Loading...
                      </Button>):( 
                      <Button size="sm" variant="outline-success" type="submit"> {onlyButtons_data[4]}</Button>)
                      }
                      <Button size="sm" variant="outline-dark mx-1"
                      type="button" onClick={()=>resetInputs()}> {onlyButtons_data[5]}</Button> 
                      <Button size="sm" variant="outline-info mx-1"
                      type="button" onClick={()=>setTrialData()}> {onlyButtons_data[6]}</Button>
                      <Button size="sm" variant="outline-info mx-2" disabled>Select Size</Button>
                  </Col>
                  
              </Row>
          </form>
        )}
          </div>
         </Row>
       </>
    
    )
}
export default KidsMeasurmentComponent