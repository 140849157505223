import React, { useContext, useState } from "react";
import { Nav, Tab } from 'react-bootstrap';
import { Button, Col, Row,Spinner  } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import { ChudidharContext } from "../../../context/chudidharContext";
import { LoginContext } from "../../../context/logincontext";
import urls from "../../../api.json";

const ChudiCustomization=()=>{
    const {chudiCustomizeData,getChudiCustomizationData,selectdChudiDFrontItem,
        selectdChudiDBackItem,chudiDefaultBackData,chudiDefaultFrontData
    }=useContext(ChudidharContext)
    const [localCustomizeData,setlocalCustomizeData]=useState(chudiCustomizeData.customizations_form);
    const [loading, setLoading] = useState(false);
    const {authTokens}=useContext(LoginContext);
  const accessToken = authTokens?.access;
    const [tempFrontNeck,setTempFrontNeck]=useState();
    const [tempBackNeck,setTempBackNeck]=useState();
    
    const handleWithOutNeck=(value)=>{
        if(value.target.name === "front_neck"){
            if(value.target.checked){
                setTempFrontNeck(selectdChudiDFrontItem.id)
                chudiDefaultFrontData.map((each)=>{
                    if(each.name === "Without Neck"){
                        chudiCustomizeData.measurement.default_front_neck_id = each.id
                    }
                })

            }else{
                chudiCustomizeData.measurement.default_front_neck_id = tempFrontNeck
            }
            }else{
                if(value.target.checked){
                    setTempBackNeck(selectdChudiDBackItem.id)
                    chudiDefaultBackData.map((each)=>{
                        if(each.name === "Without Neck"){
                            chudiCustomizeData.measurement.default_back_neck_id = each.id
                        }
                    })
            }else{
                chudiCustomizeData.measurement.default_back_neck_id = tempBackNeck
            }
      }}

const handleWithOutNeckOther=(value)=>{
    if(value.target.name === "front_neck"){
        if(value.target.checked){
            chudiCustomizeData.measurement.default_front_neck_id = 1
        }else{
            chudiCustomizeData.measurement.default_front_neck_id = 0
        }}
        else{
        if(value.target.checked){
            chudiCustomizeData.measurement.default_back_neck_id = 1
        }else{
            chudiCustomizeData.measurement.default_back_neck_id = 0
        }
        }
    }

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        let updatedData = { ...localCustomizeData };
    
        if (name === 'backdrop_type') {
            updatedData[name] = value;
        } else {
            updatedData[name] = parseFloat(value); // Ensure to parse the value to a float if needed
        }
        setlocalCustomizeData(updatedData);
    };

    const handleSelectBottomChange = (e) => {
        const { name, value } = e.target;
        let updatedData = { ...localCustomizeData };
        if (name === 'bottom_curve') {
            updatedData[name] = value;
        } else {
            updatedData[name] = parseFloat(value); 
        }
        setlocalCustomizeData(updatedData);
    };

    const getUpdate=(event)=>{
        event.preventDefault();
        setLoading(true)
        const customizations_form ={}
        for (let element = 0; element < event.target.length; element++) {
            const { name, value } = event.target[element];
            
            // Check if the value is a numeric string
            const parsedValue = !isNaN(value) ? Number(value) : value;
            customizations_form[name] = parsedValue;
        }
        const payload = {
            "measurement":chudiCustomizeData.measurement,
            "customizations_form":customizations_form
        }
        axios.post(urls.urls.BASE_URL+urls.urls.chudi_pattern,payload,{
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then(res=>{
                setlocalCustomizeData(res.data.customizations_form);
                setLoading(false);
                getChudiCustomizationData(res.data);
                for (let element = 0; element < event.target.length; element++) {
                    event.target[element].value=res.data.customizations_form[event.target[element].name]
                }
            })
            .catch(err=>{
                console.log("update Pattern error ",err);
                setLoading(false);
            })
           
    }

    

    return(
        <>
        <Row>
            <Col > 
                <Button variant="outline-light" size="sm"
                >Customization Details</Button>
            </Col>
        </Row>
        <Row className="bg-white rounded mt-1 pt-1" style={{ height: "79.5vh" }}>
            <div>
                <form className="" onSubmit={getUpdate}>
                    <div className="items_main_cus row">
                        {Object.keys(localCustomizeData)
                            .filter(key => !(key === 'size' || key === 'Neck_Seam' ||  key === 'shoulder_shape' 
                            || key === 'backdrop_type' || key === '' || key === 'bottom_curve' || key === 'side_zip'  ||
                            key === 'Shoulder_Finish' || key === 'Front_Cup_Size')) // Filter out keys
                            .map((key, index) => (
                                <Col lg={6} key={index} className="mb-1 mt-1">
                                    <FloatingLabel controlId={`floatingInputGrid-${key}`} label={key}>
                                        <Form.Control type="number"
                                        value={localCustomizeData[key]} 
                                        name={key} 
                                        onChange={handleSelectChange}
                                        disabled={key === 'Full_Shoulder' || 
                                            key === 'Arm_Hole_Round'} />
                                    </FloatingLabel>
                                </Col>
                            ))}
                            
                                {Object.keys(localCustomizeData)
                                .filter(key => key === "backdrop_type")
                                .map((key, index) => (
                                    <Col>
                                        <Form.Select aria-label="Default select example"
                                        name={key}
                                        value={localCustomizeData[key] || ''}
                                        onChange={handleSelectChange}>
                                            <option value="Button">Button</option>
                                            <option value="Hook">Hook</option>
                                        </Form.Select>
                                    </Col>
                                ))}
                                {Object.keys(localCustomizeData)
                                .filter(key => key === "bottom_curve")
                                .map((key, index) => (
                                    <Col>
                                        <Form.Select aria-label="Default select example"
                                        name={key}
                                        value={localCustomizeData[key] || ''}
                                        onChange={handleSelectBottomChange}>
                                            <option value="line">line</option>
                                            <option value="curve">curve</option>
                                        </Form.Select>
                                    </Col>
                                ))}
                                
                            
                    </div>
                    <Row className="mt-2">    
                        <Col>
                        {loading ? (
                            <Button variant="outline-success" size="sm" disabled>
                                <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                Loading...
                            </Button>):( 
                            <Button size="sm" variant="outline-success" type="submit">Update</Button>)
                            }
                        </Col>
                    </Row>
                </form>
            </div>
            <Row className="mt-1">
                {selectdChudiDFrontItem ? (
                    <Col>
                        <input
                            className="form-check-input mx-2"
                            type="checkbox"
                            name="front_neck"
                            onChange={handleWithOutNeck}
                            id="inlineCheckbox1"
                        />
                        <label className="form-check-label"
                            htmlFor="inlineCheckbox1">Front Without Neck</label>
                    </Col>) : (
                        <Col>
                            <input
                                className="form-check-input mx-2"
                                type="checkbox"
                                name="front_neck"
                                onChange={handleWithOutNeckOther}
                                id="inlineCheckbox1"
                            />
                            <label className="form-check-label"
                                htmlFor="inlineCheckbox1">Front Without Neck</label>
                        </Col>
                    )}

                {selectdChudiDBackItem ? (
                    <Col>
                        <input
                            className="form-check-input mx-2"
                            type="checkbox"
                            name="back_nack"
                            id="inlineCheckbox2"
                            onChange={handleWithOutNeck}
                        />
                        <label className="form-check-label"
                            htmlFor="inlineCheckbox2">Back Without Neck</label>
                    </Col>) :
                    (
                        <Col>
                            <input
                                className="form-check-input mx-2"
                                type="checkbox"
                                name="back_nack"
                                id="inlineCheckbox2"
                                onChange={handleWithOutNeckOther}
                            />
                            <label className="form-check-label"
                                htmlFor="inlineCheckbox2">Back Without Neck</label>
                        </Col>
                    )
                }
        </Row>
        </Row>
        
       
        </>
    )
}
export default ChudiCustomization