import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { CustomerContext } from "../../context/customerContext";
import axios from "axios";
import { LoginContext } from "../../context/logincontext";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import urls from "../../api.json";

const OldCustomer=()=>{
    const [selectedDate, setSelectedDate] = useState('');
    const {formData,setFormData,setOrderOldId,setOrderNewId,getID}=useContext(CustomerContext);
    const {authTokens}=useContext(LoginContext);
    const accessToken = authTokens?.access;
    

    const { t } = useTranslation();
    const customre_filter = t('main.page_2_buttons', { returnObjects: true });
    const onlyButtons_data = t('main.all_buttons', { returnObjects: true });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

      const handleFormSubmit = (e) => {
        e.preventDefault();
  
        const updateCustomerData={
          "update_type": "customer_details",
            "order_id": formData.order_id,
            "customer_details": {
              "order_id": formData.order_id,
              "email": "suresh@gmail.com",
              "customer_name": formData.customer_name,
              "phone_number": formData.phone_number,
              "address": formData.address,
              "order_date": formData.order_date,
              "exp_del_date": formData.exp_del_date
            }
        }
        axios.put(urls.urls.BASE_URL+urls.urls.order_update,updateCustomerData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }})
          .then(res=>{
            // setCurrentStep(3);
            toast.success('Your Data Updated Successfully!', {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          })
          .catch(err=>{
            console.log("customer Update API Error",err)
          })
  
      };

    const initialDate = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    useState(() => {
        setSelectedDate(initialDate);
    }, []);

    useEffect(() => {
      if(formData){
        setOrderOldId(formData && formData?.order_id);
        setOrderNewId(null);
      }
      else{
        getID();
      }
    }, [formData.order_id]);
    
    return(
        <>
        <Row>
            <Col > 
                <Button variant="outline-light" size="sm"
                >{customre_filter[0]}</Button>
            </Col>
        </Row>
        <Row className="bg-white rounded mt-1" style={{height:"79.5vh"}}>
            <div>
                <form  className="p-1" onSubmit={handleFormSubmit}>
                <FloatingLabel controlId="floatingPassword" label={customre_filter[3]} className="mb-2">
                    <Form.Control type="text" placeholder="Order ID" name="order_id"
                    value={formData.order_id} onChange={handleInputChange}
                     />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label={customre_filter[4]} className="mb-2">
                    <Form.Control type="text" placeholder="Customer Name"
                    value={formData.customer_name} onChange={handleInputChange}
                    name="customer_name" />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label={customre_filter[5]} className="mb-2">
                    <Form.Control type="text" placeholder="Phone Number" 
                    value={formData.phone_number} onChange={handleInputChange}
                    name="phone_number"/>
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label={customre_filter[6]} className="mb-2">
                    <Form.Control type="text" placeholder="Address"
                    value={formData.address} onChange={handleInputChange}
                    name="address" />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label={customre_filter[7]} className="mb-2">
                    <Form.Control type="date" placeholder="Password" value={selectedDate} 
                    onChange={handleInputChange}
                    name="order_date"/>
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label={customre_filter[8]} className="mb-2">
                    <Form.Control type="date" placeholder="Password"  onChange={handleInputChange}
                   name="exp_del_date" />
                </FloatingLabel>
                <Col className="text-center">
                  <Button variant="outline-info" type="submit" >{onlyButtons_data[3]}</Button>
                </Col>
                </form>
            </div>
        </Row>
        </>

    )
}
export default OldCustomer