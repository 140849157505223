import React, { useContext} from "react";
import { Button } from "react-bootstrap";
import { CropTopContext } from "../../../../context/croptopContext";




const CropTopDisplay=({data,name,icon})=>{
    const {selectedCropItem,updateCropItems}=useContext(CropTopContext)

    const getBlosueData=(jsonData)=>{
        updateCropItems(jsonData)
    }

    
    return(
        <>
           <Button variant="bg-light border"
           className={selectedCropItem && selectedCropItem.name === name ? 
            "items_display btn-danger":"items_display"}
               onClick={()=>getBlosueData(data)}>
                <img src={icon} alt={name} className="icon_image" />
                {name}
            </Button>
        </>
    )
}
export default CropTopDisplay