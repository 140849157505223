import React, { useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { BlouseContext } from "../../../../context/blouseContext";
import { CustomerContext } from "../../../../context/customerContext";
import { useTranslation } from 'react-i18next';



const BlouseDisplay=({data,name,icon})=>{
    const {updateBlouseItems,selectedBlouse,sleeveData,
        selectedHighneckItem,updateHighNeckItem,updateDefaultFrontNecks,updateFrontNeckModels,
        selectedDefaultFrontneckItem,defaultFrontData,frontModelData,
        updateDefaultBackNecks,updateBackNeckModels,defaultBackData,
        selectedDefaultBackneckItem,selectedCollarItem,updateCollarItem,
        collarData,updateBackDropItem,highNeckData,
        selectedSleeveItem,updateSleeveItems}=useContext(BlouseContext);
        const {setCustomerMeasurment,setFormData}=useContext(CustomerContext);
        const { t } = useTranslation();
        const BLouseDataFilter = t('main.blouse_data', { returnObjects: true });


    const getBlosueData=(jsonData,itemName)=>{
        updateBlouseItems(jsonData);
        updateCollarItem(null);
        updateBackDropItem(null);
        setCustomerMeasurment(null)
        setFormData(null)

        localStorage.removeItem('BlouseMeasurments');

        
        // Sleeve Data Update
        if(selectedSleeveItem === null){
            sleeveData.map((each)=>{
                if(each.name === "Basic Sleeve"){
                    updateSleeveItems(each)
                }
            })
        }

        // Update The Front Neck Shapes
        if(jsonData.front_neck_shapes){
            if(jsonData.name.includes("High") || jsonData.name.includes("Halter")){
                if(selectedHighneckItem === null){
                    highNeckData.map(each=>{
                        if(each.name === "Round Neck"){
                            updateHighNeckItem(each)
                            updateDefaultFrontNecks(null)
                            updateFrontNeckModels(null)
                        }
                    })
                }
            }else{
                if(selectedDefaultFrontneckItem === null){
                    defaultFrontData.map((each)=>{
                        if(each.name === "Round Neck"){
                            updateDefaultFrontNecks(each)
                            updateHighNeckItem(null)
                            updateFrontNeckModels(null)
                        }
                    })
                }
                else{
                    frontModelData.map((each)=>{
                        if(each.name === "Round Neck"){
                            updateFrontNeckModels(each);
                            updateDefaultFrontNecks(null)
                        }
                    })

                }

            }
        }
        else{
            updateHighNeckItem(null)
            updateDefaultFrontNecks(null);
            updateFrontNeckModels(null)
        }

         // Update The Back Neck Shapes
        if(jsonData.back_neck_shapes){
            if(selectedDefaultBackneckItem === null){
                defaultBackData.map((each)=>{
                    if(each.name === "Round Neck"){
                        updateDefaultBackNecks(each);
                        updateBackNeckModels(null);
                    }
                })
            }
            else{
                defaultBackData.map((each)=>{
                    if(each.name === "Round Neck"){
                        updateDefaultBackNecks(each);
                        // updatePatcheItem(null);
                        updateBackNeckModels(null);
                    }
                })
            }
            
        }
        else{
            updateBackNeckModels(null);
            updateDefaultBackNecks(null);
            // updatePatcheItem(null);
        }
    }

    useEffect(()=>{
        if (selectedBlouse && selectedBlouse.name.toLowerCase().includes("close")) {
           if (selectedCollarItem === null) {
             const defaultCloseNeckCollar = collarData.find((each) => each.name === "Close Neck");
             if (defaultCloseNeckCollar) {
                updateCollarItem(defaultCloseNeckCollar);
             }
           }
         }
         if (selectedBlouse && selectedBlouse.name.toLowerCase().includes("boat")) {
            if (selectedCollarItem === null) {
              const defaultCloseNeckCollar = collarData.find((each) => each.name === "Boat Neck");
              if (defaultCloseNeckCollar) {
                 updateCollarItem(defaultCloseNeckCollar);
              }
            }
          }
          
      },[selectedBlouse, selectedCollarItem, collarData, updateCollarItem])

    return(
        <>
           <Button variant="bg-light border" id="blouse_items"
           className={selectedBlouse && selectedBlouse.name === name ? 
            "items_display btn-danger":"items_display"}
               onClick={()=>getBlosueData(data,name)}>
                <img src={icon} alt={name} className="icon_image" />
                {BLouseDataFilter[name] ? BLouseDataFilter[name] :name}
            </Button>
        </>
    )
}
export default BlouseDisplay