import React, { useContext, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import $ from "jquery";
import "./measurment.css"
import axios from "axios";
import TrailSizes from "./BlouseTrail.json"
import { BlouseContext } from "../../../context/blouseContext";
import { CustomerContext } from "../../../context/customerContext";
import { LoginContext } from "../../../context/logincontext";
import { useTranslation } from 'react-i18next';
import urls from "../../../api.json";

const MeasurmentComponent=()=>{
    const {selectedBlouse,selectedSleeveItem,selectedDefaultFrontneckItem,selectedDefaultBackneckItem
    ,selectedFrontneckItem,selectedBackneckItem,selectedHighneckItem,selectedCollarItem,setCurrentStep,
    getCustomizationData,handleNextClick,
    selectedBackDropItem}=useContext(BlouseContext);
    const {authTokens}=useContext(LoginContext);
    const accessToken = authTokens?.access;
    const {customerMeasurment,orderNewId,orderOldId}=useContext(CustomerContext);
  const { t } = useTranslation();
  const measurement_data = t('main.page_3_buttons', { returnObjects: true });
  const onlyButtons_data = t('main.all_buttons', { returnObjects: true });
   const [loading,setLoading]=useState(false);
   const [updateId,setUpdateId]=useState("");
   const [localMeasurmentData,setLocalMeasurmentData]=useState({});
   const [editedValues, setEditedValues] = useState({
    length: "",
    UperChest: "",
    CenterChest: "",
    ShoulderFinishing: "",
    SleeveLenght: "",
    SleeveRound: "",
    MiddleHandRound: "",
    FrontNeckHeight: "",
    BackNeckHeight: "",
    WaistLoose: "",
    FrontDartPoint: "",
    Full_Shoulder: "",
    Arm_Hole_Round: ""
});

  

   const updateCustomerData=()=>{
        const updateCustomerData={
            "update_type": "measurment",
            "order_id":updateId,
            "measurment":{
            "blouse":[
                {
                "blouse_id": selectedBlouse.name,
                "sleeve_id": selectedSleeveItem?.name,
                "dfront_neck_id": selectedDefaultFrontneckItem?.name,
                "dback_neck_id": selectedDefaultBackneckItem?.name,
                "collar_id": selectedCollarItem?.name,
                "frontmodel_id": selectedFrontneckItem?.name,
                "backmodel_id": selectedBackneckItem?.name,
                "highneck_id": selectedHighneckItem?.name,
                "backdrop_id": selectedBackDropItem?.name,
                "measurment":{
                    "length":parseFloat($("input[name='length']").val()),
                    "UperChest":parseFloat($("input[name='UperChest']").val()),
                    "CenterChest": parseFloat($("input[name='CenterChest']").val()),
                    "ShoulderFinishing": parseFloat($("input[name='ShoulderFinishing']").val()),
                    "SleeveLenght": parseFloat($("input[name='SleeveLenght']").val()),
                    "SleeveRound": parseFloat($("input[name='SleeveRound']").val()),
                    "MiddleHandRound": parseFloat($("input[name='MiddleHandRound']").val()),
                    "FrontNeckHeight": parseFloat($("input[name='FrontNeckHeight']").val()),
                    "BackNeckHeight": parseFloat($("input[name='BackNeckHeight']").val()),
                    "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
                    "FrontDartPoint": parseFloat($("input[name='FrontDartPoint']").val()),
                    "sleeve_patti_height":selectedSleeveItem && selectedSleeveItem.name === "Puff Sleeve" ? parseFloat($("input[name='sleeve_patti_height']").val()) : 0,
                    "Full_Shoulder": parseFloat($("input[name='Full_Shoulder']").val()),
                    "Arm_Hole_Round": parseFloat($("input[name='Arm_Hole_Round']").val()),
                    }
                }
                ]
            }
        }
        axios.put(urls.urls.BASE_URL+urls.urls.order_update,updateCustomerData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
          )
          .then(res=>{
              console.log(res.data)
          })
          .catch(err=>{
            console.log("update customer Data",err)
          })
   }
   


   const getPattern=(e)=>{
       setLoading(true);
      e.preventDefault();

      const payload={
        "measurement":{
            "blouse_id": selectedBlouse ? selectedBlouse.id : 0,
            "sleeve_id": selectedSleeveItem ? selectedSleeveItem.id : 0,
            "patche_id": 0,
            "collar_id": selectedCollarItem ? selectedCollarItem.id : 0,
            "backdrop_id": selectedBackDropItem ? selectedBackDropItem.id:0,
            "frontneck_id": selectedFrontneckItem ? selectedFrontneckItem.id : 0,
            "backneck_id": selectedBackneckItem ? selectedBackneckItem.id : 0,
            "default_front_neck_id": selectedDefaultFrontneckItem ? selectedDefaultFrontneckItem.id : 0,
            "default_back_neck_id":selectedDefaultBackneckItem ?selectedDefaultBackneckItem.id : 0,
            "high_neck_id":selectedHighneckItem ? selectedHighneckItem.id : 0, 
            "length":parseFloat($("input[name='length']").val()),
            "UperChest":parseFloat($("input[name='UperChest']").val()),
            "CenterChest": parseFloat($("input[name='CenterChest']").val()),
            "ShoulderFinishing": parseFloat($("input[name='ShoulderFinishing']").val()),
            "SleeveLenght": parseFloat($("input[name='SleeveLenght']").val()),
            "SleeveRound": parseFloat($("input[name='SleeveRound']").val()),
            "MiddleHandRound": parseFloat($("input[name='MiddleHandRound']").val()),
            "FrontNeckHeight": parseFloat($("input[name='FrontNeckHeight']").val()),
            "BackNeckHeight": parseFloat($("input[name='BackNeckHeight']").val()),
            "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
            "FrontDartPoint": parseFloat($("input[name='FrontDartPoint']").val()),
            "sleeve_patti_height":selectedSleeveItem && selectedSleeveItem.name === "Puff Sleeve" ? parseFloat($("input[name='sleeve_patti_height']").val()) : 0,
            "Full_Shoulder": parseFloat($("input[name='Full_Shoulder']").val()),
            "Arm_Hole_Round": parseFloat($("input[name='Arm_Hole_Round']").val()),
            }
        }

        const MeasurmentsData={
            "length":parseFloat($("input[name='length']").val()),
            "UperChest":parseFloat($("input[name='UperChest']").val()),
            "CenterChest": parseFloat($("input[name='CenterChest']").val()),
            "ShoulderFinishing": parseFloat($("input[name='ShoulderFinishing']").val()),
            "SleeveLenght": parseFloat($("input[name='SleeveLenght']").val()),
            "SleeveRound": parseFloat($("input[name='SleeveRound']").val()),
            "MiddleHandRound": parseFloat($("input[name='MiddleHandRound']").val()),
            "FrontNeckHeight": parseFloat($("input[name='FrontNeckHeight']").val()),
            "BackNeckHeight": parseFloat($("input[name='BackNeckHeight']").val()),
            "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
            "FrontDartPoint": parseFloat($("input[name='FrontDartPoint']").val()),
            "sleeve_patti_height":selectedSleeveItem && selectedSleeveItem.name === "Puff Sleeve" ? parseFloat($("input[name='sleeve_patti_height']").val()) : 0,
            "Full_Shoulder": parseFloat($("input[name='Full_Shoulder']").val()),
            "Arm_Hole_Round": parseFloat($("input[name='Arm_Hole_Round']").val()),
        }
        
        axios.post(urls.urls.BASE_URL+urls.urls.blouse_pattern,payload,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(res=>{
            getCustomizationData(res.data)
            setLoading(false);
            handleNextClick();
            updateCustomerData()
            localStorage.setItem("BlouseMeasurments",JSON.stringify(MeasurmentsData))
            // setCurrentStep(4);
        })
        .catch(err=>{
            console.log("BLouse API Error",err);
            setLoading(false);
        })


   }



   const resetInputs = () => {
    const inputs = [
      "length",
      "UperChest",
      "CenterChest",
      "ShoulderFinishing",
      "SleeveLenght",
      "SleeveRound",
      "MiddleHandRound",
      "FrontNeckHeight",
      "BackNeckHeight",
      "WaistLoose",
      "FrontDartPoint",
      "sleeve_patti_height",
      "Full_Shoulder",
      "Arm_Hole_Round",
    ];

    inputs.forEach((input) => {
      $(`input[name='${input}']`).val(""); // Reset input values to empty
    });
  };

   const setTrialData = () => {
    const trialData = {
      length: 14,
      UperChest: 36,
      CenterChest:36,
      ShoulderFinishing:2.25,
      SleeveLenght:6,
      SleeveRound:12,
      MiddleHandRound:13,
      FrontNeckHeight:6,
      BackNeckHeight:7,
      WaistLoose:30,
      FrontDartPoint:9.5,
      Full_Shoulder:15,
      Arm_Hole_Round:16,
    };

    Object.entries(trialData).forEach(([key, value]) => {
      $(`input[name='${key}']`).val(value); // Set input values to trial data
    });
  };

  useEffect(()=>{
    if (orderOldId !== null) {
        setUpdateId(orderOldId);
      } else if (orderNewId && orderNewId.order_id !== null) {
        setUpdateId(orderNewId.order_id);
      } else {
        setUpdateId(null);
      }
      if (customerMeasurment) {
        const inputsToUpdate = [
            "length", "UperChest", "CenterChest", "ShoulderFinishing", "SleeveLenght",
            "SleeveRound", "MiddleHandRound", "FrontNeckHeight", "BackNeckHeight", "WaistLoose",
            "FrontDartPoint", "Full_Shoulder", "Arm_Hole_Round"
        ];

        inputsToUpdate.forEach(inputName => {
            const inputValue = customerMeasurment[inputName] || ""; // Get value from customerMeasurment or set to empty string if undefined
            $(`input[name='${inputName}']`).val(inputValue);
        });
    }
    const mesurData=JSON.parse(localStorage.getItem("BlouseMeasurments"));
    if(mesurData !== null){
      setLocalMeasurmentData(mesurData);
    }

  },[customerMeasurment])
  

    return(
        <>
        <Row>
            <Col > 
                <Button variant="outline-light" size="sm"
                >{measurement_data[1]}</Button>
            </Col>
        </Row>
        <Row className="bg-white rounded mt-1" style={{height:"79.5vh"}}>
            <div>
            <form  onSubmit={getPattern}>
                <Row className="items_main_mea">
                    <Col lg={6} className="mb-1 mt-1">
                        <FloatingLabel controlId="floatingInputGrid" label={measurement_data[2]}>
                        <Form.Control type="float" name="length" placeholder="name@example.com" 
                          defaultValue={customerMeasurment && customerMeasurment.length ?
                            customerMeasurment.length : localMeasurmentData.length}/>
                        </FloatingLabel>
                    </Col>
                    <Col lg={6} className="mb-1 mt-1">
                        <FloatingLabel controlId="floatingInputGrid" label={measurement_data[3]}>
                        <Form.Control type="float" name="UperChest" placeholder="name@example.com"
                             defaultValue={customerMeasurment && customerMeasurment.UperChest ?
                              customerMeasurment.UperChest : localMeasurmentData.UperChest} />
                        </FloatingLabel>
                    </Col>
                    <Col lg={6} className="mb-1">
                            <FloatingLabel controlId="floatingInputGrid" label={measurement_data[4]}>
                            <Form.Control type="float" name="CenterChest" placeholder="name@example.com"
                              defaultValue={customerMeasurment && customerMeasurment.CenterChest ?
                                customerMeasurment.CenterChest : localMeasurmentData.CenterChest} />
                            </FloatingLabel>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <FloatingLabel controlId="floatingInputGrid" label={measurement_data[5]}>
                        <Form.Control type="float" name="ShoulderFinishing" placeholder="name@example.com"
                         defaultValue={customerMeasurment && customerMeasurment.ShoulderFinishing ?
                          customerMeasurment.ShoulderFinishing : localMeasurmentData.ShoulderFinishing} />
                        </FloatingLabel>
                    </Col>
                    <Col lg={6} className="mb-1">
                            <FloatingLabel controlId="floatingInputGrid" label={measurement_data[6]}>
                            <Form.Control type="float" name="SleeveLenght" placeholder="name@example.com" 
                            defaultValue={customerMeasurment && customerMeasurment.SleeveLenght ?
                              customerMeasurment.SleeveLenght : localMeasurmentData.SleeveLenght} />
                            </FloatingLabel>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <FloatingLabel controlId="floatingInputGrid" label={measurement_data[7]}>
                        <Form.Control type="float" name="SleeveRound" placeholder="name@example.com" 
                         defaultValue={customerMeasurment && customerMeasurment.SleeveRound ?
                          customerMeasurment.SleeveRound : localMeasurmentData.SleeveRound} />
                        </FloatingLabel>
                    </Col>
                    <Col lg={6} className="mb-1">
                            <FloatingLabel controlId="floatingInputGrid" label={measurement_data[8]}>
                            <Form.Control type="float" name="MiddleHandRound" placeholder="name@example.com"
                            defaultValue={customerMeasurment && customerMeasurment.MiddleHandRound ?
                              customerMeasurment.MiddleHandRound : localMeasurmentData.MiddleHandRound} />
                            </FloatingLabel>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <FloatingLabel controlId="floatingInputGrid" label={measurement_data[9]}>
                        <Form.Control type="float" name="FrontNeckHeight" placeholder="name@example.com" 
                         defaultValue={customerMeasurment && customerMeasurment.FrontNeckHeight ?
                          customerMeasurment.FrontNeckHeight : localMeasurmentData.FrontNeckHeight}/>
                        </FloatingLabel>
                    </Col>
                    <Col lg={6} className="mb-1">
                            <FloatingLabel controlId="floatingInputGrid" label={measurement_data[10]}>
                            <Form.Control type="float" name="BackNeckHeight" placeholder="name@example.com" 
                            defaultValue={customerMeasurment && customerMeasurment.BackNeckHeight ?
                              customerMeasurment.BackNeckHeight : localMeasurmentData.BackNeckHeight}/>
                            </FloatingLabel>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <FloatingLabel controlId="floatingInputGrid" label={measurement_data[11]}>
                        <Form.Control type="float" name="WaistLoose" placeholder="name@example.com"
                         defaultValue={customerMeasurment && customerMeasurment.WaistLoose ?
                          customerMeasurment.WaistLoose : localMeasurmentData.WaistLoose} />
                        </FloatingLabel>
                    </Col>
                    <Col lg={6} className="mb-1">
                            <FloatingLabel controlId="floatingInputGrid" label={measurement_data[12]}>
                            <Form.Control type="float" name="FrontDartPoint" placeholder="name@example.com"
                            defaultValue={customerMeasurment && customerMeasurment.FrontDartPoint ?
                              customerMeasurment.FrontDartPoint : localMeasurmentData.FrontDartPoint} />
                            </FloatingLabel>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <FloatingLabel controlId="floatingInputGrid" label={measurement_data[13]}>
                        <Form.Control type="float" name="Full_Shoulder" placeholder="name@example.com"
                         defaultValue={customerMeasurment && customerMeasurment.Full_Shoulder ?
                          customerMeasurment.Full_Shoulder : localMeasurmentData.Full_Shoulder} />
                        </FloatingLabel>
                    </Col>
                    <Col lg={6} className="mb-1">
                            <FloatingLabel controlId="floatingInputGrid" label={measurement_data[14]}>
                            <Form.Control type="float" name="Arm_Hole_Round" placeholder="name@example.com" 
                             defaultValue={customerMeasurment && customerMeasurment.Arm_Hole_Round ?
                              customerMeasurment.Arm_Hole_Round : localMeasurmentData.Arm_Hole_Round}/>
                            </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        {loading ? (
                        <Button variant="outline-success" size="sm" disabled>
                            <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />
                            Loading...
                        </Button>):( 
                        <Button size="sm" variant="outline-success" id="pattern_button"
                        type="submit">{onlyButtons_data[4]}</Button>)
                        }
                        <Button size="sm" variant="outline-dark mx-1"
                        type="button" onClick={()=>resetInputs()}>{onlyButtons_data[5]}</Button> 
                        <Button size="sm" variant="outline-info"
                        type="button" onClick={()=>setTrialData()} id="trails_button"
                        >{onlyButtons_data[6]}</Button>
                        {/* <Button size="sm" variant="outline-info mx-2" disabled>Select Size</Button> */}
                        {/* <Form.Select size="sm" style={{width:"150px"}}>
                          <option>Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                      </Form.Select> */}
                        
                    </Col>
                </Row>
            </form>
            </div>
        </Row>
        </>
    )
}
export default MeasurmentComponent