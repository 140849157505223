import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {Dropdown} from 'react-bootstrap';
import {BiLogOut} from "react-icons/bi"
import { PackageContext } from "../../context/packageContext";
import { LoginContext } from "../../context/logincontext";
import { CustomerContext } from "../../context/customerContext";
import { ChudidharContext } from "../../context/chudidharContext";
import { KidsContext } from "../../context/kidsContext";
import { useTranslation } from 'react-i18next';
import { GrLanguage } from "react-icons/gr";
import { BlouseContext } from "../../context/blouseContext";
import { SlLocationPin } from "react-icons/sl";
import { CropTopContext } from "../../context/croptopContext";


const locales={
    en:{title:'English'},
    tl:{title:'తెలుగు'},
    ka:{title:'ಕನ್ನಡ'},
}


const NavBarComponent=()=>{
    const {  i18n } = useTranslation();
    const {userData,getClearAll,setModelBill}=useContext(BlouseContext);
    const {getAllChudiClear}=useContext(ChudidharContext);
    const {handleKidsClear}=useContext(KidsContext)
    const {logoutUser}=useContext(LoginContext)
    const {selectedName}=useContext(PackageContext);
    const {setFormData,setOrderOldId,setShowOld,setOrderDataDetails}=useContext(CustomerContext);
    const {getAllCropClear}=useContext(CropTopContext);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const getLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
            },
            (error) => {
              setError(error.message);
            }
          );
        } else {
          setError('Geolocation is not supported by this browser.');
        }
      };
  
      getLocation();
    }, []);
  
  

    const handleLogout = () => {
        logoutUser();
    };

    const handleBill=()=>
    {
        setModelBill(false);
        setFormData(null)
    }

    const GetBill=()=>{
        setModelBill(true);
    }

    const getLanding = () => {
        getClearAll();
        setFormData(null);
        setShowOld(false);
        setOrderOldId(null);
        setOrderDataDetails(null);
        getAllChudiClear();
        handleKidsClear();
        getAllCropClear()
    };

    if (i18n.language === "en-US" || i18n.language === "en-GB") {
        i18n.changeLanguage('en');
    }
      
    return(
        <>
        <Container fluid className="text-white">
            <Row className="pt-1">
                <Col>
                  <Link to="/" onClick={()=>getLanding()}>
                  <img src="\media\logo\tt_logo-02.svg" alt="logo" style={{height:"85%"}}/>
                  </Link>
                </Col>
                <Col className="text-center">
                         <Link to="/"  onClick={()=>getLanding()}
                          style={{textDecoration:"none"}}>
                            <h5 className="text-white name_navbar_style">
                                {selectedName === "Blouse" && "Blouse"}
                                {selectedName === "Chudidhar" && "Chudidhar"}
                                {selectedName === "Kids" && "Kids"}
                                {selectedName === "CropTop" && "CropTop"}
                                </h5>
                        </Link>
                </Col>
                <Col className="text-end">
                    <Row>
                    <Col>
                        {/* <Button variant="danger" size="sm" onClick={startTour}>Start</Button> */}
                        </Col>
                        <Col>
                        <Dropdown size="sm">
                            <Dropdown.Toggle size="sm" className="select_style">
                            <GrLanguage className="mx-2 mb-1" />
                            {locales[i18n.language].title}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            {Object.keys(locales).map((locale) => (
                                <Dropdown.Item
                                className="text-dark"
                                onClick={() => i18n.changeLanguage(locale)}
                                >
                                {locales[locale].title}
                                </Dropdown.Item>
                            ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        </Col>
                        
                        <Col>
                        <Dropdown size="sm">
                            <Dropdown.Toggle size="sm" className="select_style">
                            <Image
                                src="\media\logout\logout.jfif"
                                alt="My Image"
                                className="logout_style mx-2"
                            />
                            {userData && userData[0].user_company_name}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            <Dropdown.Item
                                className="text-dark"
                            >
                               {error ? (
                                <p>Error: {error}</p>
                                ) : (
                                    <>
                                   <SlLocationPin  className="mx-2"/>  
                                    Latitude: {latitude}, 
                                    Longitude: {longitude}
                                    
                                    </>
                                )}
                            </Dropdown.Item>
                            <Dropdown.Item
                                title="Click To Logout"
                                className="text-danger"
                                onClick={handleLogout} 
                            >
                                <BiLogOut
                                className="mx-2"
                                onClick={() => logoutUser()}
                                />Logout
                            </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
     </>
    )
}
export default NavBarComponent;
