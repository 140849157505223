import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaPrint } from "react-icons/fa6";
import { ChudidharContext } from "../../../context/chudidharContext";



const ChudiPattern=()=>{
    const {chudiCustomizeData,selectdChudiItem,selectdChudiSleeveItem,selectdChudiDFrontItem,selectdChudiDBackItem,
        selectdChudiFrontModelItem,selectdChudiBackModelItem,selectdChudiHighNeckItem,
        selectdChudiBackDropItem,selectdChudiCollarItem
    }=useContext(ChudidharContext)
    
    const [dxfPath,setDxfPath]=useState(chudiCustomizeData.dxf_path);

  
    useEffect(()=>{
        setDxfPath(chudiCustomizeData.dxf_path);
    },[])

    const disableAllLinks = true; // Set this to true to disable all links
    const svgWithClass = chudiCustomizeData.url ? chudiCustomizeData.url.replace('<svg', '<svg class="kids_pattern_image"') : null;
    return(
        <>
            <Row className="bg-light rounded mt-1">
                <Col className="p-1" lg={12}>
                   <h6 >Pattern View / 
                    <span className="text-danger"> {selectdChudiItem && selectdChudiItem.name}</span>
                    <span className="text-info"> {selectdChudiSleeveItem && selectdChudiSleeveItem.name}</span>
                    <span className="text-danger"> {selectdChudiDFrontItem && selectdChudiDFrontItem.name}</span>
                    <span className="text-info"> {selectdChudiDBackItem && selectdChudiDBackItem.name}</span>
                    <span className="text-danger">{selectdChudiHighNeckItem && selectdChudiHighNeckItem.name}</span>
                    <span className="text-info"> {selectdChudiFrontModelItem && selectdChudiFrontModelItem.name}</span>
                    <span className="text-danger"> {selectdChudiBackModelItem && selectdChudiBackModelItem.name}</span>
                    <span className="text-info"> {selectdChudiCollarItem && selectdChudiCollarItem.name}</span>
                    <span className="text-info"> {selectdChudiBackDropItem && selectdChudiBackDropItem.name}</span>
                   </h6>
                </Col>
                <hr style={{margin:"0px",marginBottom:"0px",marginTop:"-5px"}}/>
                {/* <Col>
                    {dxfPath.map(each => {
                            const path = each.split("/");
                            const buttonName = path[path.length - 2];

                            // Check if the button name contains 'dxf_files'
                            if (buttonName.includes('dxf_files')) {
                            // Replace the button name containing 'dxf_files' with your desired name
                            return (
                                <a  href={disableAllLinks ? undefined : (process.env['REACT_APP_BASE_URL'] + each)}
                                className={`btn btn-outline-success btn-sm btn_style mx-1 ${disableAllLinks ? 'disabled' : ''}`}
                                onClick={disableAllLinks ? (e) => e.preventDefault() : null}
                                // onClick={getDownloadCount}
                                >
                                    <FaPrint className="mx-1"/>
                                Cut Pattern
                                </a>
                            );
                            }

                            // If the button name doesn't contain 'dxf_files', display the original name
                            return (
                            <a  href={disableAllLinks ? undefined : (process.env['REACT_APP_BASE_URL'] + each)}
                            className={`btn btn-outline-success btn-sm btn_style mx-1 ${disableAllLinks ? 'disabled' : ''}`}
                            onClick={disableAllLinks ? (e) => e.preventDefault() : null}>
                                <FaPrint className="mx-1"/>
                                {buttonName}
                            </a>
                            );
                        })
                        }
                </Col> */}
                <Row className="items_pattern">
                      <div dangerouslySetInnerHTML={{ __html:svgWithClass}} />
                  </Row>
                        
            </Row>
        </>
    )
}
export default ChudiPattern