import React, { useContext, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { BlouseContext } from "../../../context/blouseContext";
import CollarDisplay from "./collardisplay";
import { useTranslation } from 'react-i18next';

const CollarList=()=>{
    const {collarData,selectedBlouse}=useContext(BlouseContext);
    const [filterData,setFitlerData]=useState('');
    const [filterDataCollor,setFilterDataCollor]=useState([]);
    const { t } = useTranslation();

    const FilterData=filterDataCollor.filter(each=>(
        each.name.toLowerCase().includes(filterData.toLowerCase())
      ))

      useEffect(() => {
        if (selectedBlouse) {
          let filteredItems = [];
          if (selectedBlouse.name.toLowerCase().includes("close")) {
            filteredItems = collarData.filter((item) => item.name.toLowerCase().includes("close"));
          } else if (selectedBlouse.name.toLowerCase().includes("high")) {
            filteredItems = collarData.filter((item) => item.name.toLowerCase().includes("high"));
          }
          else if (selectedBlouse.name.toLowerCase().includes("boat")) {
            filteredItems = collarData.filter((item) => item.name.toLowerCase().includes("boat"));
          }
          setFilterDataCollor(filteredItems);
         
        }
      }, [selectedBlouse, collarData]);


    return(
        <>
        <div className="d-flex mt-2">
           <Col className="">
            <Form.Control type="text" 
            onChange={(e)=>setFitlerData(e.target.value)}
            placeholder="Search By Items..." />
            </Col>
            <Col className="mx-2 mt-2" lg={5} sm={5} md={5}>
            <p className="text-dark px-1">{t('main.search_total')} {FilterData.length}</p>
            </Col>
        </div>
        <div className="leftside_items_sleeve">
           {FilterData?.map(each=>(
                  <CollarDisplay key={each.id}
                  icon={each.icon}
                  name={each.name}
                  data={each}>
                  </CollarDisplay>
                 ))}
        </div>
        </>
    )
}
export default CollarList