import React, { useContext, useState } from "react";
import { Col, Form } from "react-bootstrap";
import SleeveDisplay from "./display";
import { KidsContext } from "../../../context/kidsContext";
import { useTranslation } from 'react-i18next';


const KidsSleeveList=()=>{
    const {kidsSleeveData}=useContext(KidsContext);
    const [filterSleeve,setFilterSleeve]=useState("");
    const { t } = useTranslation();

    const FilterData=kidsSleeveData.filter(each=>(
        each.name.toLowerCase().includes(filterSleeve.toLowerCase())
      ))


    return(
        <>
        <div className="d-flex mt-2">
           <Col className="">
            <Form.Control type="text" 
            onChange={(e)=>setFilterSleeve(e.target.value)}
            placeholder="Search By Items..." />
            </Col>
            <Col className="mx-2 mt-2" lg={5} sm={4} md={4}>
            <p className="text-dark">{t('main.search_total')} {FilterData.length}</p>
            </Col>
        </div>
        <div className="leftside_items_sleeve">
           {FilterData?.map(each=>(
                  <SleeveDisplay key={each.id}
                  icon={each.icon}
                  name={each.name}
                  data={each}>
                  </SleeveDisplay>
                 ))}
        </div>
        </>
    )
}
export default KidsSleeveList