import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, Stage,OrbitControls,SpotLight } from '@react-three/drei';
import './view3donly.css';

function Model(props) {
  const { scene } = useGLTF("/media/view3D/final/Western_31.glb");
  const model = useRef();
  
    useFrame(() => {
      if (model.current) {
        model.current.rotation.y += 0.015; // Adjust rotation speed as needed
      }
    });
  
    return <primitive object={scene} {...props} ref={model} />;

  
}

export default function Westren3D() {
  return (
    
      <div className="view-header" style={{marginTop:"30px"}}>
       <Canvas>
      <OrbitControls
       enablePan={true}
        enableZoom={true}
        enableRotate={false} 
        position={[4, 5, 0]}
        />
        {/* <SpotLight

          // color={"white"}
          angle={0.1}
          penumbra={1}
          position={[0, 5, 0]}
        /> */}
        {/* <spotLight  angle={0.1} penumbra={2}  /> */}

        <Stage>
          <Model scale={5.0} />
        </Stage>
      </Canvas>
      </div> 
   
  );
}

