import React, { useContext, useState } from "react";
import { Button, Col } from "react-bootstrap";
import DefaultFrontNecks from "./defaultFrontNeck";
import DefaultBackNecks from "./defaultBackNeck";
import FrontModelNecks from "./frontNeckModel";
import BackModelNecks from "./backNeckModel";
import { BlouseContext } from "../../../context/blouseContext";
import DefaultHighNecks from "./defaultHighNeck";
import { useTranslation } from 'react-i18next';


const NecksComponent=()=>{
    const {selectedBlouse}=useContext(BlouseContext);
    const [activeModel,setActiveModel]=useState("Front Necks");
    const { t } = useTranslation();
    const neck_filter__Data = t('main.necks_buttons', { returnObjects: true });

    const handleNecks=(value)=>{
        setActiveModel(value)
    }
    return(
        <>
        {selectedBlouse && selectedBlouse.front_neck_shapes && 
            (selectedBlouse.name.includes('High') || selectedBlouse.name.includes('Halter')) ? (
                <>
                  <DefaultHighNecks/>
                </>
            ):(
                <>
            <div className="mt-1">
                <Col className="text-center">
                <Button variant={activeModel === "Front Necks" ? "dark px-1":"outline-dark px-1"} size="sm"
                onClick={()=>handleNecks("Front Necks")}>{neck_filter__Data[0]}</Button>
                <Button variant={activeModel === "Back Necks" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                onClick={()=>handleNecks("Back Necks")}>{neck_filter__Data[1]}</Button>
                <Button variant={activeModel === "Front M Necks" ? "dark px-1":"outline-dark px-1"} size="sm"
                onClick={()=>handleNecks("Front M Necks")} title="Front Model Necks">{neck_filter__Data[2]}</Button>
                <Button variant={activeModel === "Back M Necks" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                onClick={()=>handleNecks("Back M Necks")} title="Back Model Necks">{neck_filter__Data[3]}</Button>
                </Col>
            </div>
            {activeModel === "Front Necks" && <DefaultFrontNecks/>}
            {activeModel === "Back Necks" && <DefaultBackNecks/>}
            {activeModel === "Front M Necks" && <FrontModelNecks/>}
            {activeModel === "Back M Necks" && <BackModelNecks/>}
        </>
        )}
        </>
    )
}
export default NecksComponent