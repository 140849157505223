import React, { useContext } from "react";
import parse from 'html-react-parser';
import { BlouseContext } from "../../../context/blouseContext";


const BackSvgContent=()=>{
   const {selectedSleeveItem,selectedBlouse,activeColor,activeFabric,
    selectedDefaultBackneckItem,selectedBackneckItem,selectedBackDropItem}=useContext(BlouseContext);

    return(
        <>
         <svg xmlns="http://www.w3.org/2000/svg" 
          width="100mm" height="100mm" version="1.1" 
            viewBox="4200 500 6750 10400"
            style={{shapeRendering:"geometricPrecision",textRendering:"geometricPrecision",imageRendering:"optimizeQuality",fillRule:"evenodd",clipRule:"evenodd",
           fill: activeColor || activeFabric ? activeColor || `url(#image-fill)` : "white"}}
            >
              <defs>
                <pattern id="image-fill" width="100%" height="100%" patternContentUnits="userSpaceOnUse">
                    <image xlinkHref={activeFabric} width="100%" />
                </pattern>
              </defs>

                <path style={{stroke:"#16406A",strokeWidth:"70.56",strokeLinecap:"round",strokeLinejoin:'round',strokeMiterlimit:"22.9256",
               fill: activeColor || activeFabric ? activeColor || `url(#image-fill)` : "white"}}
                d={selectedBlouse ? (selectedBlouse.back_base_value +
                 (selectedDefaultBackneckItem ? selectedDefaultBackneckItem.svg_content:'') +
                 (selectedBackneckItem ? selectedBackneckItem.svg_content :'') 
                //  (selectedPatcheItem ? selectedPatcheItem.base_value :'')
                 ) : null}
                 ></path>

                {selectedBlouse && parse(selectedBlouse.back_svg)}
                {selectedBlouse && selectedBackDropItem && parse(selectedBackDropItem.svg_content)}
                { selectedSleeveItem && parse(selectedSleeveItem.svg_content)} 
                {/* {selectedPatcheItem && parse(selectedPatcheItem.svg_content)} */}

                
            </svg>
        </>
    )
}
export default BackSvgContent