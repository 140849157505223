import React, { useContext } from "react";
import parse from 'html-react-parser';
import { ChudidharContext } from "../../../context/chudidharContext";
import { BlouseContext } from "../../../context/blouseContext";



const ChudiFrontSvgContent=()=>{
    const {selectdChudiItem,selectdChudiSleeveItem,selectdChudiDFrontItem
    ,selectdChudiFrontModelItem,selectdChudiHighNeckItem,selectdChudiCollarItem,activeChudiColor
    }=useContext(ChudidharContext);
    const {activeFabric}=useContext(BlouseContext)
   
    return(
        <>
         <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
          width="100mm" height="100mm" version="1.1" 
            viewBox="1500 2000 5000 10000"
            style={{shapeRendering:"geometricPrecision",textRendering:"geometricPrecision",imageRendering:"optimizeQuality",fillRule:"evenodd",clipRule:"evenodd",
            fill:activeChudiColor || activeFabric ? activeChudiColor || `url(#image-fill)` : "white"}}
            >    
            <defs>
                <pattern id="image-fill"  width="100%" height="100%" patternContentUnits="userSpaceOnUse">
                    <image href={activeFabric}  width="100%" />
                </pattern>
                
            </defs>
              
            <path style={{stroke:"#16406A",strokeWidth:"70.56",strokeLinecap:"round",strokeLinejoin:'round',strokeMiterlimit:"22.9256",
            fill:activeChudiColor || activeFabric ? activeChudiColor || `url(#image-fill)` : "white"}}
                d={selectdChudiItem ? (selectdChudiItem.base_value +
                 (selectdChudiDFrontItem ? selectdChudiDFrontItem.base_value:'') + 
                 (selectdChudiHighNeckItem ? selectdChudiHighNeckItem.svg_content :'') +
                 (selectdChudiCollarItem ? selectdChudiCollarItem.svg_content :'')+
                 (selectdChudiFrontModelItem ? selectdChudiFrontModelItem.svg_content :'')
                 ) : null}></path>
            
                {selectdChudiItem && parse(selectdChudiItem.front_svg)}
                 {selectdChudiCollarItem && parse(selectdChudiCollarItem.svg_content)}
                { selectdChudiSleeveItem && parse(selectdChudiSleeveItem.svg_content)}
                { selectdChudiDFrontItem && parse(selectdChudiDFrontItem.svg_content)}
        </svg>
        </>
    )
}
export default ChudiFrontSvgContent