import React, { useContext } from "react";
import parse from 'html-react-parser';
import { ChudidharContext } from "../../../context/chudidharContext";
import { BlouseContext } from "../../../context/blouseContext";


const ChudiBackSvgContent=()=>{
   const {selectdChudiSleeveItem,selectdChudiItem,activeChudiColor,selectdChudiBackDropItem,
    selectdChudiCollarItem,
    selectdChudiDBackItem,selectdChudiBackModelItem}=useContext(ChudidharContext);
    const {activeFabric}=useContext(BlouseContext)

    return(
        <>
         <svg xmlns="http://www.w3.org/2000/svg" 
          width="100mm" height="100mm" version="1.1" 
            viewBox="1500 2000 5000 10000"
            style={{shapeRendering:"geometricPrecision",textRendering:"geometricPrecision",imageRendering:"optimizeQuality",fillRule:"evenodd",
            clipRule:"evenodd", 
            fill:activeChudiColor || activeFabric ? activeChudiColor || `url(#image-fill)` : "white" }}>  
              <defs>
                <pattern id="image-fill" width="100%" height="100%" patternContentUnits="userSpaceOnUse">
                    <image xlinkHref={activeFabric} width="100%" />
                </pattern>
              </defs>
             
                <path style={{stroke:"#16406A",strokeWidth:"70.56",strokeLinecap:"round",strokeLinejoin:'round',strokeMiterlimit:"22.9256",
                    fill:activeChudiColor || activeFabric ? activeChudiColor || `url(#image-fill)` : "white"}}
                d={selectdChudiItem ? (selectdChudiItem.back_base_value +
                 (selectdChudiDBackItem ? selectdChudiDBackItem.svg_content:'') +
                 (selectdChudiCollarItem ? selectdChudiCollarItem.back_base_value :'') +
                 (selectdChudiBackModelItem ? selectdChudiBackModelItem.svg_content :'') 
                 ) : null}
                 ></path>

                {selectdChudiItem && parse(selectdChudiItem.back_svg)}
                { selectdChudiSleeveItem && parse(selectdChudiSleeveItem.svg_content)} 
                {selectdChudiBackDropItem && parse(selectdChudiBackDropItem.svg_content)}

                
            </svg>
        </>
    )
}
export default ChudiBackSvgContent