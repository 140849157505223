import React, { useContext, useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from 'react-toastify';
import { BlouseContext } from "../../../context/blouseContext";
import { ChudidharContext } from "../../../context/chudidharContext";
import { LoginContext } from "../../../context/logincontext";
import { PackageContext } from "../../../context/packageContext";
import { CustomerContext } from "../../../context/customerContext";
import { KidsContext } from "../../../context/kidsContext";
import urls from "../../../api.json";


const GetCustomerData=()=>{
    const {updateBlouseItems,updateSleeveItems,currentStep,
        updateDefaultFrontNecks,updateDefaultBackNecks,updateBackDropItem,updateBackNeckModels
        ,updateFrontNeckModels,updateHighNeckItem,
        updateCollarItem,backDropData
        ,itemsData,sleeveData,defaultFrontData,
        defaultBackData,frontModelData,backModelData,highNeckData,
        collarData
         }=useContext(BlouseContext);

         const {kidsItemsData,updateKidsItem,updateKidsNextButton,
             setCustomerKidsMeasurment}=useContext(KidsContext);

         const {updateChudiItems,allchudidharData,updateChudiSleeve,updateChudiDFront,
            updateChudiFrontModel,updateChudiDBack,updateChudiCollar,chudiSleeveData,
            chudiDefaultFrontData,chudiFrontModelData,chudiCollarData
           ,chudiDefaultBackData}=useContext(ChudidharContext);

         const {authTokens}=useContext(LoginContext);
         const {selectedName}=useContext(PackageContext);
         const accessToken = authTokens?.access;
         const {setCustomerMeasurment,searchName,handleSelectSearch,setOrderNewId,
            setFilterCustomer,getID,setFormData,setOrderOldId,
            showOld,setShowOld,orderDataDetails,setOrderDataDetails,
            orderNewId}=useContext(CustomerContext);


        const handleOld=()=>{
            setShowOld(true);
        }

        const getUserData = () => {
            const url = urls.urls.BASE_URL+urls.urls.gets_cutomer_data;
            
        
            let search_item=''
            let mainId=''
            let phoneNumberParams = new URLSearchParams();
            let orderIdParams = new URLSearchParams();
        
            if(searchName ==="Number"){
                  search_item=document.getElementById("search_customer_number").value;
                  phoneNumberParams.append('phone_number', search_item); 
                }
                else{
                 search_item=document.getElementById("search_customer_id").value;
                 mainId=document.getElementById("main_id").value;
                 orderIdParams.append('order_id', mainId+"-"+search_item);
                }
        
        
            const combinedParams = [
              orderIdParams.toString(),
              phoneNumberParams.toString()
            ].filter(param => param !== '').join('&');
            
        
            axios.get(`${url}?${combinedParams}`,{
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(res => {
                setFormData(res.data.data[0])
                setOrderDataDetails(res.data.data[0])
            })
            .catch(err => {
                console.log("Search Customer API Error", err);
                setFilterCustomer(false);
                toast.error('Client order not found', {
                  position: "top-center",
                  autoClose: 1000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
            });
      
           };
    
        const handleSelectChange = (e) => {
        const temp_order_id = e.target.value.split('_')[0]
        const temp_blouse_name = e.target.value.split('_')[1]
        const temp_chudi_name = e.target.value.split('_')[1]
        const temp_kids_name = e.target.value.split('_')[1]
        const temp_sleeve_name = e.target.value.split('_')[2]
        const temp_dFront_name = e.target.value.split('_')[4]
        const temp_dBack_name = e.target.value.split('_')[3]
        const temp_collar_name = e.target.value.split('_')[6]
        const temp_backdrop_name = e.target.value.split('_')[9]
        const temp_frontmodel_name = e.target.value.split('_')[7]
        const temp_backmodel_name = e.target.value.split('_')[8]
        const temp_highneck_name = e.target.value.split('_')[5]

        
        setOrderNewId(null);
        setOrderOldId(temp_order_id);

        console.log("suresh",temp_kids_name,temp_chudi_name)

        // setCurrentStep(2)
       
        
        itemsData.forEach(item => {
            if (temp_blouse_name === item.name) {
                updateBlouseItems(item);
                    orderDataDetails.measurment.blouse.forEach(blouse => {
                        // Check if the blouse_id matches the selectedBlouseId
                        if (blouse.blouse_id === temp_blouse_name) {
                            setCustomerMeasurment(blouse.measurment)
                        }
                    });
            }
        });

        sleeveData.map(each => {
            if (temp_sleeve_name === each.name) {
                updateSleeveItems(each);
            }
        });

        defaultFrontData.map(each => {
            if (temp_dFront_name === each.name) {
                updateDefaultFrontNecks(each);
                updateCollarItem(null)
                updateBackDropItem(null);
                updateHighNeckItem(null);
                
            }
        });

        defaultBackData.map(each => {
            if (temp_dBack_name === each.name) {
                updateDefaultBackNecks(each);
                updateCollarItem(null)
                updateBackDropItem(null);
            }
        });

        frontModelData.map(each => {
            if (temp_frontmodel_name === each.name) {
                updateFrontNeckModels(each);
                updateDefaultFrontNecks(null);
                updateBackDropItem(null);
                updateHighNeckItem(null);
            }
        });

        backModelData.map(each => {
            if (temp_backmodel_name === each.name) {
                updateBackNeckModels(each);
                updateDefaultBackNecks(null);
                updateBackDropItem(null);
                updateHighNeckItem(null);
            }
        });
        
        highNeckData.map(each => {
            if (temp_highneck_name === each.name) {
                updateHighNeckItem(each);
                updateDefaultFrontNecks(null);
                updateDefaultBackNecks(null);
                updateCollarItem(null)
                updateFrontNeckModels(null);
                updateBackDropItem(null);
                updateBackNeckModels(null);
            }
        });

        collarData.map(each => {
            if (temp_collar_name === each.name) {
                updateCollarItem(each);
                updateDefaultFrontNecks(null);
                updateFrontNeckModels(null);
                updateDefaultBackNecks(null);
                updateHighNeckItem(null);
                updateBackDropItem(null);
                updateBackNeckModels(null);
            }
        });

        backDropData.map(each => {
            if (temp_backdrop_name === each.name) {
                updateBackDropItem(each);
            }
        });

        allchudidharData.forEach(item => {
            if (temp_chudi_name === item.name) {
                updateChudiItems(item);
                    orderDataDetails.measurment.churidar.forEach(chudi => {
                        // Check if the churidar_id matches the selectedBlouseId
                        if (chudi.churidar_id === temp_chudi_name) {
                            setCustomerMeasurment(chudi.measurment)
                        }
                    });
            }
        });


        chudiSleeveData.map(each => {
            if (temp_sleeve_name === each.name) {
                updateChudiSleeve(each);
            }
        });

        chudiDefaultFrontData.map(each => {
            if (temp_dFront_name === each.name) {
                updateChudiDFront(each);
                updateChudiFrontModel(null);
            }
        });
        chudiDefaultBackData.map(each => {
            if (temp_dBack_name === each.name) {
                updateChudiDBack(each);
            }
        });
        chudiFrontModelData.map(each => {
            if (temp_frontmodel_name === each.name) {
                updateChudiFrontModel(each);
                updateChudiDFront(null);
            }
        });

        chudiCollarData.map(each => {
            if (temp_collar_name === each.name) {
                updateChudiCollar(each);
                updateChudiDFront(null);
                updateChudiFrontModel(null);
            }
        });

        // kidsItemsData.forEach(item => {
        //     if (temp_kids_name === item.name) {
        //         updateKidsItem(item);
        //             orderDataDetails.measurment.kids.forEach(kids => {
        //                 // Check if the blouse_id matches the selectedBlouseId
        //                 if (kids.kids_id === temp_kids_name) {
        //                     setCustomerKidsMeasurment(kids.measurment)
        //                     updateKidsNextButton()
        //                 }
        //             });
        //     }
        // });

        
        };

          useEffect(()=>{
            getID();
          },[orderNewId?.order_id])

    return(
        <>
        <Row className="col-lg-6 col-sm-6 col-md-6 nab_link_container">
            {!showOld && <Col>
               <Button variant="outline-light" size="sm" onClick={handleOld}
               disabled={currentStep >3}>Old Customer</Button>
            </Col>
            }
            {showOld && 
            <>
                <Col lg={4}>
                    <Form.Select onChange={handleSelectSearch} size="sm">
                        <option value="Customer Id">Customer Id</option>
                        <option value="Number">Number</option>
                    </Form.Select>
                </Col>
                <Col lg={6} >
                    {searchName === "Number" && 
                    <Form.Control
                    type="text" size="sm" id="search_customer_number"
                        placeholder={`Enter Your ${searchName}`}
                    />}
                    {searchName === "Customer Id" && 
                    <>
                        <Row>
                            <Col>
                            <Form.Control
                                type="text" size="sm" id="main_id" 
                                value={orderNewId?.order_id?.split("-")[0]} 
                                disabled
                                />
                            </Col>
                            <Col>
                            <Form.Control
                                type="text" size="sm"
                                id="search_customer_id"
                                placeholder={`${searchName}`}
                                />
                            </Col> 
                        </Row>
                        
                    </>
                    }
                    
                </Col>
                <Col lg={2}>
                    <button className="btn btn-outline-light btn-sm" onClick={getUserData}>Search</button>
                </Col>
             </> }
           </Row>
            <Row className="col-lg-3 col-sm-3 col-md-3 nab_link_container mx-3">
                {orderDataDetails && 
                    <Form.Select size="sm" onChange={handleSelectChange}>
                            <option value="">Select Your Model</option>
                            {selectedName === "Blouse" && orderDataDetails && orderDataDetails.measurment && orderDataDetails.measurment.blouse && orderDataDetails.measurment.blouse.map(each => (
                                <option
                                    key={each.blouse_id} // Ensure a unique key for each option
                                    value={`${orderDataDetails.order_id}_${each.blouse_id}_${each.sleeve_id}_${each.dback_neck_id}_${each.dfront_neck_id}_${each.highneck_id}_${each.collar_id}_${each.frontmodel_id}_${each.backmodel_id}_${each.backdrop_id}`}
                                >
                                    {orderDataDetails.order_id}-{each.blouse_id}
                                </option>
                            ))}


                        {selectedName === "Chudidhar" && orderDataDetails && orderDataDetails.measurment && orderDataDetails.measurment.churidar && orderDataDetails.measurment.churidar.map(each => (
                            <option
                                key={each.churidar_id} // Make sure to set a unique key for each option
                                value={`${orderDataDetails.order_id}_${each.churidar_id}_${each.sleeve_id}_${each.dback_neck_id}_${each.dfront_neck_id}_${each.highneck_id}_${each.collar_id}_${each.frontmodel_id}_${each.backmodel_id}_${each.backdrop_id}`}
                            >
                                {orderDataDetails.order_id}-{each.churidar_id}
                            </option>
                        ))}

                        {selectedName === "Kids" && orderDataDetails && orderDataDetails.measurment && orderDataDetails.measurment.kids && orderDataDetails.measurment.kids.map(each => (
                            <option
                                key={each.kids_id} // Make sure to set a unique key for each option
                                value={`${orderDataDetails.order_id}_${each.kids_id}_${each.sleeve_id}_${each.dback_neck_id}_${each.dfront_neck_id}_${each.highneck_id}`}
                            >
                                {orderDataDetails.order_id}-{each.kids_id}
                            </option>
                        ))}     

                    </Form.Select>
                    }
           </Row>

        </>
    )
}
export default GetCustomerData