import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBarComponent from "../Navbar";
import MainKidsComponent from "./layout/leftside";
import RightKidsComponent from "./layout/rightside";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../../context/logincontext";


const KidsComponent=()=>{
    const {authTokens}=useContext(LoginContext);
    const navigate=useNavigate();

    useEffect(()=>{
        if(!authTokens){
          navigate("/login")
        }
    
      })
    return(
        <Container fluid className="home">
            <NavBarComponent/>
            <Row className="p-1">
                <Col >
                <MainKidsComponent/>
                </Col>
                <Col lg={8} className="mx-1">
                <RightKidsComponent/>
                </Col>
            </Row>
        </Container>
    )
}
export default KidsComponent