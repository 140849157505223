import React, { useContext, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import CropTopDisplay from "./display";
import { CropTopContext } from "../../../context/croptopContext";

const CropTopList=()=>{
    const [blouseList, setBlouseList] = useState("All");
    const {cropTopData,allcroptopData}=useContext(CropTopContext);
    const [filterBlouse,setFilterBlouse]=useState("");
    const [filterSubBlouse,setFilterSubBlouse]=useState("");
   

    const FitlerBlouseData=allcroptopData.filter(each=>(
        each.name.toLowerCase().includes(filterBlouse.toLowerCase())
    ))
    const FitlerBlouseSubData=cropTopData.filter(each=>(
        each.name.toLowerCase().includes(filterSubBlouse.toLowerCase())
    ))

    const filteredItems = cropTopData.reduce((acc, category) => {
        if (category.name === blouseList) {
            acc.push(...category.items);
        }
        return acc;
        }, []);
    
    const handleSelect = (key) => {
        setBlouseList(key);
    };

    return(
        <>
          <div>
            <Col className="pt-1" lg={12}>
                <Button variant={blouseList === "All" ? "dark px-1":"outline-dark px-1"} size="sm"
                onClick={()=>handleSelect("All")}>All</Button>
                <Button variant={blouseList === "Model" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                onClick={()=>handleSelect("Model")}>Model</Button>
                <Button variant={blouseList === "ModelBack" ? "dark px-1":"outline-dark px-1"} size="sm"
                onClick={()=>handleSelect("ModelBack")}>ModelBack</Button>
            </Col>
            </div>
            {blouseList === "All" ? (<>
                <div className="d-flex">
                <Col className="" >
                   <Form.Control type="text" 
                   onChange={(e)=>setFilterBlouse(e.target.value)}
                   placeholder="Search By Items..." />
                 </Col>
                 <Col className="mt-2 mx-1" lg={5} sm={5} md={5}>
                   <p className="text-dark px-1"> <span>{FitlerBlouseData.length}</span></p>
                 </Col>
                 </div>
                 </>)
                 :
                 (<>
                 <div className="d-flex m-0">
                 <Col className="" >
                   <Form.Control type="text" 
                   onChange={(e)=>setFilterSubBlouse(e.target.value)}
                   placeholder="Search By Items..." />
                 </Col>
                 <Col className="mt-2" lg={4} sm={4} md={4}>
                   <p className="text-dark mx-2"> {filteredItems.length}</p>
                 </Col>
                 </div>
                 </>)
                 }

             {blouseList === "All" ? (<>
                <div className="leftside_items">
                {FitlerBlouseData.map(each=>{
                    if(blouseList === "All"){
                        return(
                        <CropTopDisplay  
                        key={each.id}
                        icon={each.icon}
                        name={each.name}
                        data={each} >
                    </CropTopDisplay>
                    )}
                })}
                
                 </div>
                 </>)
                 :
                 (<>
                 <div className="leftside_items">
                 {filteredItems.map(each=>(
                   each.name.toLowerCase().includes(filterSubBlouse.toLowerCase()) &&
                    <CropTopDisplay key={each.id}
                        icon={each.icon}
                        name={each.name}
                        data={each}>
                    </CropTopDisplay>
                ))}
                 
                 </div>
                 </>)
                 }
                 
            
            
            
        </>
    )
}
export default CropTopList