import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import { ChudidharContext } from "../../../context/chudidharContext";
import axios from "axios";
import { CustomerContext } from "../../../context/customerContext";
import { useTranslation } from 'react-i18next';
import { LoginContext } from "../../../context/logincontext";
import { Button, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import urls from "../../../api.json";

const Pantmeasurment=()=>{
    const {selectdChudiItem,getChudiCustomizationData,
      selectdChudiSleeveItem,customerChudiMeasurment,
        getPatternPage}=useContext(ChudidharContext);
    const {authTokens}=useContext(LoginContext);
    const accessToken = authTokens?.access;
    const {customerMeasurment,orderNewId,orderOldId,
      setCustomerMeasurment}=useContext(CustomerContext);
    const [loading, setLoading] = useState(false);
    const [updateId,setUpdateId]=useState("");
    const { t} = useTranslation();
    const measurement_data = t('main.page_3_buttons', { returnObjects: true });
    const onlyButtons_data = t('main.all_buttons', { returnObjects: true });


    const getDownloadCount=()=>{
      const updateCustomerData={
          "update_type": "measurment",
          "order_id":updateId,
          "measurment":{
            "churidar":[
              {
                "churidar_id": selectdChudiItem.name,
                "measurment":{
                    "length":parseFloat($("input[name='length']").val()),
                    "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
                    "hip_round":parseFloat($("input[name='hip_round']").val()),
                    "knee_height":parseFloat($("input[name='knee_height']").val()),
                    "knee_loose":parseFloat($("input[name='knee_loose']").val()),
                    "bottom_round":parseFloat($("input[name='bottom_round']").val()),
                    }
                  }
              ]
          }
    
        }
        
        axios.put(urls.urls.BASE_URL+urls.urls.order_update,updateCustomerData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
        )
        .then(res=>{
            // console.log(res.data)
        })
        .catch(err=>{
          console.log("update customer Data",err)
        })

    }

    const getPantPattern=(e)=>{
        setLoading(true)
        e.preventDefault();

        
        const PantMeasurmentInputData={
          "length":parseFloat($("input[name='length']").val()),
          "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
          "hip_round":parseFloat($("input[name='hip_round']").val()),
          "knee_height":parseFloat($("input[name='knee_height']").val()),
          "knee_loose":parseFloat($("input[name='knee_loose']").val()),
          "bottom_round":parseFloat($("input[name='bottom_round']").val()),
         }

         const  MeasurmentChudiData={
            "measurement":{
                "churidar_id":selectdChudiItem ? selectdChudiItem.id : 0,
                "sleeve_id":selectdChudiSleeveItem ? selectdChudiSleeveItem.id : 0,
                "patche_id": 2,
                "backdrop_id": 2,
                "frontneck_id": 2,
                "backneck_id": 2,
                "collar_id":2,
                "colorheight":10,
                "colorwidth":12,
                "collar_id":2,
                "length":parseFloat($("input[name='length']").val()),
                "CenterChest": 0,
                "ShoulderFinishing": 0,
                "SleeveLenght": 0,
                "SleeveRound": 0,
                "MiddleHandRound": 0,
                "FrontNeckHeight":0,
                "BackNeckHeight": 0,
                "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
                "slit_open":0,
                "WaistHeight": 0,
                "hip_round":parseFloat($("input[name='hip_round']").val()),
                "knee_height":parseFloat($("input[name='knee_height']").val()),
                "knee_loose":parseFloat($("input[name='knee_loose']").val()),
                "bottom_round":parseFloat($("input[name='bottom_round']").val()),
                "FrontDartPoint":0,
                "Full_Shoulder": 0,
                "Arm_Hole_Round": 0,
                "order_id":updateId
                }
         }
        axios.post(urls.urls.BASE_URL+urls.urls.chudi_pattern,MeasurmentChudiData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(res=>{
        getChudiCustomizationData(res.data);
        setLoading(false);
        getPatternPage();
        getDownloadCount()
        setCustomerMeasurment(PantMeasurmentInputData);
        })
        .catch(err=>{
            console.log("measurments error ",err);
        })
    }

    const getSave=()=>{
        const PantMeasurmentInputData={
          "length":parseFloat($("input[name='length']").val()),
          "WaistLoose": parseFloat($("input[name='WaistLoose']").val()),
          "hip_round":parseFloat($("input[name='hip_round']").val()),
          "knee_height":parseFloat($("input[name='knee_height']").val()),
          "knee_loose":parseFloat($("input[name='knee_loose']").val()),
          "bottom_round":parseFloat($("input[name='bottom_round']").val()),
         }
        localStorage.setItem("ChudiPantMeasurmentData",JSON.stringify(PantMeasurmentInputData));
        toast.success('Data Added Successfully!', {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
      }


      const resetInputs = () => {
        const inputs = [
        "hip_round",
        "knee_height",
        "length",
        "knee_loose",
        "bottom_round",
        "WaistLoose",
        ];
        inputs.forEach((input) => {
          $(`input[name='${input}']`).val(""); // Reset input values to empty
        });
      };

      const setTrialData = () => {
        const trialData = {
          hip_round:42,
          knee_height:22,
          length: 30,
          knee_loose:24,
          bottom_round:16,
          WaistLoose:36,
        };
    
        Object.entries(trialData).forEach(([key, value]) => {
          $(`input[name='${key}']`).val(value); // Set input values to trial data
        });
      };

      useEffect(()=>{
        if (orderOldId !== null) {
          setUpdateId(orderOldId);
        } else if (orderNewId && orderNewId.order_id !== null) {
          setUpdateId(orderNewId.order_id);
        } else {
          setUpdateId(null);
        }

        if (customerMeasurment) {
          const inputsToUpdate = [
              "length", "WaistLoose","slit_open","hip_round","knee_height",
              "knee_height","knee_loose","bottom_round"
          ];
          inputsToUpdate.forEach(inputName => {
            const inputValue = customerMeasurment[inputName] || ""; // Get value from customerMeasurment or set to empty string if undefined
            $(`input[name='${inputName}']`).val(inputValue);
        });
        }
      },[])

     
    return(
        <>
        <Row className="bg-white rounded mt-1" style={{height:"79.5vh"}}>
          <div>
          <form  onSubmit={getPantPattern}>
              <Row className="items_main_mea mt-2">
                  <Col lg={12} className="mb-1 mt-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[2]}>
                      <Form.Control type="float" name="length" placeholder="name@example.com" 
                      defaultValue={customerMeasurment && customerMeasurment.length ?
                        customerMeasurment.length : ""}
                        />
                      </FloatingLabel>
                  </Col>
                  
                  <Col lg={12} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[11]}>
                      <Form.Control type="float" name="WaistLoose" placeholder="name@example.com"
                      defaultValue={customerMeasurment && customerMeasurment.WaistLoose ?
                        customerMeasurment.WaistLoose : ""}
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[17]}>
                      <Form.Control type="float" name="hip_round" placeholder="name@example.com"
                      defaultValue={customerMeasurment && customerMeasurment.hip_round ?
                        customerMeasurment.hip_round : ""}
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[20]}>
                      <Form.Control type="float" name="knee_height" placeholder="name@example.com"
                      defaultValue={customerMeasurment && customerMeasurment.knee_height ?
                        customerMeasurment.knee_height : ""}
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[21]}>
                      <Form.Control type="float" name="knee_loose" placeholder="name@example.com"
                      defaultValue={customerMeasurment && customerMeasurment.knee_loose ?
                        customerMeasurment.knee_loose : ""}
                        />
                      </FloatingLabel>
                  </Col>
                  <Col lg={12} className="mb-1">
                      <FloatingLabel controlId="floatingInputGrid" label={measurement_data[22]}>
                      <Form.Control type="float" name="bottom_round" placeholder="name@example.com"
                      defaultValue={customerMeasurment && customerMeasurment.bottom_round ?
                        customerMeasurment.bottom_round : ""}
                        />
                      </FloatingLabel>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      {loading ? (
                      <Button variant="outline-success" size="sm" disabled>
                          <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          />
                          Loading...
                      </Button>):( 
                      <Button size="sm" variant="outline-success" type="submit">{onlyButtons_data[4]}</Button>)
                      }
                      <Button size="sm" variant="outline-dark mx-1"
                      type="button" onClick={()=>resetInputs()}>{onlyButtons_data[5]}</Button> 
                      <Button size="sm" variant="outline-info mx-1"
                      type="button" onClick={()=>setTrialData()}>{onlyButtons_data[6]}</Button>
                      <Button size="sm" variant="outline-info mx-2" disabled>Select Size</Button>
                  </Col>
                  
              </Row>
          </form>
          </div>
         </Row>
        </>
    )
}
export default Pantmeasurment