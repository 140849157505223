import React, { useContext, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Form from 'react-bootstrap/Form';
import BackModelDisplay from "./backdisplay";
import { BlouseContext } from "../../../../context/blouseContext";
import { useTranslation } from 'react-i18next';


const BackModelNecks=()=>{
  const {backModelData}=useContext(BlouseContext);
  const [filterData,setFilterData]=useState('');
  const { t } = useTranslation();

  const getFilterData= backModelData && backModelData.filter(each=>(
    each.name.toLowerCase().includes(filterData.toLowerCase())
  ));

 

    return(
        <>
          <div className="d-flex">
                <Col className="" >
                   <Form.Control type="text" placeholder="Search By Items..." 
                   onChange={(e)=>setFilterData(e.target.value)}/>
                 </Col>
                 <Col className="mx-2 mt-2" lg={5}>
                   <p className="text-dark px-1">{t('main.search_total')} {getFilterData && getFilterData.length}</p>
                 </Col>
            </div>
            <div className="leftside_items">
                 {getFilterData?.map(each=>(
                  <BackModelDisplay key={each.id}
                  icon={each.icon}
                  name={each.name}
                  data={each}></BackModelDisplay>
                 ))}
            </div>
           
        </>
    )
}
export default BackModelNecks