import { Button } from "react-bootstrap";
import React, { useContext } from "react";
import { ChudidharContext } from "../../../../../context/chudidharContext";
import { useTranslation } from 'react-i18next';


const DFrontDisplay=({name,icon,data})=>{
    const {updateChudiDFront,selectdChudiDFrontItem,
        updateChudiFrontModel}=useContext(ChudidharContext);
        const { t, i18n } = useTranslation();
        const default_front_neck_Data = t('main.chudi_default_front_neck', { returnObjects: true });
    
    const getDFrontNecks=(jsondata)=>{
        updateChudiDFront(jsondata);
        updateChudiFrontModel(null)

    }

    return(
        <>
            <Button variant="white border" className={selectdChudiDFrontItem && selectdChudiDFrontItem.name === name ? 
            "items_display btn-danger":"items_display"}
           onClick={()=>getDFrontNecks(data,name)}
            >
                <img src={icon} alt={name} className="icon_image"/>
                {default_front_neck_Data[name] ? default_front_neck_Data[name] :name}
               
            </Button>
        </>
    )
}
export default DFrontDisplay