import React, { useContext, useState } from "react";
import { Button, Col, Row } from 'react-bootstrap';
import { ChudidharContext } from "../../context/chudidharContext";
import ChudiList from "./chudidhar";
import ChudiSleeveList from "./sleeves";
import ChudiNecksComponent from "./necks";
import ChudiCollarList from "./collars";
import ChudiBackDropList from "./backdrops";
import CustomerComponent from "../customer";
import ChudidharMeasurment from "./chudidharmeasurment";
import ChudiCustomization from "./customization";
import { useTranslation } from 'react-i18next';

const MainChudiComponent=()=>{
    const {selectdChudiItem,handlePreviousClick,handleNextClick,currentStep}=useContext(ChudidharContext)
    const [activeKey, setActiveKey] = useState("Chudidhar");
    const { t } = useTranslation();
    const onlyButtons_data = t('main.all_buttons', { returnObjects: true });

    const handleSelect = (key) => {
        setActiveKey(key);
    };
    
    return(
        <>
        {currentStep ===1 && (
            <>
            <Row >
            <Col > 
                <Button variant={activeKey === "Chudidhar" ? "light mx-1":"outline-light mx-1"} size="sm"
                 onClick={()=>handleSelect("Chudidhar")}>Chudidhar</Button>
                <Button variant={activeKey === "Sleeves" ? "light mx-1":"outline-light mx-1"} size="sm"
                onClick={()=>handleSelect("Sleeves")}>Sleeves</Button>
                 {selectdChudiItem && selectdChudiItem.front_neck_shapes && (
                <Button variant={activeKey === "Necks" ? "light mx-1":"outline-light mx-1"} size="sm"
                onClick={()=>handleSelect("Necks")}>Necks</Button>)}
                {selectdChudiItem && selectdChudiItem.collar &&(
                <Button variant={activeKey === "Collars" ? "light mx-1":"outline-light mx-1"} size="sm"
                onClick={()=>handleSelect("Collars")}>Collars</Button>)}
                {selectdChudiItem && selectdChudiItem.back_drops &&(
                <Button variant={activeKey === "BackDrops" ? "light mx-1":"outline-light mx-1"} size="sm"
                onClick={()=>handleSelect("BackDrops")}>BackDrops</Button>
                )}
            </Col>
        </Row>
        <Row className="bg-white rounded mt-1" style={{height:"79.5vh"}}>
            {activeKey === "Chudidhar" && <ChudiList/>}
                {activeKey === "Sleeves" && <ChudiSleeveList/>}
            {activeKey === "Necks" && <ChudiNecksComponent/>}
            {activeKey === "Collars" && <ChudiCollarList/>}
            {activeKey === "BackDrops" && <ChudiBackDropList/>}
            </Row>
            </>
        )}
       

        {currentStep === 2 && 
       <>
       <CustomerComponent/>
       </>
       }
       {currentStep === 3 && 
       <>
       <ChudidharMeasurment/>
       </>
       }
       {currentStep === 4 && 
       <>
       <ChudiCustomization/>
       </>
       }

        <Row className="bg-white rounded mt-1 p-1">
        <Col>
        <Button size="sm" variant={currentStep===1 ? "outline-dark":"dark"} onClick={handlePreviousClick}
        disabled={ currentStep ===1}>{onlyButtons_data[1]}</Button>
        </Col>
        <Col className="text-end">
        <Button size="sm" variant={currentStep===3 || currentStep===4 ? "outline-dark":"dark"} onClick={handleNextClick} 
        disabled={!selectdChudiItem || currentStep ===3 || currentStep ===4}>{onlyButtons_data[0]}</Button>
        </Col>
       </Row>
      
     </>
    )
}
export default MainChudiComponent