import React, { createContext, useContext, useEffect, useState } from "react";
import { LoginContext } from "./logincontext";
import axios from "axios";
import urls from "../api.json"

export const ChudidharContext=createContext({});

const ChudidharProvider=({children})=>{
    const {authTokens}=useContext(LoginContext);
    const accessToken = authTokens?.access;
    const [chudidharData,setChudidharData]=useState([]);
    const [allchudidharData,setAllChudidharData]=useState([]);
    const [chudiSleeveData,setChudiSleeveData]=useState([]);
    const [chudiDefaultFrontData,setChudiDefaultFrontData]=useState([]);
    const [chudiDefaultBackData,setChudiDefaultBackData]=useState([]);
    const [chudiFrontModelData,setChudiFrontModelData]=useState([]);
    const [chudiBackModelData,setChudiBackModelData]=useState([]);
    const [chudiHighNeckData,setChudiHighNeckData]=useState([]);
    const [chudiCollarData,setChudiCollarData]=useState([]);
    const [chudiBackDropData,setChudiBackDropData]=useState([]);
    const [selectdChudiItem,setSelectedChudiItem]=useState(null);
    const [selectdChudiSleeveItem,setSelectedChudiSleeveItem]=useState(null);
    const [selectdChudiDFrontItem,setSelectedChudiDFrontItem]=useState(null);
    const [selectdChudiDBackItem,setSelectedChudiDBackItem]=useState(null);
    const [selectdChudiFrontModelItem,setSelectedChudiFrontModelItem]=useState(null);
    const [selectdChudiBackModelItem,setSelectedChudiBackModelItem]=useState(null);
    const [selectdChudiHighNeckItem,setSelectedChudiHighNeckItem]=useState(null);
    const [selectdChudiBackDropItem,setSelectedChudiBackDropItem]=useState(null);
    const [selectdChudiCollarItem,setSelectedChudiCollarItem]=useState(null);
    const [chudiCustomizeData,setChudiCustomizeData]=useState(null)
    const [currentStep, setCurrentStep] = useState(1);
    const [activeChudiColor,setActiveChudiColor]=useState("");
    
    const getChudiItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.chudi_items, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setChudidharData(res.data.categories)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    const getChudiSleeveItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.chudi_sleeve, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setChudiSleeveData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    const getChudiBackDropItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.chudi_backdrop, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setChudiBackDropData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    const getChudiDFrontNeckItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.chudi_d_frontnecks, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setChudiDefaultFrontData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    const getChudiDBackNeckItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.chudi_d_backnecks, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setChudiDefaultBackData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    const getChudiFrontModelItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.chudi_frontmodels, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setChudiFrontModelData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    const getChudiBackModelItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.chudi_backmodels, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setChudiBackModelData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    const getChudiHighNeckItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.chudi_highnecks, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setChudiHighNeckData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }
    
    const getChudiCollarItems=()=>{
        axios.get(urls.urls.BASE_URL+urls.urls.chudi_collar, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
            setChudiCollarData(res.data)
          })
          .catch(err=>{
            console.log("Error Chudidhar API Error",err)
          })

    }

    const getPatternPage=()=>{
      setCurrentStep(4);
    }

    const updateChudiItems=(value)=>{
        setSelectedChudiItem(value)
    }
    const updateChudiSleeve=(value)=>{
        setSelectedChudiSleeveItem(value)
    }
    const updateChudiDFront=(value)=>{
        setSelectedChudiDFrontItem(value)
    }
    const updateChudiDBack=(value)=>{
        setSelectedChudiDBackItem(value)
    }
    const updateChudiFrontModel=(value)=>{
        setSelectedChudiFrontModelItem(value)
    }
    const updateChudiBackModel=(value)=>{
        setSelectedChudiBackModelItem(value)
    }
    const updateChudiHighNeck=(value)=>{
        setSelectedChudiHighNeckItem(value)
    }
    const updateChudiBackDrop=(value)=>{
        setSelectedChudiBackDropItem(value)
    }
    const updateChudiCollar=(value)=>{
        setSelectedChudiCollarItem(value)
    }
    

    const getChudiCustomizationData=(value)=>{
      setChudiCustomizeData(value)
    }

    const getHome=()=>{
      setCurrentStep(1);
    }

    const updateChudiColor=(value)=>{
      setActiveChudiColor(value)
    }

    const getAllChudiClear=()=>{
      setCurrentStep(1);
      updateChudiItems(null);
      updateChudiSleeve(null);
      updateChudiDFront(null);
      updateChudiDBack(null);
      updateChudiFrontModel(null);
      updateChudiBackModel(null);
      updateChudiHighNeck(null);
      updateChudiBackDrop(null);
      updateChudiCollar(null);
      updateChudiColor(null)
    }


    const handleNextClick = () => {
        if (currentStep < 4) {
            setCurrentStep(currentStep + 1);
          }
    }

    const handlePreviousClick = () => {
        if (currentStep > 1) {
          setCurrentStep(currentStep - 1);
        }
    }



    useEffect(()=>{
        if(authTokens){
            getChudiItems();
            getChudiSleeveItems();
            getChudiBackDropItems();
            getChudiDFrontNeckItems();
            getChudiDBackNeckItems();
            getChudiFrontModelItems();
            getChudiBackModelItems();
            getChudiHighNeckItems();
            getChudiCollarItems();
        }
    },[authTokens])

    useEffect(() => {
        const items = chudidharData.reduce((acc, category) => acc.concat(category.items), []);
        setAllChudidharData(items); 
      }, [chudidharData]);



    return(
        <ChudidharContext.Provider   value={{chudidharData,chudiSleeveData,chudiDefaultFrontData,
            chudiDefaultBackData,chudiFrontModelData, chudiBackModelData,chudiHighNeckData,chudiCollarData,
            chudiBackDropData,allchudidharData,selectdChudiItem,selectdChudiSleeveItem,selectdChudiDFrontItem,
            selectdChudiDBackItem,selectdChudiFrontModelItem,selectdChudiBackModelItem,selectdChudiHighNeckItem,
            selectdChudiBackDropItem,selectdChudiCollarItem,handleNextClick,handlePreviousClick,currentStep,
            updateChudiItems,updateChudiSleeve,updateChudiDFront,updateChudiDBack,updateChudiFrontModel,
            chudiCustomizeData,getChudiCustomizationData,getHome,getPatternPage,getAllChudiClear,
            activeChudiColor,updateChudiColor
            ,updateChudiBackModel,updateChudiHighNeck,updateChudiBackDrop,updateChudiCollar}}>
            {children}
        </ChudidharContext.Provider>
    )
}

export default ChudidharProvider