import React, { useContext, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { ChudidharContext } from "../../../context/chudidharContext";
import ChudiBackDropDisplay from "./display";


const ChudiBackDropList=()=>{
    const {chudiBackDropData}=useContext(ChudidharContext);
    const [filterBackDrop,setFilterBackdrop]=useState("");

    const FilterData=chudiBackDropData.filter(each=>(
        each.name.toLowerCase().includes(filterBackDrop.toLowerCase())
      ))


    return(
        <>
        <div className="d-flex mt-2">
           <Col className="">
            <Form.Control type="text" 
            onChange={(e)=>setFilterBackdrop(e.target.value)}
            placeholder="Search By Items..." />
            </Col>
            <Col className="mx-2 mt-2" lg={4} sm={4} md={4}>
            <p className="text-dark ">Total Items {FilterData.length}</p>
            </Col>
        </div>
        <div className="leftside_items_sleeve">
           {FilterData?.map(each=>(
                  <ChudiBackDropDisplay key={each.id}
                  icon={each.icon}
                  name={each.name}
                  data={each}>
                  </ChudiBackDropDisplay>
                 ))}
        </div>
        </>
    )
}
export default ChudiBackDropList