import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { BlouseContext } from "../../../context/blouseContext";
import "./index.css"
import urls from "../../../api.json";
import { ChudidharContext } from "../../../context/chudidharContext";
import { KidsContext } from "../../../context/kidsContext";


const FabricComponent=()=>{
    const {updateFabric,updateColor}=useContext(BlouseContext);
    const {updateChudiColor}=useContext(ChudidharContext);
    const {updateKidsColor}=useContext(KidsContext)
    const [file, setFile] = useState(null);
    const baseurl=urls.urls.BASE_URL;


    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
      };

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('image', file);
    
        fetch(baseurl+urls.urls.fabric_upload, {
          method: 'POST',
          body: formData,
        })
          .then(response => response.json())
          .then(data => {
            getFabricApply(baseurl+data.file_path)
          })
          .catch(error => console.error('Error:', error));
      }

    const getFabricApply = (fabric, index) => {
        updateFabric(fabric);
        updateColor(null);
        updateChudiColor(null)
        updateKidsColor(null)
    };
    return(
        <Row className="p-1 mt-3">
            <Col lg={6} sm={3} className="">
                <Button variant="white" size="sm" className="mx-2 fabric_button" onClick={()=>getFabricApply("/media/fabrics/test_03.jpeg")}
                   id="1" title="Fabric1">
                      <div className="fabric1"></div>
                </Button>
                <Button variant="white" size="sm" className="mx-2 fabric_button" onClick={()=>getFabricApply("/media/fabrics/test01.jpg")}
                   id="2" title="Fabric2">
                      <div className="fabric2"></div>
                </Button>
                <Button variant="white" size="sm" className="mx-2 fabric_button" onClick={()=>getFabricApply("/media/fabrics/test_02.avif")}
                   id="3" title="Fabric3">
                      <div className="fabric3"></div>
                </Button>
                <Button variant="white" size="sm" className="mx-2 fabric_button" onClick={()=>getFabricApply("/media/fabrics/ukrainian-floral-flower-embroidery-seamless-pattern-vector-ethnic-illustration_658151-3314.avif")}
                   id="4" title="Fabric4">
                      <div className="fabric4"></div>
                </Button>
                
            </Col>
            <Col lg={4} sm={2}>
                <Form.Group controlId="formFile" >
                    <Form.Control type="file" onChange={handleFileChange}/>
                </Form.Group>
            </Col>
            <Col lg={2} sm={1}>
                <Button onClick={handleUpload}>
                Upload
                </Button>
            </Col>
        </Row>
    )
}
export default FabricComponent