import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBarComponent from "../Navbar";
import MainCropComponent from "./layout/leftside";
import RightCropComponent from "./layout/rightside";
import { LoginContext } from "../../context/logincontext";
import { useNavigate } from "react-router-dom";


const CropTopComponent=()=>{
    const {authTokens}=useContext(LoginContext);
    const navigate=useNavigate();

    useEffect(()=>{
        if(!authTokens){
          navigate("/login")
        }
    
      })
    return(
        <Container fluid className="home">
            <NavBarComponent />
            <Row className="p-1">
                <Col >
                <MainCropComponent/>
                </Col>
                <Col lg={8} className="mx-1">
                <RightCropComponent/>
                </Col>
            </Row>
        </Container>
    )
}
export default CropTopComponent