import React, { useContext } from "react";
import parse from 'html-react-parser';
import { BlouseContext } from "../../../context/blouseContext";



const FrontSvgContent=()=>{
    const {selectedBlouse,selectedSleeveItem,selectedDefaultFrontneckItem
    ,selectedFrontneckItem,selectedHighneckItem,selectedCollarItem,activeColor,activeFabric
    }=useContext(BlouseContext)


    return(
        <>
         <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
          width="100mm" height="100mm" version="1.1" 
            viewBox="4200 500 6750 10400"
            style={{shapeRendering:"geometricPrecision",textRendering:"geometricPrecision",imageRendering:"optimizeQuality",fillRule:"evenodd",clipRule:"evenodd",
            fill:activeColor || activeFabric ? activeColor || `url(#image-fill)` : "white"}}
            >    
            <defs>
                <pattern id="image-fill"  width="100%" height="100%" patternContentUnits="userSpaceOnUse">
                    <image href={activeFabric}  width="100%" />
                </pattern>
                
            </defs>
              
            <path style={{stroke:"#16406A",strokeWidth:"70.56",strokeLinecap:"round",strokeLinejoin:'round',strokeMiterlimit:"22.9256",
           fill: activeColor || activeFabric ? activeColor || `url(#image-fill)` : "white"}}
                d={selectedBlouse ? (selectedBlouse.base_value +
                 (selectedDefaultFrontneckItem ? selectedDefaultFrontneckItem.svg_content:'') + 
                 (selectedHighneckItem ? selectedHighneckItem.svg_content :'') +
                 (selectedCollarItem ? selectedCollarItem.svg_content :'')+
                 (selectedFrontneckItem ? selectedFrontneckItem.svg_content :'')
                 ) : null}></path>
            
                {selectedBlouse && parse(selectedBlouse.front_svg)}
                 {selectedCollarItem && parse(selectedCollarItem.svg_content)}
                { selectedSleeveItem && parse(selectedSleeveItem.svg_content)}
        </svg>
        </>
    )
}
export default FrontSvgContent