import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { toast } from 'react-toastify';
import { LoginContext } from "./logincontext";
import urls from "../api.json"

export const CustomerContext = createContext({});


const CustomerProvider = ({ children }) => {
  const initialCustomerData = {
    orderId: null,
    customerName: null,
    emailId: null,
    phoneNumber: null,
    address: null,
  };


  const {authTokens}=useContext(LoginContext);
  const [searchName,setSearchName]=useState("Customer Id");
  const [orderDataDetails,setOrderDataDetails]=useState(null);
  const accessToken = authTokens?.access;
  const [modelshowCustomer, setModelshowCustomer] = useState(false);
  const [orderNewId,setOrderNewId]=useState("");
  const [filterCustomer,setFilterCustomer]=useState(false);
  const [updateOrderDetails,setUpdateOrderDetails]=useState(null)
  const [orderOldId, setOrderOldId] = useState(null);
  const [customerData, setCustomerData] = useState(initialCustomerData);
  const [customerMeasurment, setCustomerMeasurment] = useState([]);
  const [formData, setFormData] = useState({});
  const [showOld,setShowOld]=useState(false);
  
  const handleCustomerFormSubmit = (e) => {
    e.preventDefault();
    const formData = {
      "customer_details":{
        order_id: e.target.orderId.value,
        customer_name: e.target.customerName.value,
        email:"suresh@gmail.com",
        phone_number: e.target.phoneNumber.value,
        address: e.target.address.value,
        order_date:e.target.order_date.value,
        exp_del_date:e.target.exp_del_date.value,
      }
    }
    axios.post(urls.urls.BASE_URL+urls.urls.get_id,formData,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }})
      .then(res=>{
        setCustomerData(formData);
        toast.success('Your Data Saved Successfully!', {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        
      })
      .catch(err=>{
          console.log("measurments error ",err);
          toast.error('Your Data Not Saevd!', {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      })  
  };

  const handleSelectSearch=(e)=>{
    setSearchName(e.target.value);
  }

  const getID =()=>{
    axios.get(urls.urls.BASE_URL+urls.urls.get_id,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(res=>{
              setOrderNewId(res.data);
              // localStorage.setItem("CustomerData",JSON.stringify(res.data))
          })
          .catch(err=>{
              console.log("User Data Order Id API Error",err);
          })
  }

  

  const contextData = {
    CustomerFrom: handleCustomerFormSubmit,
    customerData: customerData,
    orderDataDetails: orderDataDetails ,
    searchName:searchName, 
    setModelshowCustomer:setModelshowCustomer,
    modelshowCustomer:modelshowCustomer,
    handleSelectSearch:handleSelectSearch,
    getID:getID,
    filterCustomer:filterCustomer,
    orderNewId:orderNewId,
    setUpdateOrderDetails:setUpdateOrderDetails,
    updateOrderDetails:updateOrderDetails,
    setFilterCustomer:setFilterCustomer,
    orderOldId:orderOldId,
    setOrderNewId:setOrderNewId,
    setOrderOldId:setOrderOldId,
    setCustomerMeasurment:setCustomerMeasurment,
    customerMeasurment:customerMeasurment,
    setFormData:setFormData,
    formData:formData,
    showOld:showOld,
    setShowOld:setShowOld,
    setOrderDataDetails:setOrderDataDetails
  };
 

  return (
    <CustomerContext.Provider value={contextData}>
      { children }
    </CustomerContext.Provider>
  );
};

export default CustomerProvider;
