import React, { useContext, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { BlouseContext } from "../../../context/blouseContext";
import BackdropDisplay from "./backdropdisplay";
import { useTranslation } from 'react-i18next';


const BackDropList=()=>{
    const {backDropData}=useContext(BlouseContext);
    const [filterBackDrop,setFilterBackdrop]=useState("");
    const { t } = useTranslation();

    const FilterData=backDropData.filter(each=>(
        each.name.toLowerCase().includes(filterBackDrop.toLowerCase())
      ))


    return(
        <>
        <div className="d-flex mt-2">
           <Col className="">
            <Form.Control type="text" 
            onChange={(e)=>setFilterBackdrop(e.target.value)}
            placeholder="Search By Items..." />
            </Col>
            <Col className="mx-2 mt-2" lg={5} sm={4} md={4}>
            <p className="text-dark px-1">{t('main.search_total')} {FilterData.length}</p>
            </Col>
        </div>
        <div className="leftside_items_sleeve">
           {FilterData?.map(each=>(
                  <BackdropDisplay key={each.id}
                  icon={each.icon}
                  name={each.name}
                  data={each}>
                  </BackdropDisplay>
                 ))}
        </div>
        </>
    )
}
export default BackDropList