import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { ChudidharContext } from "../../../../../context/chudidharContext";
import { useTranslation } from 'react-i18next';


const FrontNeckModel=({name,icon,data})=>{
    const {updateChudiFrontModel,selectdChudiFrontModelItem,chudiDefaultFrontData,
        updateChudiDFront,selectdChudiDFrontItem}=useContext(ChudidharContext);
        const { t } = useTranslation();
        const front_neck_model_Data = t('main.chudi_front_models', { returnObjects: true });

    const getFrontNeckModels=(jsondata)=>{
        updateChudiFrontModel(jsondata);


        // check the model is true default neck is without neck active
        if(selectdChudiDFrontItem === true){
            chudiDefaultFrontData.map((each)=>{
                if(each.name === "Round Neck"){
                    updateChudiDFront(each);
                }
            })
        }
        else{
            chudiDefaultFrontData.map((each)=>{
                if(each.name === "Without Neck"){
                    updateChudiDFront(each);
                }
            })
        }
       
        
    }  
      

    return(
        <>
            <Button variant="white border" className={selectdChudiFrontModelItem && selectdChudiFrontModelItem.name === name ?
             "items_display btn-danger":"items_display"}
               onClick={()=>getFrontNeckModels(data)}>
                <img src={icon} alt={name} className="icon_image"/>
                {front_neck_model_Data[name] ? front_neck_model_Data[name]:name}
            </Button>
        </>

    )
}
export default FrontNeckModel