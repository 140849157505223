import React, { useContext, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import { BlouseContext } from "../../../context/blouseContext";
import { useTranslation } from 'react-i18next';
import { LoginContext } from "../../../context/logincontext";
import urls from "../../../api.json";


const BlouseCustomization = () => {
    const { customizeData, getCustomizationData, defaultFrontData, selectedDefaultBackneckItem,
        selectedDefaultFrontneckItem, defaultBackData } = useContext(BlouseContext);
    const [localCustomizeData, setLocalCustomizeData] = useState(customizeData.customizations_form);
    const [loading, setLoading] = useState(false);
    const [tempFrontNeck, setTempFrontNeck] = useState();
    const [tempBackNeck, setTempBackNeck] = useState();
    const {authTokens}=useContext(LoginContext);
    const accessToken = authTokens?.access;
    const [activeKey, setActiveKey] = useState("CustomizationForm");
    const { t } = useTranslation();
    const customize_data = t('main.page_4_buttons', { returnObjects: true });
    const onlyButtons_data = t('main.all_buttons', { returnObjects: true });
    const customization_data = t('main.blouse_customize_data', { returnObjects: true });
    const customization_Check_data = t('main.blouse_customize_check', { returnObjects: true });

    const handleSelect = (key) => {
        setActiveKey(key);
    };

    const handleWithOutNeck = (value) => {
        if (value.target.name === "front_neck") {
            if (value.target.checked) {
                setTempFrontNeck(selectedDefaultFrontneckItem.id);
                defaultFrontData.map((each) => {
                    if (each.name === "Without Neck") {
                        customizeData.measurement.default_front_neck_id = each.id;
                    }
                });

            } else {
                customizeData.measurement.default_front_neck_id = tempFrontNeck;
            }
        } else {
            if (value.target.checked) {
                setTempBackNeck(selectedDefaultBackneckItem.id);
                defaultBackData.map((each) => {
                    if (each.name === "Without Neck") {
                        customizeData.measurement.default_back_neck_id = each.id;
                    }
                });
            } else {
                customizeData.measurement.default_back_neck_id = tempBackNeck;
            }
        }
    };

    const handleWithOutNeckOther = (value) => {
        if (value.target.name === "front_neck") {
            if (value.target.checked) {
                customizeData.measurement.default_front_neck_id = 1;
            } else {
                customizeData.measurement.default_front_neck_id = 0;
            }
        } else {
            if (value.target.checked) {
                customizeData.measurement.default_back_neck_id = 1;
            } else {
                customizeData.measurement.default_back_neck_id = 0;
            }
        }
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;

        let updatedData = { ...localCustomizeData };

        if (name === 'backdrop_type') {
            updatedData[name] = value;
        } else {
            updatedData[name] = parseFloat(value); // Ensure to parse the value to a float if needed
        }
        setLocalCustomizeData(updatedData);
    };

    const handleCollarShapeChange = (e) => {
        const { name, value } = e.target;

        let updatedData = { ...localCustomizeData };

        if (name === 'collar_shape') {
            updatedData[name] = value;
        } else {
            updatedData[name] = parseFloat(value); // Ensure to parse the value to a float if needed
        }
        setLocalCustomizeData(updatedData);
    };

    const handleShoulderChange = (e) => {
        const { name, value } = e.target;

        let updatedData = { ...localCustomizeData };

        if (name === 'shoulder_shape') {
            updatedData[name] = value;
        } else {
            updatedData[name] = parseFloat(value); // Ensure to parse the value to a float if needed
        }
        setLocalCustomizeData(updatedData);
    };

    const handleSelectBottomChange = (e) => {
        const { name, value } = e.target;

        let updatedData = { ...localCustomizeData };

        if (name === 'bottom_curve') {
            updatedData[name] = value;
        } else {
            updatedData[name] = parseFloat(value);
        }
        setLocalCustomizeData(updatedData);
    };

    const handleSideZip = (e) => {
        const { name, checked } = e.target;
        setLocalCustomizeData(prevState => ({
            ...prevState,
            [name]: checked // Update open_sleeve value based on checkbox state
        }));
    };


    const getUpdate = (event) => {
        event.preventDefault();
        setLoading(true)
        const customizations_form = {}
        for (let element = 0; element < event.target.length; element++) {
            const { name, value, type, checked } = event.target[element];

            // Check if the element is a checkbox
            if (type === 'checkbox') {
                customizations_form[name] = checked; // Update the state directly with checkbox value
            } else {
                // For other input types, update the state as usual
                const parsedValue = !isNaN(value) ? Number(value) : value;
                customizations_form[name] = parsedValue;
            }
        }
        const payload = {
            "measurement": customizeData.measurement,
            "customizations_form": customizations_form
        }
        axios.post(urls.urls.BASE_URL+urls.urls.blouse_pattern, payload,{
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then(res => {
                console.log(payload)
                setLocalCustomizeData(res.data.customizations_form);
                setLoading(false);
                getCustomizationData(res.data);
                for (let element = 0; element < event.target.length; element++) {
                    event.target[element].value = res.data.customizations_form[event.target[element].name]
                }
            })
            .catch(err => {
                console.log("update Pattern error ", err);
            })
    }


    const formatLabel = (label) => {
        return label.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    return (
        <>
        <Row>
            <Col > 
                <Button variant="outline-light" size="sm"
                >Customization Details</Button>
            </Col>
        </Row>
            <Row className="bg-white rounded mt-1" style={{ height: "79.5vh" }}>
                <div>
                    <form className="" onSubmit={getUpdate}>
                        <div className="items_main_cus row">
                            {Object.keys(localCustomizeData)
                                .filter(key => !(key === 'size' || key === 'Neck_Seam' || key === 'shoulder_shape'
                                    || key === ''
                                    || key === 'backdrop_type' || key === 'side_zip' ||
                                    key === 'Shoulder_Finish' || key === 'Front_Cup_Size')) // Filter out keys
                                .map((key, index) => (
                                    <Col lg={6} key={index} className="mb-1 mt-1">
                                        <FloatingLabel controlId={`floatingInputGrid-${key}`} label={formatLabel(key)}>
                                            <Form.Control type="number"
                                                value={localCustomizeData[key]}
                                                name={key}
                                                onChange={handleSelectChange}
                                                disabled={key === 'Full_Shoulder' ||
                                                    key === 'Arm_Hole_Round'} />
                                        </FloatingLabel>
                                    </Col>
                                ))}
                            {Object.keys(localCustomizeData)
                                .filter(key => key === "backdrop_type")
                                .map((key, index) => (
                                    <Col>
                                        <FloatingLabel controlId="floatingSelect" label={formatLabel(key)}>
                                            <Form.Select aria-label="Floating label select example"
                                                name={key}
                                                value={localCustomizeData[key] || ''}
                                                onChange={handleSelectChange}>
                                                <option value="Button">{customization_Check_data[9]}</option>
                                                <option value="Hook">{customization_Check_data[10]}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                ))}
                            {Object.keys(localCustomizeData)
                                .filter(key => key === "collar_shape")
                                .map((key, index) => (
                                    <Col>
                                        <FloatingLabel controlId="floatingSelect" label={formatLabel(key)}>
                                            <Form.Select aria-label="Floating label select example"
                                                name={key}
                                                value={localCustomizeData[key] || ''}
                                                onChange={handleCollarShapeChange}>
                                                <option value="straight">{customization_Check_data[4]}</option>
                                                <option value="line">{customization_Check_data[7]}</option>
                                                <option value="v_shape">{customization_Check_data[8]}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                ))}
                            {Object.keys(localCustomizeData)
                                .filter(key => key === "bottom_curve")
                                .map((key, index) => (
                                    <Col>
                                        <FloatingLabel controlId="floatingSelect" label={formatLabel(key)}>
                                            <Form.Select aria-label="Floating label select example"
                                                name={key}
                                                value={localCustomizeData[key] || ''}
                                                onChange={handleSelectBottomChange}>
                                                <option value="line">{customization_Check_data[7]}</option>
                                                <option value="curve">{customization_Check_data[8]}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                ))}
                            {Object.keys(localCustomizeData)
                                .filter(key => key === "shoulder_shape")
                                .map((key, index) => (
                                    <Col>
                                        <FloatingLabel controlId="floatingSelect" label={formatLabel(key)}>
                                            <Form.Select aria-label="Floating label select example"
                                                name={key}
                                                value={localCustomizeData[key] || ''}
                                                onChange={handleShoulderChange}>
                                                <option value="straight">{customization_Check_data[4]}</option>
                                                <option value="neck_side_slop">{customization_Check_data[5]}</option>
                                                <option value="shoulder_side_slop">{customization_Check_data[6]}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                ))}
                            {Object.keys(localCustomizeData)
                                .filter(key => key === "side_zip")
                                .map((key, index) => (
                                    <Col>
                                        <input
                                            className="form-check-input mx-2"
                                            type="checkbox"
                                            name="side_zip"
                                            id="inlineCheckbox3"
                                            onChange={handleSideZip}
                                            checked={localCustomizeData.side_zip}
                                        />
                                        <label className="form-check-label"
                                            htmlFor="inlineCheckbox3">{customization_Check_data[1]}</label>
                                    </Col>
                                ))}

                        </div>
                        <Row className="mt-2">
                            <Col>
                                {loading ? (
                                    <Button variant="outline-success" size="sm" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </Button>) : (
                                        <Button size="sm" variant="outline-success" type="submit">{onlyButtons_data[3]}</Button>)
                                }
                            </Col>
                        </Row>
                    </form>
                    <Row className="mt-1">
                        {selectedDefaultFrontneckItem ? (
                            <Col>
                                <input
                                    className="form-check-input mx-2"
                                    type="checkbox"
                                    name="front_neck"
                                    onChange={handleWithOutNeck}
                                    id="inlineCheckbox1"
                                />
                                <label className="form-check-label"
                                    htmlFor="inlineCheckbox1">{customization_Check_data[2]}</label>
                            </Col>) : (
                                <Col>
                                    <input
                                        className="form-check-input mx-2"
                                        type="checkbox"
                                        name="front_neck"
                                        onChange={handleWithOutNeckOther}
                                        id="inlineCheckbox1"
                                    />
                                    <label className="form-check-label"
                                        htmlFor="inlineCheckbox1">{customization_Check_data[2]}</label>
                                </Col>
                            )}

                        {selectedDefaultBackneckItem ? (
                            <Col>
                                <input
                                    className="form-check-input mx-2"
                                    type="checkbox"
                                    name="back_nack"
                                    id="inlineCheckbox2"
                                    onChange={handleWithOutNeck}
                                />
                                <label className="form-check-label"
                                    htmlFor="inlineCheckbox2">{customization_Check_data[3]}</label>
                            </Col>) :
                            (
                                <Col>
                                    <input
                                        className="form-check-input mx-2"
                                        type="checkbox"
                                        name="back_nack"
                                        id="inlineCheckbox2"
                                        onChange={handleWithOutNeckOther}
                                    />
                                    <label className="form-check-label"
                                        htmlFor="inlineCheckbox2">{customization_Check_data[3]}</label>
                                </Col>
                            )
                        }
                    </Row>
                </div>
            </Row>
        </>
    )
}
export default BlouseCustomization;
