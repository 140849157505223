import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { BlouseContext } from "../../../../context/blouseContext";
import { useTranslation } from 'react-i18next';

const BackdropDisplay=({name,data,icon,children})=>{
    const {selectedBackDropItem,updateBackDropItem}=useContext(BlouseContext);
    const { t } = useTranslation();
    const BackDropData = t('main.backdrop_data', { returnObjects: true });

    const getBackDropItem=(jsonData)=>{
        const isSelected = selectedBackDropItem && selectedBackDropItem.name === name;
        if (!isSelected) {
            updateBackDropItem(jsonData);
        }
        else{
            updateBackDropItem(null)
        }
    }

    return(
        <Button variant="light border" className={selectedBackDropItem && 
            selectedBackDropItem.name === name ?"items_display btn-danger" :"items_display"}
        onClick={()=>getBackDropItem(data,name)}>
            <img src={icon} alt={name} className="icon_image"/>
            {BackDropData[name] ? BackDropData[name] : name}
            {children}
        </Button>
    )
}
export default BackdropDisplay