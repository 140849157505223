import React, { useContext, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { ChudidharContext } from "../../../context/chudidharContext";
import ChudiCollarDisplay from "./collardisplay";

const ChudiCollarList=()=>{
    const {chudiCollarData,selectdChudiItem}=useContext(ChudidharContext);
    const [filterData,setFitlerData]=useState('');
    const [filterDataCollor,setFilterDataCollor]=useState([]);

    const FilterData=filterDataCollor.filter(each=>(
        each.name.toLowerCase().includes(filterData.toLowerCase())
      ))

      useEffect(() => {
        if (selectdChudiItem) {
          let filteredItems = [];
          if (selectdChudiItem.name.toLowerCase().includes("close")) {
            filteredItems = chudiCollarData.filter((item) => item.name.toLowerCase().includes("close"));
          } else if (selectdChudiItem.name.toLowerCase().includes("high")) {
            filteredItems = chudiCollarData.filter((item) => item.name.toLowerCase().includes("high"));
          }
          else if (selectdChudiItem.name.toLowerCase().includes("boat")) {
            filteredItems = chudiCollarData.filter((item) => item.name.toLowerCase().includes("boat"));
          }
          setFilterDataCollor(filteredItems);
         
        }
      }, [selectdChudiItem, chudiCollarData]);


    return(
        <>
        <div className="d-flex mt-2">
           <Col className="">
            <Form.Control type="text" 
            onChange={(e)=>setFitlerData(e.target.value)}
            placeholder="Search By Items..." />
            </Col>
            <Col className="mx-2 mt-2" lg={4} sm={4} md={4}>
            <p className="text-dark ">Total Items {FilterData.length}</p>
            </Col>
        </div>
        <div className="leftside_items_sleeve">
           {FilterData?.map(each=>(
                  <ChudiCollarDisplay key={each.id}
                  icon={each.icon}
                  name={each.name}
                  data={each}>
                  </ChudiCollarDisplay>
                 ))}
        </div>
        </>
    )
}
export default ChudiCollarList