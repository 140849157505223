import React, { useContext } from "react";
import parse from 'html-react-parser';
import { CropTopContext } from "../../../context/croptopContext";
import { BlouseContext } from "../../../context/blouseContext";

const FrontSvgContent = () => {
    const { selectedCropItem, activeCropColor } = useContext(CropTopContext);
    const { activeFabric } = useContext(BlouseContext);

    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                width="150mm" height="100mm" version="1.1"
                viewBox="1400 900 6750 7400"
                style={{
                    shapeRendering: "geometricPrecision", textRendering: "geometricPrecision", imageRendering: "optimizeQuality", fillRule: "evenodd", clipRule: "evenodd",
                    fill: activeCropColor || activeFabric ? activeCropColor || `url(#image-fill)` : "lightgray"
                }}>
                <defs>
                    <pattern id="image-fill" width="100%" height="100%" patternContentUnits="userSpaceOnUse">
                        <image xlinkHref={activeFabric} width="100%" />
                    </pattern>
                    {selectedCropItem && parse(selectedCropItem.back_svg)}
                </defs>
                <path style={{
                    stroke: "#16406A", strokeWidth: "1", strokeLinecap: "round", strokeLinejoin: 'round', strokeMiterlimit: "22.9256",
                    fill: activeCropColor || activeFabric ? activeCropColor || `url(#image-fill)` : "lightgray"
                }}
                    d={selectedCropItem ? (selectedCropItem.base_value) : null}></path>
                {selectedCropItem && parse(selectedCropItem.front_svg)}
            </svg>
        </>
    )
}

export default FrontSvgContent;
