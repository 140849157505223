import React, { useContext, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { KidsContext } from "../../../context/kidsContext";
import KidsDisplay from "./kidsdisplay";
import { useTranslation } from 'react-i18next';

const KidsList=()=>{
    const [blouseList, setBlouseList] = useState("All");
    const {kidsData,allkidsData}=useContext(KidsContext);
    const [filterBlouse,setFilterBlouse]=useState("");
    const [filterSubBlouse,setFilterSubBlouse]=useState("");
    const { t } = useTranslation();
    const kids_filter_Data = t('main.kids_fiter_buttons', { returnObjects: true });

    const FitlerBlouseData=allkidsData.filter(each=>(
        each.name.toLowerCase().includes(filterBlouse.toLowerCase())
    ))
    const FitlerBlouseSubData=kidsData.filter(each=>(
        each.name.toLowerCase().includes(filterSubBlouse.toLowerCase())
    ))

    const filteredItems = kidsData.reduce((acc, category) => {
        if (category.name === blouseList) {
            acc.push(...category.items);
        }
        return acc;
        }, []);
    
    const handleSelect = (key) => {
        setBlouseList(key);
    };
    return(
        <>
          <div>
            <Col className="pt-1" lg={12}>
                <Button variant={blouseList === "All" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                onClick={()=>handleSelect("All")}>{kids_filter_Data[0]}</Button>
                <Button variant={blouseList === "Frocks" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                onClick={()=>handleSelect("Frocks")}>{kids_filter_Data[1]}</Button>
                <Button variant={blouseList === "Blouse" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                onClick={()=>handleSelect("Blouse")}>{kids_filter_Data[2]}</Button>
                <Button variant={blouseList === "Lehenga" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                onClick={()=>handleSelect("Lehenga")}>{kids_filter_Data[3]}</Button>
                <Button variant={blouseList === "Pants" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                onClick={()=>handleSelect("Pants")}>{kids_filter_Data[4]}</Button>
            </Col>
            </div>
            {blouseList === "All" ? (<>
                <div className="d-flex">
                <Col className="" >
                   <Form.Control type="text" 
                   onChange={(e)=>setFilterBlouse(e.target.value)}
                   placeholder="Search By Items..." />
                 </Col>
                 <Col className="mt-2 mx-2" lg={5} sm={4} md={4}>
                   <p className="text-dark">{t('main.search_total')} {FitlerBlouseData.length}</p>
                 </Col>
                 </div>
                 </>)
                 :
                 (<>
                 <div className="d-flex m-0">
                 <Col className="" >
                   <Form.Control type="text" 
                   onChange={(e)=>setFilterSubBlouse(e.target.value)}
                   placeholder="Search By Items..." />
                 </Col>
                 <Col className="mt-2 mx-2" lg={5} sm={4} md={4}>
                   <p className="text-dark ">{t('main.search_total')} {filteredItems.length}</p>
                 </Col>
                 </div>
                 </>)
                 }

             {blouseList === "All" ? (<>
                <div className="leftside_items">
                {FitlerBlouseData.map(each=>{
                    if(blouseList === "All"){
                        return(
                        <KidsDisplay  
                        key={each.id}
                        icon={each.icon}
                        name={each.name}
                        data={each} >
                    </KidsDisplay>
                    )}
                })}
                
                 </div>
                 </>)
                 :
                 (<>
                 <div className="leftside_items">
                 {filteredItems.map(each=>(
                   each.name.toLowerCase().includes(filterSubBlouse.toLowerCase()) &&
                    <KidsDisplay key={each.id}
                        icon={each.icon}
                        name={each.name}
                        data={each}>
                    </KidsDisplay>
                ))}
                 
                 </div>
                 </>)
                 }
             
            
           
            
        </>
    )
}
export default KidsList