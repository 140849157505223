import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { SliderPicker } from 'react-color';
import FabricComponent from "../../Blouse/fabric";
import { KidsContext } from "../../../context/kidsContext";

const ColorKidsComponent=()=>{
    const {selectdKidsItem,updateKidsColor}=useContext(KidsContext);
    const [activeItem,setActiveItem]=useState("Color");
    const [color, setColor] = useState('#FFFFFF');

    const handleActive=(value)=>{
        setActiveItem(value)
    }

    const handleChange = (selectedColor) => {
        setColor(selectedColor.hex);
    };

    const getColorUpdate=(value)=>{
      updateKidsColor(value);
      }

    return(
        <>
        <Col>
          <Button variant={activeItem === 'Color' ? "dark mx-1":"outline-dark mx-1"} size="sm" 
          onClick={()=>handleActive("Color")} disabled={!selectdKidsItem}>Color</Button>
          <Button variant={activeItem === 'Fabric' ? "dark mx-1":"outline-dark mx-1"} size="sm"
          onClick={()=>handleActive("Fabric")}  disabled={!selectdKidsItem}>Fabric</Button>
          <Row>
            
              {selectdKidsItem && activeItem === "Color" &&
              <Row className="p-2" onClick={()=>getColorUpdate(color)}>
                <Col>
                <SliderPicker color={color} onChange={handleChange} 
                />
                </Col>
            </Row>}
           
            {selectdKidsItem && activeItem === "Fabric" &&
              <FabricComponent/>
              }
          </Row>
        </Col>
        </>
    )
}
export default ColorKidsComponent