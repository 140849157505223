import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { ChudidharContext } from "../../../../context/chudidharContext";


const ChudiBackDropDisplay=({name,icon,data})=>{
    const {selectdChudiBackDropItem,updateChudiBackDrop}=useContext(ChudidharContext);

    const getCollarItem=(jsondata)=>{
        const isSelected = selectdChudiBackDropItem && selectdChudiBackDropItem.name === name;
        if (!isSelected) {
            updateChudiBackDrop(jsondata);
        }
        else{
            updateChudiBackDrop(null)
        }
    }

    return(
        <>
        <Button className={selectdChudiBackDropItem && 
                selectdChudiBackDropItem.name === name ?"items_display btn-danger" :"items_display"}
            onClick={()=>getCollarItem(data,name)}>
            <img src={icon} alt={name} className="icon_image"/>
            {name}
        </Button>
       </>
    )
}
export default ChudiBackDropDisplay