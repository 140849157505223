import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ChudidharContext } from "../../../context/chudidharContext";
import ChudiFrontSvgContent from "../svgContent/frontsvg";
import ChudiBackSvgContent from "../svgContent/backsvg";
import ChudiPattern from "../customization/pattern";
import ChudiPantSvgContent from "../svgContent/pantsvg";
import ChudiBottomSvgContent from "../svgContent/buttomsvg";
import ChudiUmbrellaSvgContent from "../svgContent/umbrella";
import ChudiBodySvgContent from "../svgContent/bodysvg";
import ChudiBodyBackSvgContent from "../svgContent/bodysvgBack"
import GetCustomerData from "../../customer/getOld";
import ColorChudiComponent from "../color&fabric";
import { CustomerContext } from "../../../context/customerContext";



const RightCudiComponent=()=>{
    const [activeKey, setActiveKey] = useState("Both"); // Set initial active tab as "Blouse"
    const {selectdChudiItem,selectdChudiSleeveItem,selectdChudiDFrontItem,selectdChudiDBackItem
    ,selectdChudiFrontModelItem,selectdChudiBackModelItem,selectdChudiHighNeckItem,selectdChudiBackDropItem,
    currentStep,getHome,getAllChudiClear
,selectdChudiCollarItem}=useContext(ChudidharContext);
const {setFormData,setShowOld,setOrderDataDetails}=useContext(CustomerContext)
    
    const handleSelect = (key) => {
        setActiveKey(key);
    };

    const getNew=()=>{
        getAllChudiClear()
        setFormData(null)
        setShowOld(false)
        setOrderDataDetails(null)

    }

    const handleHome=()=>{
        getHome()
    }

    return(
        <>
            <Row >
                <Col>
                <Button variant="outline-light mx-1" size="sm"
                onClick={handleHome} disabled={!currentStep === 1}
                >Home</Button>
                <Button variant="outline-light mx-1" size="sm"
                disabled={!selectdChudiItem}
                onClick={getNew}>New</Button>
                </Col>
                <GetCustomerData/>
            </Row>
        {currentStep === 4 ? (
        <>
        <ChudiPattern/>
        </>)
        :(
            <>
            <Row className="bg-white mt-1 rounded" style={{height:"70vh"}}>
                <Row className="pt-1">
                    <Col className="">
                    <p><span className="text-danger bold">Technical Sketch /</span> 
                    <span className="text-dark f-500"> {selectdChudiItem && selectdChudiItem.name}</span>
                    <span className="mx-1">{selectdChudiSleeveItem && selectdChudiSleeveItem.name}</span>
                    <span className="mx-1">{selectdChudiDFrontItem && selectdChudiDFrontItem.name}</span>
                    <span className="mx-1">{selectdChudiDBackItem && selectdChudiDBackItem.name}</span>
                    <span className="mx-1">{selectdChudiFrontModelItem && selectdChudiFrontModelItem.name}</span>
                    <span className="mx-1">{selectdChudiBackModelItem && selectdChudiBackModelItem.name}</span>
                    <span className="mx-1">{selectdChudiHighNeckItem && selectdChudiHighNeckItem.name}</span>
                    <span className="mx-1">{selectdChudiCollarItem && selectdChudiCollarItem.name}</span>
                    <span className="mx-1">{selectdChudiBackDropItem && selectdChudiBackDropItem.name}</span>
                    </p>
                    </Col>
                    <hr style={{margin:"0px",marginTop:"-20px",marginLeft:"10px"}}/>
                </Row>
                <Row style={{marginTop:"-15px"}}>
                <Col className="">
                  {selectdChudiItem && selectdChudiItem.name.toLowerCase().includes("pant") ||
                  selectdChudiItem && selectdChudiItem.name.toLowerCase().includes("bottom") ||
                  selectdChudiItem && selectdChudiItem.name.toLowerCase().includes("umbrella")? (
                    <></>
                  ):(
                    <>
                    <Button variant={activeKey ==="Both" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                    onClick={()=>handleSelect("Both")}>Both</Button>
                    <Button variant={activeKey ==="Front View" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                    onClick={()=>handleSelect("Front View")}>Front View</Button>
                    <Button variant={activeKey ==="Back View" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                    onClick={()=>handleSelect("Back View")}>Back View</Button>
                    </>
                    )}
                </Col>
                </Row>
                  
                {selectdChudiItem && selectdChudiItem.name.toLowerCase().includes("pant") ? (
                <ChudiPantSvgContent />
                  ):
                  selectdChudiItem && selectdChudiItem.name.toLowerCase().includes("bottom") ? (
                    <ChudiBottomSvgContent />
                      )
                  :
                  selectdChudiItem && selectdChudiItem.name.toLowerCase().includes("umbrella")?
                  (
                    <ChudiUmbrellaSvgContent />
                  )
                  :
                  selectdChudiItem && selectdChudiItem.name.toLowerCase().includes("body") ?
                  (
                     <>
                     {activeKey ==="Both" &&
                        (
                            <Row>
                                <Col className="" lg={6} sm={12} md={6}>
                                    
                                    <ChudiBodySvgContent />
                                </Col>
                                <Col className="" lg={6} sm={12} md={6}>
                                    <ChudiBodyBackSvgContent/>
                                </Col>
                            </Row>
                        
                        )}
                          {activeKey ==="Front View" &&
                        (
                            <Row className="text-center">
                                <Col className="" lg={12} sm={12} md={12}>
                                    <ChudiBodySvgContent/>
                                </Col>
                            </Row>
                        )}
                        {activeKey ==="Back View" &&
                        (
                            <Row className="text-center">
                                <Col className="" lg={12} sm={12} md={12}>
                                    <ChudiBodyBackSvgContent/>
                                </Col>
                            </Row>
                        )}
                     </>
                  ):
                  (
                   <>
                        {activeKey ==="Both" &&
                        (
                            <Row>
                                <Col className="" lg={6} sm={12} md={6}>
                                    
                                    <ChudiFrontSvgContent />
                                </Col>
                                <Col className="" lg={6} sm={12} md={6}>
                                    <ChudiBackSvgContent/>
                                </Col>
                            </Row>
                        
                        )}
                        {activeKey ==="Front View" &&
                        (
                            <Row className="text-center">
                                <Col className="" lg={12} sm={12} md={12}>
                                    <ChudiFrontSvgContent/>
                                </Col>
                            </Row>
                        )}
                        {activeKey ==="Back View" &&
                        (
                            <Row className="text-center">
                                <Col className="" lg={12} sm={12} md={12}>
                                    <ChudiBackSvgContent/>
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </Row>
            <Row className="bg-light mt-1 rounded p-1">
                <ColorChudiComponent/>
            </Row>
            
        </>
        )}
        
       </>
    )
}
export default RightCudiComponent