import React from "react";
import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";
import ChudiComponent from "./chudidharcomponent";
import LandingComponent from "./landingcomponent";
import LoginProvider from "../context/logincontext";
import LoginComponent from "./logincomponent";
import BlouseProvider from "../context/blouseContext";
import BlouseComponent from "./Blouse";
import PackageProvider from "../context/packageContext";
import ChudidharProvider from "../context/chudidharContext";
import KidsProvider from "../context/kidsContext";
import KidsComponent from "./kidscomponent";
import CustomerProvider from "../context/customerContext";
import CropTopProvider from "../context/croptopContext";
import CropTopComponent from "./CropTop";
import NightyComponent from "./Nighty";



const RoutingComponent=()=>{
    
    return(
          <HashRouter>
            <LoginProvider>
                <PackageProvider>
                    <CustomerProvider>
                        <BlouseProvider>
                            <ChudidharProvider>
                                <KidsProvider>
                                    <CropTopProvider>
                                        <Routes>
                                            <Route exact path="/" element={<LandingComponent/>}/>
                                            <Route exact path="/login" element={<LoginComponent />}/>
                                            <Route exact path="/blouse" element={<BlouseComponent />}/>
                                            <Route exact path="/chudi" element={<ChudiComponent />}/>
                                            <Route exact path="/kids" element={<KidsComponent />}/>
                                            <Route exact path="/croptop" element={<CropTopComponent />}/>
                                            <Route exact path="/nighty" element={<NightyComponent />}/>
                                        </Routes>
                                    </CropTopProvider>
                                </KidsProvider>
                            </ChudidharProvider>
                        </BlouseProvider>
                   </CustomerProvider>
                </PackageProvider>
            </LoginProvider>
          </HashRouter>
    )
}
export default RoutingComponent