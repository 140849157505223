import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { ChudidharContext } from "../../../../../context/chudidharContext";
import { useTranslation } from 'react-i18next';


const DBackDisplay=({name,data,icon})=>{
    const {updateChudiDFront,selectdChudiDBackItem,
        updateChudiBackModel}=useContext(ChudidharContext);
    const { t } = useTranslation();
    const default_back_neck_Data = t('main.chudi_default_back_neck', { returnObjects: true });

    const getDBackNecks=(jsondata)=>{
        updateChudiDFront(jsondata);
        updateChudiBackModel(null)
    }
    return(
        <>
        <Button variant="white border" className={selectdChudiDBackItem && selectdChudiDBackItem.name === name ? 
            "items_display btn-danger":"items_display"}
           onClick={()=>getDBackNecks(data,name)}>
            <img src={icon} alt={name} className="icon_image"/>
            {default_back_neck_Data[name] ? default_back_neck_Data[name]:[name]}
            
        </Button>
       </>
    )
}
export default DBackDisplay