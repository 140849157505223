import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { SliderPicker } from 'react-color';
import { ChudidharContext } from "../../../context/chudidharContext";
import FabricComponent from "../../Blouse/fabric";

const ColorChudiComponent=()=>{
    const {selectdChudiItem,updateChudiColor}=useContext(ChudidharContext);
    const [activeItem,setActiveItem]=useState("Color");
    const [color, setColor] = useState('#FFFFFF');

    const handleActive=(value)=>{
        setActiveItem(value)
    }

    const handleChange = (selectedColor) => {
        setColor(selectedColor.hex);
    };

    const getColorUpdate=(value)=>{
        updateChudiColor(value);
      }

    return(
        <>
        <Col>
          <Button variant={activeItem === 'Color' ? "dark mx-1":"outline-dark mx-1"} size="sm" 
          onClick={()=>handleActive("Color")} disabled={!selectdChudiItem}>Color</Button>
          <Button variant={activeItem === 'Fabric' ? "dark mx-1":"outline-dark mx-1"} size="sm"
          onClick={()=>handleActive("Fabric")}  disabled={!selectdChudiItem}>Fabric</Button>
          <Row>
            
              {selectdChudiItem && activeItem === "Color" &&
              <Row className="p-2" onClick={()=>getColorUpdate(color)}>
                <Col>
                <SliderPicker color={color} onChange={handleChange} 
                />
                </Col>
            </Row>}
           
            {selectdChudiItem && activeItem === "Fabric" &&
              <FabricComponent/>
              }
          </Row>
        </Col>
        </>
    )
}
export default ColorChudiComponent