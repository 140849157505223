import React, { useContext, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Form from 'react-bootstrap/Form';
import DFrontDisplay from "./dfrontdisplay";
import { ChudidharContext } from "../../../../context/chudidharContext";
import { useTranslation } from 'react-i18next';


const ChudiDefaultFrontNecks=()=>{
  const {chudiDefaultFrontData}=useContext(ChudidharContext);
  const [filterData,setFilterData]=useState('');
  const { t } = useTranslation();

  const FilterDFrontNecks=chudiDefaultFrontData && chudiDefaultFrontData.filter(each=>(
    each.name.toLowerCase().includes(filterData.toLowerCase()) && each.name !== "Without Neck"
  ))

 
    return(
        <>
         <div className="d-flex">
              <Col className="" lg={7}>
                <Form.Control type="text" placeholder="Search By Items..."
                onChange={(e)=>setFilterData(e.target.value)} />
              </Col>
              <Col className="mx-2 mt-2" lg={5}>
                <p className="text-dark ">{t('main.search_total')} {FilterDFrontNecks && FilterDFrontNecks.length}</p>
              </Col>
            </div>
            <div className="leftside_items">
                 {FilterDFrontNecks?.map(each=>(
                      <DFrontDisplay
                          key={each.id}
                          icon={each.icon}
                          name={each.name}
                          data={each}
                      ></DFrontDisplay>
                 
                 ))}
                
            </div>


        </>
    )
}
export default ChudiDefaultFrontNecks