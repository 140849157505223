import React, { useContext, useState } from "react";
import { Button, Col, Row } from 'react-bootstrap';
import BlouseList from "./blouse";
import SleeveList from "./sleeve";
import CollarList from "./collars";
import BackDropList from "./backdrops";
import NecksComponent from "./necks";
import { BlouseContext } from "../../context/blouseContext";
import CustomerComponent from "../customer";
import MeasurmentComponent from "./measurment";
import BlouseCustomization from "./customization/maincustomization";
import { useTranslation } from 'react-i18next';

const MainBlouseComponent=()=>{
    const {selectedBlouse, currentStep, handleNextClick, handlePreviousClick} = useContext(BlouseContext);
    const [activeKey, setActiveKey] = useState("Blouse");
    const { t } = useTranslation();
    const translatedButtons = t('main.buttons', { returnObjects: true });
    const onlyButtons_data = t('main.all_buttons', { returnObjects: true });

    const handleSelect = (key) => {
        setActiveKey(key);
    };
    
    return(
        <>
        {currentStep === 1 && 
        <>
       <Row>
            <Col > 
                <Button  variant={activeKey === "Blouse" ? "light mx-1":"outline-light mx-1"} id="blouse" size="sm"
                 onClick={()=>handleSelect("Blouse")}>{translatedButtons[0]}</Button>
                <Button variant={activeKey === "Sleeves" ? "light mx-1":"outline-light mx-1"} size="sm" id="sleeve"
                onClick={()=>handleSelect("Sleeves")}>{translatedButtons[1]}</Button>
                 {selectedBlouse && selectedBlouse.front_neck_shapes && (
                <Button variant={activeKey === "Necks" ? "light mx-1":"outline-light mx-1"} size="sm"
                onClick={()=>handleSelect("Necks")}>{translatedButtons[2]}</Button>)}
                {selectedBlouse && selectedBlouse.collar &&(
                <Button variant={activeKey === "Collars" ? "light mx-1":"outline-light mx-1"} size="sm"
                onClick={()=>handleSelect("Collars")}>{translatedButtons[4]}</Button>)}
                {selectedBlouse && selectedBlouse.back_drops &&(
                <Button variant={activeKey === "BackDrops" ? "light mx-1":"outline-light mx-1"} size="sm"
                onClick={()=>handleSelect("BackDrops")}>{translatedButtons[5]}</Button>
                )}
            </Col>
       </Row>
       <Row className="bg-white rounded mt-1" style={{height:"79.5vh"}}>
           {activeKey === "Blouse" && <BlouseList/>}
           {activeKey === "Sleeves" && <SleeveList/>}
           {activeKey === "Necks" && <NecksComponent/>}
           {activeKey === "Collars" && <CollarList/>}
           {activeKey === "BackDrops" && <BackDropList/>}
       </Row>
       </>
       }
       {currentStep === 2 && 
       <>
       <CustomerComponent/>
       </>
       }
       {currentStep === 3 && 
       <>
       <MeasurmentComponent/>
       </>
       }
       {currentStep === 4 && 
       <>
       <BlouseCustomization/>
       </>
       }

       <Row className="bg-white rounded mt-1 p-1">
        <Col>
        <Button size="sm" variant={currentStep===1  ? "outline-dark":"dark"} onClick={handlePreviousClick}
        disabled={ currentStep ===1}>{onlyButtons_data[1]}</Button>
        </Col>
        <Col className="text-end">
        <Button size="sm" variant={currentStep===3 || currentStep===4 ? "outline-dark":"dark"} onClick={handleNextClick}  id="next"
        disabled={!selectedBlouse || currentStep ===3 || currentStep ===4}>{onlyButtons_data[0]}</Button>
        </Col>
       </Row>
      
     </>
    )
}
export default MainBlouseComponent;
