import React, {  useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { KidsContext } from "../../../context/kidsContext";
import KidsFrontSvgContent from "../kidsRightSection/svgcontent/frontindex";
import KidsBackSvgContentBack from "../kidsRightSection/svgcontent/backindex";
import KidsPantSvgContent from "../kidsRightSection/svgcontent/pantindex";
import KidsPatternComponent from "../kidsCustomization/pattern";
import GetCustomerData from "../../customer/getOld";
import ColorKidsComponent from "../color&fabric";
import { useTranslation } from 'react-i18next';
import { CustomerContext } from "../../../context/customerContext";



const RightKidsComponent=()=>{
    const [activeKey, setActiveKey] = useState("Both"); 
    const {selectdKidsItem,selectdKidsSleeveItem,selectdKidsDFrontItem,selectdKidsDBackItem,
        getHome,handleKidsClear,currentStep,
        selectdKidsFrontModelItem,selectdKidsBackModelItem}=useContext(KidsContext);
        const { t } = useTranslation();
        const translatedButtons = t('main.right_buttons', { returnObjects: true });
        const right_svg_Filter = t('main.right_buttons_filter', { returnObjects: true });
        const {setFormData,setShowOld,setOrderDataDetails,setOrderOldId}=useContext(CustomerContext);

    const handleSelect = (key) => {
        setActiveKey(key);
    };

    const handleHome=()=>{
        getHome()
    }

    const hanldeNew=()=>{
        handleKidsClear()
        setFormData(null)
        setShowOld(false)
        setOrderOldId(null)
        setOrderDataDetails(null)
    }
    
    return(
        <>
        <Row >
            <Col>
             <Button variant="outline-light mx-1" size="sm"
             onClick={handleHome}>{translatedButtons[0]}</Button>
             <Button variant="outline-light mx-1" size="sm"
             disabled={!selectdKidsItem}
             onClick={hanldeNew}>{translatedButtons[1]}</Button>
            </Col>
            <GetCustomerData/>
        </Row>
        {currentStep ==4 ? (
            <>
            <KidsPatternComponent/>
            </>
           )
            :(
                <>
                <Row className="bg-white mt-1 rounded" style={{height:"70vh"}}>
                    
                    <Row className="pt-1">
                        <Col className="">
                        <p><span className="text-danger bold">{t('main.right_buttons_head')} /</span> 
                        <span className="text-dark f-500">  {selectdKidsItem && selectdKidsItem.name}</span>
                        <span className="mx-1">{selectdKidsSleeveItem && selectdKidsSleeveItem.name}</span>
                        <span className="mx-1">{selectdKidsDFrontItem && selectdKidsDFrontItem.name}</span>
                        <span className="mx-1">{selectdKidsDBackItem && selectdKidsDBackItem.name}</span>
                        <span className="mx-1">{selectdKidsFrontModelItem && selectdKidsFrontModelItem.name}</span>
                        <span className="mx-1">{selectdKidsBackModelItem && selectdKidsBackModelItem.name}</span>
                        </p>
                        </Col>
                        <hr style={{margin:"0px",marginTop:"-10px",marginLeft:"10px"}}/>
                    </Row>
                    <Row className="">
                    <Col className="">
                    {selectdKidsItem && selectdKidsItem.name.toLowerCase().includes("pant") ||
                        selectdKidsItem && selectdKidsItem.name.toLowerCase().includes("bottom") ||
                        selectdKidsItem && selectdKidsItem.name.toLowerCase().includes("umbrella")? (
                            <></>
                        ):(
                            <>
                            <Button variant={activeKey ==="Both" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                            onClick={()=>handleSelect("Both")}>{right_svg_Filter[0]}</Button>
                            <Button variant={activeKey ==="Front View" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                            onClick={()=>handleSelect("Front View")}>{right_svg_Filter[1]}</Button>
                            <Button variant={activeKey ==="Back View" ? "dark mx-1":"outline-dark mx-1"} size="sm"
                            onClick={()=>handleSelect("Back View")}>{right_svg_Filter[2]}</Button>
                            </>
                            )}
                    </Col>
                    </Row>

                    {selectdKidsItem && selectdKidsItem.name.toLowerCase().includes("pant") ? (
                        <KidsPantSvgContent />
                        ):
                        selectdKidsItem && selectdKidsItem.name.toLowerCase().includes("umbrella") ? (
                            <KidsPantSvgContent />
                            ):(
                            <>
                            {activeKey ==="Both" &&
                                (
                                    <Row>
                                        <Col className="text-center" lg={6} sm={12} md={6}>
                                            <KidsFrontSvgContent />
                                        </Col>
                                        <Col className="text-center" lg={6} sm={12} md={6}>
                                            <KidsBackSvgContentBack />
                                        </Col>
                                    </Row>
                                
                                )}
                                {activeKey ==="Front View" &&
                                (
                                    <Row className="text-center">
                                        <Col className="" lg={12} sm={12} md={12}>
                                            <KidsFrontSvgContent/>
                                        </Col>
                                    </Row>
                                )}
                                {activeKey ==="Back View" &&
                                (
                                    <Row className="text-center">
                                        <Col className="" lg={12} sm={12} md={12}>
                                            <KidsBackSvgContentBack/>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                </Row>
                <Row className="bg-light mt-1 rounded p-1">
                   <ColorKidsComponent/>
               </Row>
                </>
            )}
                
       </>
    )
}
export default RightKidsComponent