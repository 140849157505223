import React from "react";
import { Button, Col, Row } from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import FrontSvgContent from "../svgcontent";
import ColorCropTopComponent from "../color&fabric";


const RightNightyComponent=()=>{
    const { t } = useTranslation();
        const translatedButtons = t('main.right_buttons', { returnObjects: true });
        const right_svg_Filter = t('main.right_buttons_filter', { returnObjects: true });
    
    return(
        <>
        <Row >
            <Col lg={2} >
             <Button variant="outline-light mx-1"
              size="sm">{translatedButtons[0]}</Button>
             <Button variant="outline-light mx-1" size="sm"
              >{translatedButtons[1]}</Button>
            </Col>
        </Row>
        <Row className="bg-white mt-1 rounded" style={{height:"70vh"}}>
            <Row className="pt-1">
                <Col className="">
                    <p><span className="text-danger bold">{t('main.right_buttons_head')} /</span> 
                    {/* <span className="text-dark f-500"> {selectedBlouse && selectedBlouse.name}</span>
                    <span className="mx-1">{selectedSleeveItem && selectedSleeveItem.name}</span>
                    <span className="mx-1">{selectedDefaultFrontneckItem && selectedDefaultFrontneckItem.name}</span>
                    <span className="mx-1">{selectedDefaultBackneckItem && selectedDefaultBackneckItem.name}</span>
                    <span className="mx-1">{selectedFrontneckItem && selectedFrontneckItem.name}</span>
                    <span className="mx-1">{selectedBackneckItem && selectedBackneckItem.name}</span>
                    <span className="mx-1">{selectedHighneckItem && selectedHighneckItem.name}</span>
                    <span className="mx-1">{selectedCollarItem && selectedCollarItem.name}</span>
                    <span className="mx-1">{selectedBackDropItem && selectedBackDropItem.name}</span> */}
                </p>
                </Col>
                <hr style={{margin:"0px",marginTop:"-10px",marginLeft:"10px"}}/>
                <FrontSvgContent/>
            </Row>
        </Row>
        <Row className="bg-light mt-1 rounded p-1">
            <ColorCropTopComponent/>
        </Row>
        
       </>
    )
}
export default RightNightyComponent