import React, { useContext, useState } from "react";
import { Button, Col, Row } from 'react-bootstrap';
import { KidsContext } from "../../../context/kidsContext";
import KidsList from "../kids/index"
import KidsSleeveList from "../sleeves";
import CustomerComponent from "../../customer";
import KidsMeasurmentComponent from "../kidsMeasurment";
import KidsCustomizationComponent from "../kidsCustomization";
import { useTranslation } from 'react-i18next';


const MainKidsComponent=()=>{
    const {selectdKidsItem,currentStep,handleNextClick,handlePreviousClick}=useContext(KidsContext)
    const [activeKey, setActiveKey] = useState("Kids");
    const { t} = useTranslation();
    const filter__Data = t('main.buttons', { returnObjects: true });
    const onlyButtons_data = t('main.all_buttons', { returnObjects: true });

    const handleSelect = (key) => {
        setActiveKey(key);
    };
    
    return(
        <>
        {currentStep === 1 && 
        <>
        <Row >
            <Col > 
                <Button variant={activeKey === "Kids" ? "light mx-1":"outline-light mx-1"} size="sm"
                 onClick={()=>handleSelect("Kids")}>{filter__Data[7]}</Button>
                <Button variant={activeKey === "Sleeves" ? "light mx-1":"outline-light mx-1"} size="sm"
                onClick={()=>handleSelect("Sleeves")}>{filter__Data[1]}</Button>
                
                <Button variant={activeKey === "Necks" ? "light mx-1":"outline-light mx-1"} size="sm"
                onClick={()=>handleSelect("Necks")}>{filter__Data[2]}</Button>
                
                <Button variant={activeKey === "Collars" ? "light mx-1":"outline-light mx-1"} size="sm"
                onClick={()=>handleSelect("Collars")}>{filter__Data[4]}</Button>
                
            </Col>
       </Row>
       <Row className="bg-white rounded mt-1" style={{height:"79.5vh"}}>
            {activeKey === "Kids" && <KidsList /> }
           {activeKey === "Sleeves" && <KidsSleeveList/>}
          {/* {activeKey === "Necks" && <NecksComponent/>}
           {activeKey === "Collars" && <CollarList/>}
           {activeKey === "BackDrops" && <BackDropList/>} */}
            
        </Row>
        </>} 
        {currentStep ===2 && 
        <>
        <CustomerComponent/>
        </>}
        {currentStep ===3 && 
        <>
        <KidsMeasurmentComponent/>
        </>}
        {currentStep ===4 && 
        <>
        <KidsCustomizationComponent/>
        </>}
        <Row className="bg-white rounded mt-1 p-1">
            <Col>
            <Button size="sm" variant={currentStep===1 ? "outline-dark":"dark"} onClick={handlePreviousClick}
            disabled={ currentStep ===1}>{onlyButtons_data[1]}</Button>
            </Col>
            <Col className="text-end">
            <Button size="sm" variant={currentStep===3 || currentStep===4 ? "outline-dark":"dark"} onClick={handleNextClick} 
            disabled={!selectdKidsItem || currentStep ===3 || currentStep ===4}>{onlyButtons_data[0]}</Button>
            </Col>
       </Row>    
     </>
    )
}
export default MainKidsComponent